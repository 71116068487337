import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import React, { useEffect, useState } from "react";
import { deleteProfile, deleteUser } from "../../_redux/user/userCrud";
import { fetchProfile, fetchUser } from "../../_redux/user/userAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DeleteDialog from "../../../../../_metronic/_partials/widgets/alert/DeleteDialog";
import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import NotAuthorized from "../../../../../_metronic/_partials/widgets/NotAuthorized";
import ProfileTable from "./user-table/ProfileTable";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { UserFilter } from "./user-filter/User";
import UserTable from "./user-table/UserTable";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import { initialFilter } from "./UserUIHelpers";
import { useIntl } from "react-intl";
import { useUserUIContext } from "./UserUIContext";

const UserCard = () => {
  // Get the intl object for formatting translations
  const intl = useIntl();

  // Get the dispatch function for dispatching actions
  const dispatch = useDispatch();
  const UserUIContext = useUserUIContext();

  const [active, setActive] = useState(false);
  // Set up state variables
  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const init = ["kt_new_user_panel", "kt_new_profile_panel"];

  // Initialize some IDs when the component mounts
  useEffect(() => {
    initIds(init);
  }, [init]);

  // Set the query params in the user UI context when the active state variable changes
  useEffect(() => {
    UserUIContext.setQueryParams(initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  // Get the permission and role from the Redux store
  const { permission, role } = useSelector(
    (state) => ({
      permission: state.auth.permission,
      role: state.auth.user.roles,
    }),
    shallowEqual
  );

  // Event handler for deleting a user or profile
  const handleDelete = () => {
    // Reset the snackbar state
    setSnack({ call: false, type: "", message: "" });
    if (!active) {
      // Delete the user
      deleteUser({ user_id: UserUIContext.ids })
        .then((res) => {
          // Show a success snackbar message
          setSnack({ call: true, type: "success", message: res.data.message });
          // Fetch the updated list of users and reset the selected IDs
          dispatch(fetchUser(initialFilter));
          setDialog(false);
          UserUIContext.setIds([]);
        })
        .catch((error) => {
          // Show an error snackbar message
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
          setDialog(false);
        });
    } else {
      // Delete the profile
      deleteProfile({ role_id: UserUIContext.ids })
        .then((res) => {
          // Show a success snackbar message
          setSnack({ call: true, type: "success", message: res.data.message });
          // Fetch the updated list of profiles and reset the selected IDs
          dispatch(fetchProfile(initialFilter));
          setDialog(false);
          UserUIContext.setIds([]);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
          setDialog(false);
        });
    }
  };

  return (
    <>
      {/* Render the breadcrumbs and tab switching buttons */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NewBreadCrumbs title={intl.formatMessage({ id: "USERS" })} />
        <div className="d-flex">
          {/* Render the delete button */}
          {/* {permission?.setting_user?.delete ? (
            <button
              className="btn btn-primary mr-2"
              style={{ height: "39px" }}
              type="button"
              onClick={() => setDialog(true)}
              disabled={UserUIContext.ids?.length === 0} // Disable the button if no items are selected
            >
              Delete
            </button>
          ) : (
            ""
          )} */}
          {permission?.setting_user?.create ? (
            <>
              <div id="kt_new_user_panel_toggle">
                {!active ? (
                  <button className="btn btn-primary mr-2" type="button">
                    {intl.formatMessage({ id: "New.User" })}
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div id="kt_new_profile_panel_toggle">
                {active ? (
                  <button className="btn btn-primary mr-2" type="button">
                    {intl.formatMessage({ id: "New.Profile" })}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <Card>
        {/* Render the "User" and "Profile" tab buttons */}
        <div className="d-flex ml-6 mt-4">
          <div>
            <button
              className={active ? "btn btn-dark m-2" : "btn btn-primary m-2"}
              onClick={() => setActive(false)}
            >
              {intl.formatMessage({ id: "USERS" })}
            </button>
          </div>
          <div>
            <button
              className={active ? "btn btn-primary m-2" : "btn btn-dark m-2"}
              onClick={() => setActive(true)}
              disabled={role && role?.length > 0 && role[0] !== "super-admin"}
            >
              {intl.formatMessage({ id: "Profile" })}
            </button>
          </div>
        </div>
        {/* Render the appropriate tab content */}
        <CardBody>
          {active ? (
            <ProfileTable />
          ) : (
            <>
              {permission?.setting_user?.view ? (
                <>
                  <UserFilter />
                  <UserTable />
                </>
              ) : (
                <NotAuthorized />
              )}
            </>
          )}
        </CardBody>
      </Card>
      {/* Render the delete dialog */}
      <DeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        handleDelete={handleDelete}
        title={active ? "Profile" : "User"}
      />
      {/* Render the snackbar */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};

export default UserCard;
