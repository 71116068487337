/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import {
  CardCVV,
  CardComponent,
  CardExpiry,
  CardNumber,
} from "@chargebee/chargebee-js-react-wrapper";
import { Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  getPaymentIntent,
  getAddress,
  getPlaceOrder,
  addCards,
  addCharge,
  addPerson,
  addCardOnPlace,
  addPodCharge,
  paymentIntentStripe,
  orderPlaceStripe,
  createPaymentIntent,
  sendSmsEmailPayUrl,
  sendQuote,
} from "../_redux/order/orderCrud";
import { shallowEqual, useSelector } from "react-redux";

import { OrderPaymentSchema } from "../order-form/validationSchema";
import SVG from "react-inlinesvg";
// import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import {
  apiThirdPartyEmail,
  handleFinalPrice,
  newOrderAddressAuth3ds,
  newOrderIntent,
  newOrderPayment,
  newOrderPaymentIntent,
} from "../orderUtils";
import { getInputClasses } from "../../../utils/commonFunction";
import { useHistory } from "react-router-dom";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useLocation } from "react-router-dom";
import {
  CardElement,
  useStripe,
  useElements,
  IbanElement,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { newOrderPaylod } from "../NewOrderStripe/NewOrderStripeUtils";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { loadStripe } from "@stripe/stripe-js";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import DynamicDialog from "../../../../_metronic/_partials/widgets/alert/DynamicDialog";

export function OrderPaymentStripe({
  state,
  dispatchReducer,
  setOpenCanvaQuote,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { cardDetail, orderSummaryListStripe } = useSelector(
    (state) => ({
      cardDetail: state.order.cardDetail,
      orderSummaryListStripe: state.order.orderSummaryListStripe,
    }),
    shallowEqual
  );

  console.log("cardDetail", cardDetail);
  const [dropDown, setDropDown] = useState({ address: [] });
  const [checked, setChecked] = useState(false);
  const [confettiLoading, setConfettiLoading] = useState(false);
  const [sortCode, setSortCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [typeCheck, setTypeCheck] = useState("");
  // const [loading, setLoading] = useState(false);

  const handleGetAddress = async (postcode, check) => {
    if (!postcode) return;
    formik.setFieldValue("is_address_manual", "0");
    getAddress(postcode)
      .then((res) => {
        setDropDown({ address: res.data.addresses });
        formik.setFieldValue("city", res.data.addresses[0].town_or_city);
        if (check !== "onMount") {
          setSnack({
            call: true,
            type: "success",
            message: "Postcode found successfully",
          });
        }
      })
      .catch((error) => {
        formik.setFieldValue("street1", "");
        formik.setFieldValue("city", "");
        setSnack({
          call: true,
          type: "error",
          message: "Cannot find Post Code",
        });
      });
  };

  const cardRef = React.createRef();
  const initialValues = {
    billing: "same",
    street1: "",
    street2: "",
    street3: "",
    city: "",
    postCode: "",
    firstName:
      cardDetail && cardDetail.length && cardDetail[0].first_name
        ? cardDetail[0].first_name + " " + cardDetail[0]?.last_name
        : "",
    is_address_manual: "0",
    paymentType: "card",
  };
  const [state3ds, setState] = useState({
    intent_id: "",
    error: "",
    loading: false,
    intentLoading: false,
    firstName: "",
    options: {
      // Custom classes - applied on container elements based on field's state
      classes: {
        focus: "focus",
        invalid: "invalid",
        empty: "empty",
        complete: "complete",
      },

      style: {
        // Styles for default field state
        base: {
          color: "#333",
          fontWeight: "500",
          fontFamily: "Roboto, Segoe UI, Helvetica Neue, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",

          ":focus": {
            color: "#424770",
          },

          "::placeholder": {
            color: "transparent",
          },

          ":focus::placeholder": {
            color: "#7b808c",
          },
        },

        // Styles for invalid field state
        invalid: {
          color: "#e41029",

          ":focus": {
            color: "#e44d5f",
          },
          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      },

      // locale
      locale: "en",

      // Custom placeholders
      placeholder: {
        number: "4111 1111 1111 1111",
        expiry: "MM / YY",
        cvv: "CVV",
      },

      // Custom fonts
      fonts: ["https://fonts.googleapis.com/css?family=Roboto:300,500,600"],
    },
  });
  // const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // const [validate, setValidate] = useState("same");

  console.log("state3ds", state3ds);

  const authorizeWith3ds = async () => {
    setState({ loading: true });
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    const addre =
      cardDetail && cardDetail.length > 0 && cardDetail[0].address
        ? cardDetail[0].address.split(",")
        : "";

    const additionalData = newOrderAddressAuth3ds(
      cardDetail,
      addre,
      state,
      formik
    );

    const intent = newOrderIntent(state);
    cardRef.current
      .authorizeWith3ds(intent, additionalData)
      .then((data) => {
        setState({ loading: true, intent_id: data.id, error: "" });
        handlePayment(data?.id);

        // handlePayment(data.active_payment_attempt.id_at_gateway);
      })
      .catch((error) => {
        console.log();
        // const time = error?.code === "replyTimeout" ? 2000 : 15000;
        setTimeout(() => {
          setOpenCanvaQuote({ type: "", open: false });
          if (error?.code === "replyTimeout") {
            window.location.reload();
          }
        }, 2000);

        setSnack({
          call: true,
          type: "error",
          message: (
            <span>
              error
              {error?.displayMessage}
              {error?.displayMessage ? <br /> : ""}
              {error.message}
            </span>
          ),
        });
        setState({
          loading: false,
          intent_id: "",
          error: error.message,
        });
      });
  };

  const handleReset = () => {};
  const [paymentIntentD, setPaymentIntent] = useState(null);
  const [paymentIntentCus, setPaymentIntentCus] = useState(null);
  const [createPIntent, setCreatePIntent] = useState(null);
  const [dialog, setDialog] = useState({ modal: false });

  const stripePromise = loadStripe(
    "pk_test_51HHqFcIE5Vvk0efG4dXZ4Y2a1c2uXEOL396ODH98XDRHukdqqkN6MlYOhcu2fCCHdKuhwuWATNxahBkAahwRTScT00v0LL4wQI"
  );
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: paymentIntentD?.client_secret,
    appearance,
  };

  const handlePaymentIntent = (value, stValue) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    // setState({ ...state3ds, intentLoading: true });
    let finalPrice = 0;
    if (state?.collectionRetrun?.return_start_time_slot) {
      finalPrice = handleFinalPrice(state)?.total;
    }

    const data = newOrderPaymentIntent(value, state, stValue, finalPrice);

    paymentIntentStripe(data)
      .then((res) => {
        setPaymentIntent(res?.data.data);
        setPaymentIntentCus(res.data);
        // dispatchReducer({ type: "paymentIntent", value: res.data });
        // setState({ ...state3ds, intentLoading: false });
      })
      .catch((err) => {
        setSnack({
          call: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  // const handleBacsPaymentIntent = (value, stValue) => {
  //   setSnack({
  //     call: false,
  //     type: "",
  //     message: "",
  //   });

  //   let finalPrice = 0;
  //   if (state?.collectionRetrun?.return_start_time_slot) {
  //     finalPrice = handleFinalPrice(state)?.total;
  //   }

  //   const data = newOrderPaymentIntent(value, state, stValue, finalPrice);
  //   // createPaymentIntent(data)
  //   //   .then((res) => {
  //   //     console.log("RESPONSERESESE", res);
  //   //     setCreatePIntent(res?.data.data);
  //   //     // dispatchReducer({ type: "paymentIntent", value: res.data });
  //   //     // setState({ ...state3ds, intentLoading: false });
  //   //   })
  //   //   .catch((err) => {
  //   //     setSnack({
  //   //       call: true,
  //   //       type: "error",
  //   //       message: err?.response?.data?.message,
  //   //     });
  //   //   });
  // };

  // useEffect(() => {
  //   if (paymentIntentD) {
  //     if (stripe && elements && paymentIntentD?.client_secret) {
  //       const paymentElement = elements.create("payment", {
  //         clientSecret: paymentIntentD?.client_secret,
  //         layout: "tabs",
  //       });
  //       paymentElement.mount("#payment-element");
  //     }
  //   }
  // }, [stripe, elements, paymentIntentD?.client_secret]);

  useEffect(() => {
    if (cardDetail && cardDetail.length > 0 && cardDetail[0])
      handlePaymentIntent(cardDetail[0], state);
    // handleBacsPaymentIntent(cardDetail[0], state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetail]);
  const handlePayment = (token) => {
    setState({ ...state3ds, loading: true });
    const data = newOrderPayment(cardDetail, state, formik, token);
    for (let items of state?.productArray) {
      if (
        items?.is_packaging_material === "1" ||
        items?.is_packaging_material === 1
      ) {
        data.packaging_materials.push({
          id: items.id,
          quantity: items.qty ? items.qty : 1,
          name: items.name,
          price: items.cost,
          item_price_id: items.price_id,
        });
      }
      if (
        items.id === "Standard-Access" ||
        items.id === "24hr-Access" ||
        items.id === "Extended-Access"
      ) {
        data.access_type = items.id;
      }
      if (items.type === "addon") {
        const productItem = {
          id: items.id,
          quantity: items.qty ? items.qty : 1,
          name: items.name,
          price: items.cost,
          item_price_id: items.price_id,
          cover: items?.cover,
        };

        if (
          items.id !== "Container-Protect" &&
          items.id !== "Store-Protect" &&
          items.id !== "Insurance-Cover"
        ) {
          // Remove the 'cover' property from productItem
          delete productItem.cover;
        }
        data.product.push(productItem);

        if (
          items.id === "Container-Protect" ||
          items.id === "Store-Protect" ||
          items.id === "Insurance-Cover"
        ) {
          data.insurance_cover = items?.cover;
        }
      } else {
        data.product.push({
          id: items.id,
          quantity: items.qty ? items.qty : 1,
          name: items.name,
          price: items.cost,
          item_price_id: items.price_id,
        });
      }
      data.item_price_id.push(items.price_id);
      data.quantity.push(items.qty ? items.qty : 1);
    }
    if (state?.coupon_id) {
      for (let items of state?.coupon_id) {
        if (items?.id) data.coupon_id.push(items.id);
      }
    }
    if (state?.adhocChargeName) {
      data.adhoc_charge_name = state?.adhocChargeName;
    }
    const adhoc = state?.productArray.findIndex(
      (e) => e.id === state?.adHoc?.id
    );
    if (adhoc !== -1) {
      data.adhoc_charge_price = state?.productArray[adhoc].productPrice;
      data.adhoc_charge_quantity = state?.productArray[adhoc].qty;
    }
    if (state?.manualDiscount?.amount) {
      const datas = {
        duration_type: state?.manualDiscount?.durationType || "",
        apply_on: state?.manualDiscount?.applyOn || "",
        discount_amount:
          (+state?.manualDiscount?.amount &&
            state?.manualDiscount?.manual_discount &&
            (
              +state?.manualDiscount?.amount +
              Number(state?.manualDiscount?.manual_discount)
            ).toFixed(2)) ||
          "",
        discount_type: state?.manualDiscount?.discount_type || "",
        manual_discount_period: state?.manualDiscount?.period || "",
        manual_discount_period_unit: state?.manualDiscount?.periodUnit || "",
        manual_discount_item_price_id:
          state?.manualDiscount?.manual_discount_item_price_id || "",
        // manual_discount: state?.manualDiscount?.manual_discount || "",
      };
      data.apply_manual_discounts.push(datas);
    }
    if (formik?.values?.paymentType) {
      data.payment_type = formik?.values?.paymentType;
    }
    if (state.competitorPrice?.check) {
      const pod = state.productArray.find((e) => e.type === "plan");
      data.competitor_price = state?.competitorPrice?.price || 0;
      data.competitor_pod_price = pod?.cost || 0;
      data.competitor_name = state?.competitorPrice?.name || "";
    }
    const containerExists =
      data.product.some((e) => e.id === "Container") ||
      state.containerTypeSelect === "container";
    const howdenExists = data.product.some((e) => e.id === "Howdens");

    if (containerExists || howdenExists) {
      data.is_container = containerExists ? 1 : 2;

      data.date_type = state.collectionRetrun?.date_type || "";
      data.site_class_id = state.collectionRetrun?.site_class_id || "";
      data.container_return_date =
        state.collectionRetrun?.container_return_date || "";
      data.container_collection_date =
        state.collectionRetrun?.container_collection_date || "";
      data.return_date = state.collectionRetrun?.return_date || "";

      if (state.availData.length > 0) {
        const qty =
          state?.productArray.find((e) => e.type === "plan")?.qty || 0;

        // Filter availData based on the quantity
        const filteredAvailData = state.availData.slice(0, +qty);

        // Extract the 'id' values from the filtered array
        const franchise_container_id = filteredAvailData.map((e) => e.id);

        // Assign the franchise_container_id to data.franchise_container_id
        data.franchise_container_id = franchise_container_id;
        data.end_time_slot = "";
        data.return_end_time_slot = "";
      }
    }

    if (location?.search.includes("quote")) {
      if (
        cardDetail &&
        cardDetail.length > 0 &&
        cardDetail[0] &&
        cardDetail[0].contact?.latest_deals?.id
      ) {
        data.deal_id = cardDetail[0] && cardDetail[0].contact?.latest_deals?.id;
      }
    }
    if (
      state?.containerTypeSelect != "pod" ||
      (state?.containerTypeSelect == "pod" &&
        state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID &&
        state?.productArray?.some(
          (e) =>
            e.type === "plan" &&
            e?.price_id !== process.env?.REACT_APP_PRODUCT_ECONOMY
        ))
    ) {
      data.is_reservation =
        new Date(state.collectionRetrun?.collection_date) <
        new Date(Date.now() + 48 * 60 * 60 * 1000)
          ? 1
          : 2;
    }

    getPlaceOrder(data)
      .then((res) => {
        setConfettiLoading(true);
        // setSnack({ call: true, type: "success", message: res.data.message });

        if (
          (state?.containerTypeSelect == "pod" &&
            state?.franchise_id != process.env?.REACT_APP_FRANCHISE_ID) ||
          (state?.containerTypeSelect == "pod" &&
            state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID &&
            state?.productArray?.some(
              (e) =>
                e.type === "plan" &&
                e?.price_id !== process.env?.REACT_APP_PRODUCT_ECONOMY
            )) ||
          (state.collectionRetrun?.collection_date &&
            new Date(state.collectionRetrun?.collection_date) <
              new Date(Date.now() + 48 * 60 * 60 * 1000)) ||
          (state?.containerTypeSelect === "container" &&
            state.collectionRetrun?.collection_date &&
            // Convert string date to Date object
            new Date(state.collectionRetrun?.collection_date) <
              new Date(Date.now() + 48 * 60 * 60 * 1000)) ||
          // state?.containerTypeSelect === "container" &&
          formik.values?.paymentType !== "card"
        ) {
          setOpenCanvaQuote({ type: "", open: false });

          addPerson(data.email)
            .then(() => {
              window.location.reload();
              setTimeout(() => {}, 2000);
            })
            .catch(() => {
              window.location.reload();
              setTimeout(() => {}, 2000);
            });
          history.push(`/successful-order/${res.data.data?.id}`);
        } else {
          addPerson(data.email);
          const dataCard = {
            customer_chargebee_id: res.data.data?.customer_chargebee_id,
            franchise_id: res.data.data?.franchise_id,
            gw_token: token,
            order_id: res.data.data?.id,
          };
          addCardOnPlace(dataCard)
            .then((resC) => {
              let finalPrice = 0;
              if (state?.collectionRetrun?.return_start_time_slot) {
                finalPrice = handleFinalPrice(state)?.total;
              }
              const twentyPercentOfTotal =
                Number(
                  state?.collectionRetrun?.return_start_time_slot
                    ? finalPrice.toFixed(2)
                    : state?.total.toFixed(2)
                ) * 0.1;
              const ePercentOfTotal =
                Number(
                  state?.collectionRetrun?.return_start_time_slot
                    ? finalPrice
                    : state?.total
                ) * 0.9;
              const dataC = {
                order_id: dataCard?.order_id,
                invoice_id: res.data.data?.invoice_id,
                invoice_due_amount:
                  state?.total == 0 ? 0 : +state?.total.toFixed(2),
                twenty_percent_amount: Number(twentyPercentOfTotal).toFixed(2),
                eighty_percent_amount: Number(ePercentOfTotal).toFixed(2),
                twenty_percent_status: 1,
                ...data,
              };
              if (state.containerTypeSelect !== "container") {
                addPodCharge(dataC)
                  .then((resCh) => {
                    setState({ ...state3ds, loading: false });
                    setSnack({
                      call: true,
                      type: "success",
                      message: resCh.data.message,
                    });
                    setOpenCanvaQuote({ type: "", open: false });

                    history.push(`/successful-order/${res.data.data?.id}`);
                    window.location.reload();
                    setTimeout(() => {}, 2000);
                  })
                  .catch((error) => {
                    setState({ ...state3ds, loading: false });
                    setSnack({
                      call: true,
                      type: "error",
                      message:
                        "Order Placed successfully " +
                        error?.response?.data?.message,
                    });
                  });
              } else {
                addCharge(dataC)
                  .then((resCh) => {
                    setState({ ...state3ds, loading: false });
                    setSnack({
                      call: true,
                      type: "success",
                      message: resCh.data.message,
                    });
                    setOpenCanvaQuote({ type: "", open: false });

                    history.push(`/successful-order/${res.data.data?.id}`);
                    window.location.reload();
                    setTimeout(() => {}, 2000);
                  })
                  .catch((error) => {
                    setState({ ...state3ds, loading: false });
                    setSnack({
                      call: true,
                      type: "error",
                      message:
                        "Order Placed successfully " +
                        error?.response?.data?.message,
                    });
                  });
              }
            })
            .catch((err) => {
              setState({ ...state3ds, loading: false });
              setSnack({
                call: true,
                type: "error",
                message:
                  "Order Placed successfully " + err?.response?.data?.message,
              });
            });
        }
        // setTimeout(() => {}, 3000);
      })
      .catch((error) => {
        setState({ ...state3ds, loading: false });
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  useEffect(() => {
    if (confettiLoading) {
      const timer = setTimeout(() => {
        // Call your function here
        setConfettiLoading(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [confettiLoading]);

  const placeOrder = (paymentIntent) => {
    setIsLoading(true);
    const data = newOrderPaylod(
      paymentIntent,
      state,
      orderSummaryListStripe,
      cardDetail,
      paymentIntentCus,
      formik
    );
    orderPlaceStripe(data)
      .then((res) => {
        addPerson(data.email);
        setIsLoading(false);
        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });
        setTimeout(() => {
          history.push(`/successful-order/${res.data.data?.id}`);
        }, 2000);
      })
      .catch((err) => {
        addPerson(data.email);
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);

    // event.preventDefault(); // Uncomment if using with a form

    if (!stripe || !elements) {
      setIsLoading(false);
      return; // Stripe.js has not yet loaded
    }

    const cardElement = elements.getElement(CardElement);
    // const sortCode = sortCode; // Assuming you're capturing sort code from formik
    // const accountNumber = accountNumber; // Assuming you're capturing account number from formik

    try {
      if (formik.values.paymentType === "bac") {
        if (paymentIntentD) {
          // Confirm BACS Direct Debit
          const {
            error: piError,
            setupIntent,
          } = await stripe.confirmBacsDebitSetup(paymentIntentD.client_secret, {
            payment_method: {
              bacs_debit: {
                sort_code: sortCode,
                account_number: accountNumber,
              },
              billing_details: {
                name: cardDetail?.[0]?.first_name || "", // Customer's name
                email: cardDetail?.[0]?.email || "", // Customer's email
                phone: cardDetail?.[0]?.phone || "", // Customer's phone number
                address: {
                  line1: cardDetail?.[0]?.address || "", // Address line 1
                  line2: "", // Address line 2 (optional)
                  city: state.collectionReturn?.collection_address_city || "", // City
                  postal_code:
                    state.collectionReturn?.collection_postcode || "", // ZIP or postal code
                  country: "GB", // Two-letter country code (ISO 3166-1 alpha-2)
                },
              },
            },
          });

          if (piError) {
            // Handle error
            setSnack({
              call: true,
              type: "error",
              message: piError.message,
            });
          } else {
            // Successfully set up the BACS mandate
            console.log("SetupIntent confirmed: ", setupIntent);
            placeOrder(setupIntent);
          }
          return;
        }
        return;
      }

      const {
        error: piError,
        paymentIntent,
        setupIntent,
      } = await stripe.confirmCardSetup(paymentIntentD.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardDetail?.[0]?.first_name || "", // Customer's name
            email: cardDetail?.[0]?.email || "", // Customer's email
            phone: cardDetail?.[0]?.phone || "", // Customer's phone number
            address: {
              line1:
                formik?.values?.billing === "same"
                  ? cardDetail?.[0]?.address
                  : formik?.values?.street1 || "", // Address line 1
              line2: "", // Address line 2 (optional)
              city:
                formik?.values?.billing === "same"
                  ? state.collectionReturn?.collection_address_city
                  : formik?.values?.city || "", // City
              postal_code:
                formik?.values?.billing === "same"
                  ? state.collectionReturn?.collection_postcode
                  : formik?.values?.postCode || "", // ZIP or postal code
              country: "GB", // Two-letter country code (ISO 3166-1 alpha-2)
            },
          },
        },
      });

      if (piError) {
        setIsLoading(false);
        console.error("[PaymentIntent Error]", piError);
        setSnack({
          call: true,
          type: "error",
          message: piError.message,
        });
      } else {
        console.log("[PaymentIntent]", paymentIntent, setupIntent);
        placeOrder(setupIntent);
      }
    } catch (error) {
      console.error("Unexpected Error:", error);
      setIsLoading(false);
      setSnack({
        call: true,
        type: "error",
        message: error?.response?.data?.message,
      });
      // Handle unexpected errors
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: OrderPaymentSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });
  console.log("helloformik", formik.values);

  useEffect(() => {
    if (formik?.values?.postcode && formik.values?.is_address_manual !== 1) {
      handleGetAddress(formik?.values?.postcode, "onMount");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.postcode]);

  const handleBacsPayment = (linktype, paymentType) => {
    setIsLoading(true);
    // setState({ ...state3ds, loading: true });
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    let payload = {};
    const data = newOrderPaylod(
      "",
      state,
      orderSummaryListStripe,
      cardDetail,
      "",
      formik
    );
    payload = {
      type: linktype,
      amount: data.amount,
      id: data.id,
      currency_code: data.currency_code,
      is_reservation: data.is_reservation ? data.is_reservation : "",
      is_container: data.is_container,
      franchise_id: data.franchise_id,
      phone: data.phone,
      payment_type: paymentType,
      ...data,
    };
    const dataQuote = {
      // to: values.to,
      // subject: values.send,
      // notes: values.notes,
      zip:
        cardDetail && cardDetail.length && cardDetail[0].postcode
          ? cardDetail[0].postcode
          : "",
      quote_type: "draft",
      email:
        cardDetail && cardDetail.length && cardDetail[0].email
          ? cardDetail[0].email
          : "",
      phone:
        cardDetail && cardDetail.length && cardDetail[0].phone
          ? cardDetail[0].phone
          : "",
      contact_id:
        cardDetail &&
        cardDetail.length &&
        cardDetail[0].contact &&
        cardDetail[0].contact?.id
          ? cardDetail[0].contact?.id
          : "",
      first_name:
        cardDetail && cardDetail.length && cardDetail[0].first_name
          ? cardDetail[0].first_name
          : "",
      last_name:
        cardDetail && cardDetail.length && cardDetail[0].last_name
          ? cardDetail[0].last_name
          : "",
      city: "",
      is_student: state.collectionRetrun?.is_student || "0",
      is_student_return: state.collectionRetrun?.is_student_return || "0",
      address:
        cardDetail && cardDetail.length && cardDetail[0].address
          ? cardDetail[0].address
          : "",
      deal_id:
        cardDetail &&
        cardDetail.length &&
        cardDetail[0].contact &&
        cardDetail[0].contact.latest_deals
          ? cardDetail[0].contact.latest_deals?.id
          : "",
      country: "GB",
      agent_id:
        cardDetail &&
        cardDetail.length &&
        cardDetail[0].contact &&
        cardDetail[0].contact?.agent?.id
          ? cardDetail[0].contact?.agent?.id
          : "",

      currency_code: "GBP",
      // pod_count: null,

      // driver_notes: state?.driver_note,
      container_size: state?.containerListSelect || "",
      site_id: state?.siteSelect?.id || "",
      ...state,
      collectionReturn: state.collectionRetrun,
      max_pod: state?.product_array.find((e) => e.type === "plan")?.quantity,
      is_container: state.containerTypeSelect === "container" ? 1 : 0,
    };
    // delete state.collectionRetrun;
    if (state.containerTypeSelect !== "container") {
      delete dataQuote.container_size;
      delete dataQuote.site_id;
    }
    sendSmsEmailPayUrl(payload)
      .then((res) => {
        sendQuote(dataQuote)
          .then((response) => {
            setState({ ...state3ds, loading: false });
            // setSnack({
            //   call: true,
            //   type: "success",
            //   message:
            //     "Order saved as draft, it will be convert into an order when customer pays via the sent link",
            // });
            setDialog({ modal: true });
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);

            setSnack({
              call: true,
              type: "error",
              message: error?.response?.data?.message,
            });
          });
      })
      .catch((error) => {
        setIsLoading(false);

        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  const handlePushToContact = () => {
    setIsLoading(true);
    setTimeout(() => {
      history.push(
        `/contact-details/${
          cardDetail &&
          cardDetail.length &&
          cardDetail[0].contact &&
          cardDetail[0].contact?.id
            ? cardDetail[0].contact?.id
            : ""
        }?draft=1`
      );
    }, 3000);
  };

  const handlePayByLink = () => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    let checkLatestDeal =
      (cardDetail &&
        cardDetail.length &&
        cardDetail[0].contact &&
        cardDetail[0].contact.latest_deals?.id) ||
      "";
    const data = newOrderPayment(cardDetail, state, formik);
    for (let items of state?.productArray) {
      if (items.type === "addon") {
        const productItem = {
          id: items.id,
          quantity: items.qty ? items.qty : 1,
          name: items.name,
          price: items.cost,
          item_price_id: items.price_id,
          cover: items?.cover,
        };

        if (
          items.id !== "Container-Protect" &&
          items.id !== "Store-Protect" &&
          items.id !== "Insurance-Cover"
        ) {
          // Remove the 'cover' property from productItem
          delete productItem.cover;
        }
        data.product.push(productItem);

        data.insurance_cover = items?.cover;
      } else {
        data.product.push({
          id: items.id,
          quantity: items.qty ? items.qty : 1,
          name: items.name,
          price: items.cost,
          item_price_id: items.price_id,
        });
      }
      data.item_price_id.push(items.price_id);
      data.quantity.push(items.qty ? items.qty : 1);
    }
    if (state?.coupon_id) {
      for (let items of state?.coupon_id) {
        if (items?.id) data.coupon_id.push(items.id);
      }
    }
    if (state?.adhocChargeName) {
      data.adhoc_charge_name = state?.adhocChargeName;
    }
    const adhoc = state?.productArray.findIndex(
      (e) => e.id === state?.adHoc?.id
    );
    if (adhoc !== -1) {
      data.adhoc_charge_price = state?.productArray[adhoc].productPrice;
      data.adhoc_charge_quantity = state?.productArray[adhoc].qty;
    }
    if (state?.manualDiscount?.applyOn) {
      const datas = {
        duration_type: state?.manualDiscount?.duration || "",
        apply_on: state?.manualDiscount?.applyOn || "",
        discount_amount: state?.manualDiscount?.enterDiscount || "",
        discount_type: state?.manualDiscount?.discountType || "",
        manual_discount_period: state?.manualDiscount?.limitedPeriod || "",
        manual_discount_period_unit: state?.manualDiscount?.time || "",
      };
      data.apply_manual_discounts.push(datas);
    }
    data.payment_link = 1;
    data.to = (cardDetail && cardDetail.length && cardDetail[0].email) || "";
    data.subject = "hello, your easyStorage enquiry.";
    data.deal_id = checkLatestDeal;
    if (state?.collectionRetrun?.return_start_time_slot) {
      data.amount = handleFinalPrice(state)?.total;
    }

    const containerExists =
      data.product.some((e) => e.id === "Container") ||
      state.containerTypeSelect === "container";
    const howdenExists = data.product.some((e) => e.id === "Howdens");

    if (containerExists || howdenExists) {
      data.is_container = containerExists ? 1 : 2;
      data.date_type = state.collectionRetrun?.date_type || "";
      data.site_class_id = state.collectionRetrun?.site_class_id || "";
      data.container_return_date =
        state.collectionRetrun?.container_return_date || "";
      data.container_collection_date =
        state.collectionRetrun?.container_collection_date || "";
      data.return_date = state.collectionRetrun?.return_date || "";

      if (state.availData.length > 0) {
        const qty =
          state?.productArray.find((e) => e.type === "plan")?.qty || 0;

        // Filter availData based on the quantity
        const filteredAvailData = state.availData.slice(0, +qty);

        // Extract the 'id' values from the filtered array
        const franchise_container_id = filteredAvailData.map((e) => e.id);

        // Assign the franchise_container_id to data.franchise_container_id
        data.franchise_container_id = franchise_container_id;
        data.end_time_slot = "";
        data.return_end_time_slot = "";
      }
    }
    if (
      state?.containerTypeSelect != "pod" ||
      (state?.containerTypeSelect == "pod" &&
        state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID &&
        state?.productArray?.some(
          (e) =>
            e.type === "plan" &&
            e?.price_id !== process.env?.REACT_APP_PRODUCT_ECONOMY
        ))
    ) {
      data.is_reservation =
        new Date(state.collectionRetrun?.collection_date) <
        new Date(Date.now() + 48 * 60 * 60 * 1000)
          ? 1
          : 2;
    }

    getPlaceOrder(data)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  // const hello = () => {
  //   const container = document.querySelector(".fireworks");
  //   const fireworks = new Fireworks.default(container);
  //   fireworks.start();
  // };
  const cardElementOptions = {
    style: {
      base: {
        // Add your custom styles here
        fontSize: "16px",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
    hidePostalCode: true, // This removes the ZIP code field
  };
  const handleManualUpdate = () => {
    formik.setFieldValue("street1", "");
    formik.setFieldValue("city", "");
    formik.setFieldValue("is_address_manual", 1);
  };

  return (
    <div
      id="kt_orderpayment_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_orderpayment_panel_close"
            onClick={() => setOpenCanvaQuote({ type: "", open: false })}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          {confettiLoading && (
            <div className="offcanvas-overlay ">
              <div className="customloader">
                {/* <Fireworks
                    style={{
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      position: "fixed",
                      background: "transparent",
                    }}
                    enabled={enabled}
                    options={options}
                  ></Fireworks> */}
                {/* <Confetti width={width} height={height} /> */}
              </div>
            </div>
          )}
          <div>
            <h5 onClick={() => hello()}>
              {intl.formatMessage({ id: "Order.Payment" })}
            </h5>
          </div>

          <div className="row mt-4">
            <div className="mb-4 col-4">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="enabled_for_checkout"
                  checked={formik.values?.paymentType === "card" ? true : false}
                  onChange={(e) => formik.setFieldValue("paymentType", "card")}
                />
                <span className="mr-2"></span>
                Card
              </label>
            </div>
            <div className="mb-4 col-4 ">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="enabled_for_checkout"
                  checked={formik.values?.paymentType === "cash" ? true : false}
                  onChange={(e) => formik.setFieldValue("paymentType", "cash")}
                />
                <span className="mr-2"></span>
                Cash
              </label>
            </div>{" "}
            <div className="mb-4 col-4">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="enabled_for_checkout"
                  checked={formik.values?.paymentType === "bac" ? true : false}
                  onChange={(e) => formik.setFieldValue("paymentType", "bac")}
                />
                <span className="mr-2"></span>
                Bank Transfer
              </label>
            </div>{" "}
          </div>

          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10 custom-scroll"
            id="kt_quick_panel_logs_kt_orderpayment_panel"
          >
            {formik.values.paymentType == "card" && (
              <div style={{ width: "89%" }}>
                <CardElement options={cardElementOptions} />
              </div>
            )}
          </div>
          {formik?.values?.paymentType === "card" && (
            <div className="mb-7 mt-8 pt-8 payment-radio">
              <Form.Check
                className="mb-4"
                type="radio"
                label={intl.formatMessage({ id: "Billing.address" })}
                name="formHorizontalRadios"
                id="formHorizontalRadios1"
                checked={formik.values.billing === "same"}
                onClick={() => formik.setFieldValue("billing", "same")}
              />
              <Form.Check
                type="radio"
                label={intl.formatMessage({
                  id: "Billing.customer.address",
                })}
                name="formHorizontalRadios"
                id="formHorizontalRadios2"
                checked={formik.values.billing === "manual"}
                onClick={() => formik.setFieldValue("billing", "manual")}
              />
            </div>
          )}
          {formik.values.billing === "manual" ? (
            <div className="row">
              <div className="col-md-12 mb-5">
                <label>{intl.formatMessage({ id: "Post.Code" })}</label>
                <div className="row align-items-center">
                  <div className="col-6 d-flex justify-content-between align-items-center ">
                    <div className="form-groupB w-100">
                      <input
                        type="text"
                        className={`form-control  ${getInputClasses(
                          formik,
                          "postCode"
                        )}`}
                        placeholder={intl.formatMessage({
                          id: "Post.Code",
                        })}
                        name="postCode"
                        value={formik.values?.postCode}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Remove any non-alphanumeric characters from the input value
                          const alphanumericValue = inputValue.replace(
                            /\W/g,
                            ""
                          );

                          // Use regex to format the input value as a UK postcode
                          const formattedValue = alphanumericValue.replace(
                            /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s?(\d[A-Z]{2})$/i,
                            "$1 $2"
                          );
                          formik.setFieldValue("postCode", formattedValue);
                        }}
                        // {...formik.getFieldProps("postcode")}
                      />
                    </div>
                    <div className="mb-2">
                      <span className="symbol-label ml-2">
                        <span
                          className="svg-icon svg-icon-lg svg-icon-primary"
                          onClick={() =>
                            handleGetAddress(formik.values.postCode)
                          }
                        >
                          <SVG
                            className="h-25 align-self-end"
                            style={{ cursor: "pointer" }}
                            title=" "
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Search.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2 123">
                    <span
                      style={{
                        color: "#ff9f60",
                        cursor: "pointer",
                      }}
                      onClick={handleManualUpdate}
                    >
                      {intl.formatMessage({ id: "Enter.Manually" })}
                    </span>
                  </div>
                  {formik.touched.postCode && formik.errors.postCode ? (
                    <div className=" ml-4 mb-4 fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik?.errors?.postCode}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <label>Address</label>
                <div className="form-groupB">
                  {formik.values.is_address_manual === 1 ? (
                    <input
                      type="text"
                      className={`form-control  ${getInputClasses(
                        formik,
                        "street1"
                      )}`}
                      placeholder="address"
                      name="street1"
                      {...formik.getFieldProps("street1")}
                    />
                  ) : (
                    <FormControl fullWidth>
                      <Select
                        name="street1"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "street1"
                        )}`}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        placeholder="Address"
                        displayEmpty
                        {...formik.getFieldProps("street1")}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "street1" })}
                        </MenuItem>
                        {dropDown?.address && dropDown?.address.length > 0
                          ? dropDown?.address.map((data, index) => {
                              let addressData = data?.formatted_address.toString();
                              addressData = addressData?.replaceAll(", ", ",");
                              addressData = addressData?.replaceAll(",, ", ",");
                              addressData = addressData?.replaceAll(",,,", ",");

                              return (
                                <MenuItem value={addressData} key={index}>
                                  {addressData}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  )}

                  {formik.touched.street1 && formik.errors.street1 ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.street1}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <label>{intl.formatMessage({ id: "CITY" })}</label>
                <div className="form-groupB">
                  <input
                    type="text"
                    className={`form-control  ${getInputClasses(
                      formik,
                      "city"
                    )}`}
                    placeholder={intl.formatMessage({ id: "CITY" })}
                    name="city"
                    {...formik.getFieldProps("city")}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.city}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {paymentIntentD && paymentIntentD.client_secret && (
            <Elements options={options} stripe={stripePromise}>
              <div
                className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10 custom-scroll"
                id="kt_quick_panel_logs_kt_orderpayment_panel"
              >
                 <label htmlFor="sort-code">Sort Code</label>
      <input
        type="text"
        id="sort-code"
        value={sortCode}
        onChange={(e) => setSortCode(e.target.value)}
        placeholder="108800"
        required
      />

      <label htmlFor="account-number">Account Number</label>
      <input
        type="text"
        id="account-number"
        value={accountNumber}
        onChange={(e) => setAccountNumber(e.target.value)}
        placeholder="00012345"
        required
      />

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <button type="submit" disabled={loading}>
        {loading ? 'Processing...' : 'Pay'}
      </button> */}
          {/* <IbanElement
                  options={{
                    supportedCountries: ["SEPA"], // Optional: Specify supported countries
                  }}
                />
                <PaymentElement
  options={{
    paymentMethodOrder: ['card', 'bacs_debit'], // Show card and BACS Direct Debit
    supportedCountries: ['GB'], // Support UK (GB) specific methods
  }}
/> */}
          {/* </div>
            </Elements>
          )} */}
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-center ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => setOpenCanvaQuote({ type: "", open: false })}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            {formik?.values?.paymentType === "card" && (
              <>
                {/* SMS Checkbox */}
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={typeCheck === "sms" || typeCheck === "both"}
                      onChange={(e) => {
                        if (typeCheck == "email") {
                          setTypeCheck("both");
                          return;
                        }
                        setTypeCheck(
                          e.target.checked
                            ? "sms"
                            : typeCheck == "both"
                            ? "email"
                            : ""
                        );
                      }}
                    />
                  }
                  label="Send SMS"
                />

                {/* Email Checkbox */}
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={typeCheck === "email" || typeCheck === "both"}
                      onChange={(e) => {
                        if (typeCheck == "sms") {
                          setTypeCheck("both");
                          return;
                        }
                        setTypeCheck(
                          e.target.checked
                            ? "email"
                            : typeCheck == "both"
                            ? "sms"
                            : ""
                        );
                      }}
                    />
                  }
                  label="Send Email"
                />
              </>
            )}
            {/* {formik?.values?.paymentType === "bac" ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bolder font-size-sm mr-3"
                  onClick={() => 
                    
                    handleBacsPayment("sms", "bac")}
                  // disabled={state3ds.loading}
                >
                  Send SMS
                </button>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bolder font-size-sm mr-3"
                  onClick={() => handleBacsPayment("email", "bac")}
                  // disabled={state3ds.loading}
                >
                  Send Email
                </button>
              </>
            ) : null} */}
            <button
              type={
                formik.values.paymentType == "cash" ||
                formik.values.paymentType == "bac" ||
                typeCheck != ""
                  ? "button"
                  : "submit"
              }
              className="btn btn-primary font-weight-bolder font-size-sm "
              // onClick={
              //     Object.keys(formik.errors).length === 0 &&
              //         formik.values?.firstName &&
              //         formik.values?.paymentType === "card"
              //         ? authorizeWith3ds
              //         : state.containerTypeSelect === "container" &&
              //             state.collectionRetrun?.collection_date &&
              //             // Convert string date to Date object
              //             new Date(state.collectionRetrun?.collection_date) <
              //             new Date(Date.now() + 48  60  60 * 1000) &&
              //             formik.values?.paymentType === "card"
              //             ? authorizeWith3ds
              //             : handlePayment
              // }
              onClick={() => {
                if (typeCheck != "") {
                  handleBacsPayment(typeCheck, "card");
                  return;
                }
                if (
                  formik.values.paymentType == "cash" ||
                  formik.values.paymentType == "bac"
                ) {
                  placeOrder("");
                }
              }}
              disabled={state3ds.loading || state3ds?.intentLoading}
            >
              {typeCheck != "" ? "Place Order" : ""}
              {typeCheck == ""
                ? state.containerTypeSelect === "container"
                  ? "Payment"
                  : intl.formatMessage({ id: "Place.Order" })
                : ""}
              {state3ds?.intentLoading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      {/* {snack.call ? <SnackBarTool {...snack} /> : ""} */}
      {isLoading && (
        <div className="offcanvas-overlay ">
          <div className="customloader">
            <div class="spinner-border customSpinner" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <DynamicDialog
        setDialog={setDialog}
        dialog={dialog}
        header={"Alert"}
        mainText={
          "Order saved as draft, it will be convert into an order when customer pays via the sent link"
        }
        page={"newOrder"}
        handleChange={handlePushToContact}
        loading={isLoading}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
