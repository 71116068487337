import axios from "axios";
export const ORDER_URL = "order";
export const SEARCH_NEW_ORDER = "order/search-customer";
export const NEW_CUSTOMER_CARD = "order/get-customer-details/";
export const SEARCH_PRODUCT = "order/search-product";
export const SEARCH_ORDER_SUMMARY = "order/order-summary-list";
export const OFFER_CODE = "get-offer-code";
export const AD_HOC = "order/get-ad-hoc-charge";
export const SEND_QUOTES = "order/stripe/send-quote";
export const PAYMENT_INTENT = "order/create-payment-intent";
export const PLACE_ORDER = "order/place-order";
export const SMS_PLACE_ORDER = "sms-place-order";
export const QUOTE = "order/stripe/get-selected-quotes";
// export const ORDER_CUSTOMER = "/get-order-details/";
export const ORDER_CUSTOMER = "/stripe/get-order-details/";
export const ORDER_DETAILS = "order-details/";
export const ORDER_TIMELINE = "/get-orders-timeline?";
export const ORDER_DETAIL = "order-details/order-details-update";
// export const CARD_DETAIL = "/bank-card-details?";
export const CARD_DETAIL = "/stripe/bank-card-details?";

export const ADD_CARD = "/add-new-card";
export const ADD_CARD_ON_PLACE = "add-card-reserved-payments";
export const ADD_CARD_SMS = "sms-add-new-card";
export const PRIMARY = "/stripe/assign-payment-role";
export const DELETE = "/stripe/delete-customer-card";
// export const BILLING_CARD = "/billing-card-details?";
export const BILLING_CARD = "/stripe/billing-card-details?";
export const PAYMENT_INVOICE = "/invoice-payments-list?";
export const DOWNLOAD_INVOICE = "/invoice-pdf-download?";
export const INVOICE_LIST = "/customer-invoices-list?";
export const PUT_ON_HOLD = "/stripe/put-on-hold";
export const ORDER_DROPDOWN = "/order-dropdown-list";
export const REFUND = "/create-refund-invoice";
export const REFUND_CREDIT = "/refunding-credit-notes";
export const CREDIT_NOTES = "/create-credit-note";
export const NEXT_BILLING = "/stripe/next-billing-date-update";
export const ADHOC_CHARGE = "/stripe/add-adhoc-charge";
export const OFFLINE_PAYMENT = "order-details/create-offline-payments";
export const STORAGE_DETAIL = "/update-storage-details";
export const DELETE_ORDER = "/delete-orders";
export const PAYMENT_PLAN = "/collect-payment-plan";
export const UPDATE_ORDER = "/update-order-subscription";
export const ORDER_SUMMARY_LIST = "/edit-order-summary?";
export const ORDER_INVOICE = "/order-invoice-data?";
export const DRIVER_LOADER = "/driver-loader-list?";
export const ADD_DRIVER_LOADER = "/assign-driver-loader";
export const SAVED_DRIVER_LOADER = "/get-assigned-driver-loader?";
export const RESUME_SUBSCRIPTION = "/stripe/resume-subscription";
export const CANCEL_SUBSCRIPTION = "/stripe/cancel-subscription";
export const EXTRA_MILES = "get-extra-miles-product";
export const ACCESS_UPDATE = "order-details/order-access-save";
export const CANCEL_BOOKING = "order/cancel-bookings";
export const CANCEL_RETURN = "order/cancel-return";
export const CANCEL_PAR_RETURN = "order/stripe/cancel-partial-return";

export const COMPLETE_BOOKING = "order/complete-access-booking";
export const UPDATE_DEPOT = "order/order-depot-update";
export const VERSION = "order/order-franchise-version-details";
export const POSTCODE_FRANCHISE = "postcode-franchise";
export const COLLECTION_EXTRA_CHARGE =
  "order-details/collection-return-extra-charge";
export const RETURN_EXTRA_CHARGE = "order-details/plan-extra-charge";
export const LATE_FEE_STATUS = "order/latefee-status";
export const ACCESS_MONITOR = "order-details/access-monitor";
export const BILLING_COUPONS = "order-summary/billing-cycle-coupons?";
export const PACKAGING_LIST = "order/get-packaging-materials?";
export const BOOKINGACCESSCHARGES = "order-details/access-charge-options?";
export const COLLECTION_COMPLETE = "order/collection-complete";
export const UPDATE_CONTACT_FRANCHISE = "order/update-contact-franchise";
export const EXPIRE = "check-payment-link-expiry";
export const DELETE_INSURANCE = "order/subscription-addon-remove";
export const PAYMENT_FAILD = "payment-link-error";
export const CHECKOUT_SENDQUOTE = "link-send-quote";
export const ORDER_RECURRING = "v1-invoices/get-recurring-array";
export const CASH_OFFLINE_PAYMENT = "order/cash-offline-payment";
export const GET_STATS = "order/order-stats";
export const GET_NEW_FLAG = "order/extra-order-details?id=";
export const GET_CONDITION_STATS = "order-details/mark-void/";
export const ESTIMATION = "order/order-update-charge-estimate";
export const MAVIS_PACKAGING_MATERIAL = "mavis-packaging";
export const AUTO_COLLECT = "order/stripe/auto-collect-payments";
export const PAUSE_PAY = "order-details/pause-payment-collection";
export const ADD_PRODUCT = "order/get-addon-charges-list?";
export const GET_ADDON_CHARGE = "order/get-chargebee-products-prices?";
export const BREAK_DOWN = "order/subscription-update-breakdown";
export const UPDATE_SUBSCRIPTION = "order/change-order-subscription";
export const DUE_INVOICE = "order/change-invoice-status";
export const REQUEST_PAYMENT = "order/stripe/request-payment-email";
export const CANCELATION_CHARGE = "order/cancellation-charge";
export const GET_SCHEDULE_CHANGE = "order/subscription-scheduled-changes?";
export const COVER_LIST = "order/insurance-cover-list?price=";
export const PAR_BOOKING = "order/complete-partial-return";
export const ACCESS_MONITOR_PAR = "order-details/partial-return-charge";
export const EXCEMPT_INCREASE = "order/exempt-price-increase";
export const NEAR_SITE = "franchise-site/get-site-list";
export const CONTAINER_LIST = "container/sizes";
export const CONTAINER_PLAN = "container/plans";
export const CHARGE_20 = "order/container-payment-status?order_id=";
export const ADD_CHARGE = "order/collect-container-payment";
export const ADD_POD_CHARGE = "order/collect-pod-payment";
export const ADD_SMS_POD_CHARGE = "sms-collect-pod-payment";
export const ADD_CHARGE_SMS = "sms-collect-container-payment";
export const CHECK_AVAILABILITY = "franchise-container/container-availability";
export const CONTAINER_UPDATE = "order-details/container-order-details-update";
export const EXTRA_CHARGE = "order-details/container-extra-charge?";
export const CONFIRM = "order/confirm-container-order";
export const CONTAINER_EDIT = "order/franchise-container-details-update";
export const MOVEIN = "order/change-container-status";
export const DELETE_CONTAINER = "order/delete-order-container-details";
export const UPDATE_PLAN = "update-order-plan";
export const ORDER_ACCESS_CHARGE =
  "order-details/order-access-charge?franchise_id=";
export const PAYMENT_STATUS = "order/pod-payment-status";
export const SEND_LABEL = "self-booking/send-label";
export const CHANGE_TYPE = "order/change-payment-method";
export const EMAIL_PAYMENT = "order-details/resend-confirmation-email";
export const RESEND = "order/reactivate-subscriptions";
export const COLLECTION_CHARGE = "order-details/collection-return-day-charges?";
//new-order-strip
export const ORDER_SUMMARY_LIST_STRIPE = "order-summary/all-products";
export const PAYMENT_INTENT_STRIPE = "order/stripe-payment-intent";
export const CREATE_PAYMENT_INTENT = "order/stripe/create-payment-intent";
export const PAYMENT_ADD_CARD_STRIPE = `order/stripe/add-new-card`;
export const create_payment_intent_Stripe = `order/stripe/create-payment-intent`;
export const ORDER_PLACE_STRIPE = "order-summary/stripe-create-order";
export const create_setup_intent_Stripe = `order/stripe/create-setup-intent`;
export const EDIT_STRIPE_ORDER = "order-details/update-order-subscription";
export const UPCOMING_INVOICE_DETAIL =
  "order-details/get-upcoming-invoice-detail";
export const CREATE_BAC_ORDER = "order/stripe-create-order";
export const PAYMENTS_TIMELINE =
  "order-details/payments-timeline/items?invoice_id=";
export const CUSTOMER_PAYMENT = "stripe/customer-payments/listing?";
export const HAS_ARREAR = "order-details/has-arrears?customer_id=";
export const HAS = "order-details/update-arrear";
export const FRANCHISE_TYPE =
  "franchise-container/franchise-plan-type?franchise_id=";
export const GET_NOTIFICATION = "order-details/get-noti/";
export const POST_NOTIFICATION = "order-details/action-on-notification";
export const INARREARS = "order-details/is-In-arrears";
export const PERIOD_GET = "order/stripe/extra-subscription-details/";
export const BALANCE_LIST =
  "order/stripe/get-customer-balance-transaction?order_id=";
export const ADJUST_BALANCE = "order/stripe/atjust-debt-by-adding-credit";
export const SUB_DETAIL = "stripe/subscription-details?subscription_id=";
export const DISABLE_25 = "order-details/disable-price-increase-on-order";
export const ENABLE_25 =
  "order-details/execute-command-for-price-increaseon-order";

export function disable25Api(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DISABLE_25, data);
}
export function enable25Api(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ENABLE_25, data);
}

export function getSubDetail(data) {
  return axios.get(process.env.REACT_APP_SITE_URL + SUB_DETAIL + data);
}

export function setAdjustBalance(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ADJUST_BALANCE, data);
}

export function findBalanceList(data) {
  return axios.get(process.env.REACT_APP_SITE_URL + BALANCE_LIST + data);
}

export function getPeriod(data) {
  return axios.get(process.env.REACT_APP_SITE_URL + PERIOD_GET + data);
}

export function getInarrearsStatus(data) {
  console.log("data-90", data);
  return axios.post(process.env.REACT_APP_SITE_URL + INARREARS, data);
}

export function patchNoti(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + POST_NOTIFICATION, data);
}
export function getNotification(data) {
  return axios.get(process.env.REACT_APP_SITE_URL + GET_NOTIFICATION + data);
}

export function getfranchiseType(data) {
  return axios.get(process.env.REACT_APP_SITE_URL + FRANCHISE_TYPE + data);
}

export function hasUpdate(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + HAS, data);
}

export function getHasArrear(data) {
  return axios.get(process.env.REACT_APP_SITE_URL + HAS_ARREAR + data);
}

export function findTransferCard(query) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      CUSTOMER_PAYMENT +
      `customer_id=${query?.customer_id}&starting_after=${query?.starting_after}&ending_before=${query?.ending_before}&limit=${query?.limit}`
  );
}

export function paymentsTimelineItems(data) {
  return axios.get(process.env.REACT_APP_SITE_URL + PAYMENTS_TIMELINE + data);
}

export function editStripeOrder(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + EDIT_STRIPE_ORDER, data);
}

export function getUpcomingInvoiceDetail(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + UPCOMING_INVOICE_DETAIL,
    data
  );
}

export function CreateSetupIntentStripe(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + create_setup_intent_Stripe,
    data
  );
}

export function CreatePaymentIntentStripe(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + create_payment_intent_Stripe,
    data
  );
}
//new-order-stripe
export function orderSummaryList(data) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_SUMMARY_LIST_STRIPE +
      `?franchise_id=${data?.franchise_id}&postcode=${data?.postcode}&return_postcode=${data?.return_postcode}&site_id=${data?.site_id}`
  );
}
export function paymentIntentStripe(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + PAYMENT_INTENT_STRIPE,
    data
  );
}

export function createPaymentIntent(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + CREATE_PAYMENT_INTENT,
    data
  );
}

export function paymentAddCardStripe(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + PAYMENT_ADD_CARD_STRIPE,
    data
  );
}
export function orderPlaceStripe(data) {
  console.log("data-98", data);
  return axios.post(process.env.REACT_APP_SITE_URL + ORDER_PLACE_STRIPE, data);
}

export function changeType(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + CHANGE_TYPE, data);
}
export function podStatus(data) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + PAYMENT_STATUS + `?order_id=${data}`
  );
}
export function orderAccessCharge(data) {
  return axios.get(process.env.REACT_APP_SITE_URL + ORDER_ACCESS_CHARGE + data);
}

export function updatePlan(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + UPDATE_PLAN, data);
}
// /franchise-container/delete/container
export function moveInComplete(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + MOVEIN, data);
}
export function containerEdit(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + CONTAINER_EDIT, data);
}
export function confirmContainer(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + CONFIRM, data);
}

export function containerDateUpdate(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + CONTAINER_UPDATE,
    queryParams
  );
}
export function checkAvailability(data) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      CHECK_AVAILABILITY +
      `?franchise_id=${data?.franchise_id}&type=${data?.type}&container_size=${data?.container_size}&site_id=${data?.site_id}&collection_date=${data?.collection_date}`
  );
}
export function addCharge(queryParams) {
  return axios.post(process.env.REACT_APP_SITE_URL + ADD_CHARGE, queryParams);
}
export function addPodCharge(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ADD_POD_CHARGE,
    queryParams
  );
}
export function smsAddCharge(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ADD_CHARGE_SMS,
    queryParams
  );
}
export function smsAddPod(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ADD_SMS_POD_CHARGE,
    queryParams
  );
}
export function showCharge20(price) {
  return axios.get(process.env.REACT_APP_SITE_URL + CHARGE_20 + price);
}

export function containerList() {
  return axios.get(process.env.REACT_APP_SITE_URL + CONTAINER_LIST);
}
export function containerPlan() {
  return axios.get(process.env.REACT_APP_SITE_URL + CONTAINER_PLAN);
}
export function nearSite(data) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      NEAR_SITE +
      `?franchise_id=${data.franchise_id}&container_size=${data.containerSize}&type=${data.type}`
  );
}
export function insuranceCoverList(price) {
  return axios.get(process.env.REACT_APP_SITE_URL + COVER_LIST + price);
}

export function getScheduleChanges(id, subId) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      GET_SCHEDULE_CHANGE +
      `franchise_id=${id}&subscription_id=${subId}`
  );
}
export function cancellationPrice() {
  return axios.get(process.env.REACT_APP_SITE_URL + CANCELATION_CHARGE);
}

export function dueInvoice(queryParams) {
  return axios.post(process.env.REACT_APP_SITE_URL + DUE_INVOICE, queryParams);
}
export function expireCheck(queryParams) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + EXPIRE + `?token=${queryParams}`
  );
}
export function requestPayment(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + REQUEST_PAYMENT,
    queryParams
  );
}
export function resend(queryParams) {
  return axios.post(process.env.REACT_APP_SITE_URL + RESEND, queryParams);
}
export function emailPayment(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + EMAIL_PAYMENT,
    queryParams
  );
}

export function recurring_check(order_id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + ORDER_RECURRING + `?order_id=${order_id}`
  );
}

export function getAllProducts() {
  return axios.get(ORDER_URL);
}

export function findOrder(queryParams) {
  return axios.post(`${ORDER_URL}/find`, { queryParams });
}

export function findNewSearchOrder(queryParams, controllerRef) {
  const api = axios.get(
    process.env.REACT_APP_SITE_URL + SEARCH_NEW_ORDER + `?name=${queryParams}`,
    { signal: controllerRef?.signal }
  );
  return api;
  // axios.get(
  //   process.env.REACT_APP_SITE_URL + SEARCH_NEW_ORDER + `?name=${queryParams}`
  // );
}

export function findSearchProduct(queryParams, name, paramData) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      SEARCH_PRODUCT +
      `?name=${name}&franchise_id=${queryParams}&period_unit=${"week"}&period=${
        paramData?.unit
      }`
  );
}

//postcode filter address api
export function getAddress(postcode) {
  return axios.get(
    `${process.env.REACT_APP_GET_ADDRESS_API + "find"}/${postcode}?api-key=${
      process.env.REACT_APP_GET_ADDRESS_KEY
    }&expand=true`
  );
}

export function findOrderSummary(
  deletedProduct,
  check,
  unit,
  period,
  productId,
  franchise_id,
  postcode,
  qty,
  delete_base_product,
  data
) {
  return axios.post(process.env.REACT_APP_SITE_URL + SEARCH_ORDER_SUMMARY, {
    deleteproduct: deletedProduct,
    check: check,
    period_unit: period,
    period: unit,
    products_id: productId,
    franchise_id: franchise_id,
    postcode: postcode,
    pod_count: qty,
    delete_base_product: delete_base_product ? delete_base_product : 1,
    ...data,
  });
}

export function findNewCustomerCard(id) {
  return axios.get(process.env.REACT_APP_SITE_URL + NEW_CUSTOMER_CARD + id);
}

export function findQuoteOrder(franchise, quote) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      QUOTE +
      `?franchise_id=${franchise}&quote_id=${quote}`
  );
}

export function findOfferCode(code, franchise, id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      OFFER_CODE +
      `?offer_code=${encodeURIComponent(
        code
      )}&franchise_id=${franchise}&search_by_id=${id}`
  );
}

export function findAdHoc(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + AD_HOC + `?franchise_id=${id}`
  );
}

export function sendQuote(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + SEND_QUOTES, data);
}

export function getPaymentIntent(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + PAYMENT_INTENT, data);
}

export function getBacPaymentIntent(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + "/bac-payment-intent",
    data
  );
}

export function getPlaceOrder(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + PLACE_ORDER, data);
}
export function getSmsPlaceOrder(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + SMS_PLACE_ORDER, data);
}
//my order list api
export function findMyOrderList(queryParams) {
  console.log("queryParams", queryParams);

  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_URL +
      `?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&status=${queryParams?.filter?.status}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}&search_view=${queryParams.filter.search_view}&search_agent=${queryParams.filter.search_agent}&search_franchise=${queryParams.filter.search_franchise}&search_status=${queryParams.filter.search_status}&from_date=${queryParams.filter?.from_date}&to_date=${queryParams.filter?.to_date}`
  );
}
//my order customer detail
export function findMyOrderDetailCard(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + ORDER_URL + ORDER_CUSTOMER + id
  );
}
// findEditOrderDetailCard
export function findEditOrderDetailCard(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + ORDER_URL + ORDER_CUSTOMER + id
  );
}
//my order timeline
export function findMyOrderTimeline(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_URL +
      ORDER_TIMELINE +
      `order_id=${id}`
  );
}

//my order driver note
export function orderDetailUpdate(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ORDER_DETAIL, data);
}

//cardDetail
export function findCardDetail(id, charge, subId, order) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_URL +
      CARD_DETAIL +
      `franchise_id=${id}&order_id=${order}&customer_chargebee_id=${charge}&subscription_id=${subId}`
  );
}

//add card
export function addCards(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + ADD_CARD,
    data
  );
}
export function addCardOnPlace(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ADD_CARD_ON_PLACE, data);
}
//add card
export function smsAddCards(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ADD_CARD_SMS, data);
}

//add primary
export function primaryCard(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ORDER_URL + PRIMARY, data);
}

//delete card
export function deleteCard(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ORDER_URL + DELETE, data);
}

//billing card detail
export function findBillingCardDetail(id, sub, cusId, oId) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_URL +
      BILLING_CARD +
      `franchise_id=${id}&subscription_id=${sub ||
        ""}&customer_id=${cusId}&order_id=${oId}`
  );
}
//payment timeline list
export function findPaymentInvoice(
  id,
  fra,
  subId,
  is_subscription_id,
  invoice_id
) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      "order-details/payments-timeline?" +
      `franchise_id=${fra}&order_id=${id}&subscription_id=${subId}&is_subscription_id=${is_subscription_id}&invoice_id=${invoice_id}`
  );
}

//download invoice
export function downloadInvoice(id, invoice, type, orderId) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      "order-details" +
      DOWNLOAD_INVOICE +
      `invoice_id=${invoice}&invoice_type=${type}&order_id=${orderId}`
  );
}
export function downloadChargeBeeInvoice(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + `chargebee/download-invoice/${id}`
  );
}

//invoiceList
export function findInvoiceList(id, order, subId) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_URL +
      INVOICE_LIST +
      `franchise_id=${id}&order_id=${order}&subscription_id=${subId}`
  );
}

//chargeBeeInvoiceList
export function findChargeBeeInvoiceList(order) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + `chargebee/get-invoices?order_id=${order}`
  );
}

//put on hold
export function sendPutHold(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + PUT_ON_HOLD,
    data
  );
}

//findOrderDropDown
export function findOrderDropDown() {
  return axios.get(process.env.REACT_APP_SITE_URL + ORDER_URL + ORDER_DROPDOWN);
}

//refund
export function refund(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ORDER_URL + REFUND, data);
}
export function refundCredit(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + REFUND_CREDIT,
    data
  );
}
//creditNotes
export function creditNotes(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + "order-details" + CREDIT_NOTES,
    data
  );
}
//offline payments
export function offlinePaymentApi(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + OFFLINE_PAYMENT, data);
}

//edit next billing date
export function nextBilling(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + NEXT_BILLING,
    data
  );
}

//add an adhoc charge
export function adhocChargeAdd(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + ADHOC_CHARGE,
    data
  );
}
//eupdate storage detail
export function updateStorageDetail(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + STORAGE_DETAIL,
    data
  );
}

//delete order row from table
export function deleteOrder(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + DELETE_ORDER,
    data
  );
}

//payment plan
export function paymentPlan(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + PAYMENT_PLAN,
    data
  );
}

//update order
export function updateOrder(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + UPDATE_ORDER,
    data
  );
}

//findOrderDropDown
export function findOrderSummaryList(oID, poId) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_URL +
      ORDER_SUMMARY_LIST +
      `order_id=${oID}&pod_count=${poId}`
  );
}

//google maps api
export function findMaps(customerPod, depotPos) {
  return axios.get(
    `https://maps.googleapis.com/maps/api/directions/json?origin=${customerPod}&destination=${depotPos}&key=AIzaSyAzo9Xzk5QwuAixqF8Kxdxp1zgMfL2DtKA`
  );
}

//orderInvoice
export function findOrderInvoice(id, controller) {
  const api = axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_URL +
      ORDER_INVOICE +
      `order_id=${id}`,
    { signal: controller?.signal }
  );
  // if (isAbort === "abort") {
  //   controller.abort();
  // }

  return api;
}

//driver loader list api
export function findDriverOrder(id, role) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_URL +
      DRIVER_LOADER +
      `franchise_id=${id}`
  );
}

//add collection or return driver or loader api
export function addDriverLoader(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + ADD_DRIVER_LOADER,
    data
  );
}
//add collection or return driver or loader api
export function savedDriverLoader(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_URL +
      SAVED_DRIVER_LOADER +
      `order_id=${id}`
  );
}
//resume Subscription
export function resumeSubscription(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + RESUME_SUBSCRIPTION,
    data
  );
}

//Cancel Subscription
export function cancelSubscription(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + CANCEL_SUBSCRIPTION,
    data
  );
}

//extra miles api
//Cancel Subscription
export function extraMiles(data) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ORDER_DETAILS +
      EXTRA_MILES +
      `?franchise_id=${data}`
  );
}

//access update
export function accessUpdate(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ACCESS_UPDATE, data);
}

//collection retrun access return
export function cancelReturnAccess(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + CANCEL_BOOKING, data);
}
//partial retrun cancel
export function cancelReturn(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + CANCEL_RETURN, data);
}

export function cancelParReturn(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + CANCEL_PAR_RETURN, data);
}

//complete api call for access
export function completeAccess(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + COMPLETE_BOOKING, data);
}
export function completePar(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + PAR_BOOKING, data);
}

//update depot
export function updateDepot(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + UPDATE_DEPOT, data);
}

//get version
export function findVersion(fId, oId, vId) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      VERSION +
      `?franchise_id=${fId}&order_id=${oId}&version=${vId}`
  );
}
export function findPostcodeFranchise(postcode) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      POSTCODE_FRANCHISE +
      `?postcode=${postcode}`
  );
}

//collection extra charge
export function collectionExtraChage(queryParams) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      COLLECTION_EXTRA_CHARGE +
      `?order_id=${queryParams.id}&pod=${queryParams.pod}${
        queryParams?.type ? "&type=" + queryParams?.type : ""
      }${
        queryParams?.is_student_return
          ? "&is_student_return=" + queryParams?.is_student_return
          : ""
      }${
        queryParams?.return_date
          ? "&return_date=" + queryParams?.return_date
          : ""
      }${
        queryParams?.return_postcode
          ? "&return_postcode=" + queryParams?.return_postcode
          : ""
      }`
  );
}

//collection extra charge
export function returnExtraChage(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + RETURN_EXTRA_CHARGE,
    queryParams
  );
}

export function containerExtraChage(queryParams) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      EXTRA_CHARGE +
      `order_id=${queryParams.id}&container_size=${queryParams.container_size}${
        queryParams?.type ? "&type=" + queryParams?.type : ""
      }${
        queryParams?.return_date
          ? "&return_date=" + queryParams?.return_date
          : ""
      }${
        queryParams?.container_size
          ? "&container_size=" + queryParams?.container_size
          : ""
      }`
  );
}
//late fees
export function lateFees(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + LATE_FEE_STATUS, data);
}

//late fees
export function accessMonitor(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ACCESS_MONITOR, data);
}
export function accessMonitorPar(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ACCESS_MONITOR_PAR, data);
}

//billing cycle coupons
//collection extra charge
export function billingCycleCoupons(queryParams) {
  console.log("queryParams", queryParams);
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      BILLING_COUPONS +
      `franchise_id=${queryParams.id}&billing_cycle=${
        queryParams.cycle
      }&is_container=${queryParams?.is_container || 0}&period=${
        queryParams?.period
      }&is_same_time_return=${queryParams.is_same_time_return}`
  );
}

//packagin material list api on new order
export function packagingList(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + PACKAGING_LIST + `franchise_id=${id}`
  );
}

//packagin material list api on new order
export function BookingAccessCharage(orderId, fid) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      BOOKINGACCESSCHARGES +
      `order_id=${orderId}&franchise_id=${fid}`
  );
}

export function collectionComplete(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + COLLECTION_COMPLETE, data);
}
export function updateFranchise(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + UPDATE_CONTACT_FRANCHISE,
    data
  );
}

export function deleteInsurance(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + DELETE_INSURANCE,
    queryParams
  );
}

export function paymentFaildError(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + PAYMENT_FAILD,
    queryParams
  );
}

export function sendQuoteCheckOut(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + CHECKOUT_SENDQUOTE,
    queryParams
  );
}

export function offlinePayment(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + CASH_OFFLINE_PAYMENT,
    data
  );
}

export function getStats() {
  return axios.get(process.env.REACT_APP_SITE_URL + GET_STATS);
}
export function getConditionStats(id, Iid) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + GET_CONDITION_STATS + id + `/${Iid}`
  );
}

export function getEstimationPrice(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ESTIMATION, data);
}

export function getMavisPackagingMaterial(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + MAVIS_PACKAGING_MATERIAL,
    data
  );
}

//order detail auto-collect
export function autoCollect(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + AUTO_COLLECT, data);
}

export function pausePay(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + PAUSE_PAY, data);
}

//add charge edit order
export function addProduct(data) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      ADD_PRODUCT +
      `franchise_id=${data.franchise_id}&type=${data.type}&search_product=${data?.search}`
  );
}

//add charge edit order
export function getProduct(data) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      GET_ADDON_CHARGE +
      `franchise_id=${data.franchise_id}${
        data?.item_id ? `&item_id=${data?.item_id}` : ""
      }${data?.period ? `&period=${data?.period}` : ""}${
        data?.period_unit ? `&period_unit=${data?.period_unit}` : ""
      }${data?.order_id ? `&order_id=${data?.order_id}` : ""}`
  );
}

export function getBreakDownData(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + BREAK_DOWN, data);
}

export function updateSubscription(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + UPDATE_SUBSCRIPTION, data);
}

export function excemptPrice(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + EXCEMPT_INCREASE,
    queryParams
  );
}
export function sendLabel(queryParams) {
  return axios.post(process.env.REACT_APP_SITE_URL + SEND_LABEL, queryParams);
}

export function deleteContainer(queryParams) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + DELETE_CONTAINER,
    queryParams
  );
}

export function addPerson(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + "self-booking/delighted-data",
    { email: data }
  );
}

export function getNewFlag(data) {
  return axios.get(process.env.REACT_APP_SITE_URL + GET_NEW_FLAG + data);
}

export function getCollectionExtraCharge(data) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      COLLECTION_CHARGE +
      `order_id=${data?.order_id}&type=${data?.type}${
        data?.collection_date ? `&collection_date=${data?.collection_date}` : ""
      }${data?.return_date ? `&return_date=${data?.return_date}` : ""}`
  );
}

export function editOrderSummary(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL +
      ORDER_DETAILS +
      "subscription-upcoming-invoice",
    data
  );
}

export function sendSmsEmailPayUrl(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_URL + "/send-sms-email-pay-url",
    data
  );
}

export function placeOrderThroughUrl(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + CREATE_BAC_ORDER, data);
}

export function sendPayUrlForInvoice(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_DETAILS + "send-pay-url-for-invoice",
    data
  );
}

export function collectionPaymentRetryCharge(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_DETAILS + "retry-charge",
    data
  );
}

export function getTransferDetails(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL + `stripe/customer-payments/details/${id}`
  );
}

export function addChargeProductsInEditOrder(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_DETAILS + "add-product-charge",
    data
  );
}

export function addPlanProductsInEditOrder(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_DETAILS + "add-product-edit-sub",
    data
  );
}

export function sendAddCardLink(id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + ORDER_DETAILS + "send-link-add-card",
    id
  );
}
