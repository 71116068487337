/* eslint-disable no-unused-expressions */
import { FormControl, InputGroup } from "react-bootstrap";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MenuItem, Select } from "@material-ui/core";
import SVG from "react-inlinesvg";
import { Table } from "react-bootstrap";
import { debounce } from "lodash";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  calculateDefaultDiscount,
  isItemExists,
  transformDataToAddProduct,
} from "../NewOrderStripeUtils";

export function OrderSummaryTable({ state, dispatchReducer }) {
  const [callQuatity, setCallQuantity] = useState({
    call: false,
    item: {},
    quantity: 1,
  });
  const [callAfterCollection, setCallAfterCollection] = useState(false);
  const { cardDetail, orderSummaryListStripe } = useSelector(
    (state) => ({
      cardDetail: state.order.cardDetail,
      orderSummaryListStripe: state.order.orderSummaryListStripe,
    }),
    shallowEqual
  );
  const handleQty = (item, quantity) => {
    const updatedArray = [...state?.product_array];
    const index = updatedArray.findIndex((e) => e.name === item?.name);
    if (item?.type == "plan") {
      const qtyUpdate = updatedArray.find(
        (e) =>
          e.price_id === process.env.REACT_APP_PRODUCT_COLLECTION_SERVICE ||
          e.price_id === process.env.REACT_APP_PRODUCT_COL_PUB_HOLIDAY ||
          e.price_id === process.env.REACT_APP_PRODUCT_WEEKEND_CHARGE ||
          e.price_id === process.env.REACT_APP_PRODUCT_RET_SER ||
          e.price_id === process.env.REACT_APP_PRODUCT_RET_PUB_HOLIDAY ||
          e.price_id === process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE ||
          e.price_id === "prod_6Oqikpw2ME5v"
      );
      const qtyUpdateFil = updatedArray.filter(
        (e) =>
          e.price_id === process.env.REACT_APP_PRODUCT_COLLECTION_SERVICE ||
          e.price_id === process.env.REACT_APP_PRODUCT_COL_PUB_HOLIDAY ||
          e.price_id === process.env.REACT_APP_PRODUCT_WEEKEND_CHARGE ||
          e.price_id === process.env.REACT_APP_PRODUCT_RET_SER ||
          e.price_id === process.env.REACT_APP_PRODUCT_RET_PUB_HOLIDAY ||
          e.price_id === process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE ||
          e.price_id === "prod_6Oqikpw2ME5v"
      );

      if (qtyUpdate?.price_id) {
        setCallQuantity({
          call: true,
          item: qtyUpdate,
          quantity: quantity,
          qtyUpdateFil: qtyUpdateFil,
        });
      }
      // handleCallAgain(qtyUpdate, quantity)
    } else {
      setCallAfterCollection(true);
      setCallQuantity({ call: false, item: {}, quantity: 1 });
    }
    if (item?.type === "plan") {
      const coverValue = (2 * +quantity + 2) * 1000;
      const value = +coverValue / 1000;
      const addonData = updatedArray.find(
        (e) =>
          e.price_id === process.env.REACT_APP_PRODUCT_POD_PROTECT ||
          e.price_id === process.env.REACT_APP_PRODUCT_STORE_PROTECT ||
          e.price_id === "Storeprotect"
      );
      if (addonData?.name) {
        const addon = updatedArray.findIndex(
          (e) =>
            e.price_id === process.env.REACT_APP_PRODUCT_POD_PROTECT ||
            e.price_id === process.env.REACT_APP_PRODUCT_STORE_PROTECT ||
            e.price_id === "Storeprotect"
        );
        updatedArray[addon].cover = +coverValue;
        updatedArray[addon].total =
          addonData?.cost * value * state?.billingParam?.unit;
      }
    }
    if (item?.product_prices && item?.product_prices?.length > 5) {
      let cost, total;

      if (quantity > 6) {
        // Calculate additional tiers beyond 6
        const additionalTiers = quantity - 6;

        cost =
          Number(item.product_prices[5].unit_amount) +
          Number(item.product_prices[6].unit_amount) * Number(additionalTiers);
      } else {
        // Get the matching price based on the exact quantity
        const matchingPrice = item.product_prices.find(
          (e) => e.tier_start_unit == quantity
        );
        if (matchingPrice) {
          cost = matchingPrice.unit_amount;
        } else {
          console.error("No matching price found for the given quantity.");
          return;
        }
      }
      if (item.type === "plan" || item.type === "addon") {
        total = cost * state?.billingParam?.unit;
      } else {
        total = cost;
      }
      if (index !== -1) {
        updatedArray[index] = {
          ...updatedArray[index],
          cost: cost,
          total: total,
          quantity: quantity,
        };
        dispatchReducer({
          type: "SET_DATA",
          data: {
            ...state,
            product_array: updatedArray,
            is_billing_call: true,
            set: 4,
          },
        });
      }
    } else {
      console.log("updatedArray[index]", updatedArray[index]);

      updatedArray[index] = {
        ...updatedArray[index],
        cost: updatedArray[index].cost,
        total: updatedArray[index].cost * quantity,
        quantity: quantity,
      };
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          product_array: updatedArray,
          is_billing_call: true,
          set: 5,
        },
      });
    }
  };

  useEffect(() => {
    if (callQuatity.call) {
      if (callQuatity?.qtyUpdateFil?.length > 1) {
        const updatedArray = [...state?.product_array]; // Initialize once outside the loop

        console.log("callQuatity", callQuatity);
        callQuatity?.qtyUpdateFil.forEach((e) => {
          const index = updatedArray.findIndex((es) => es.name === e?.name);

          if (e?.product_prices && e?.product_prices?.length > 5) {
            let cost, total;

            if (callQuatity.quantity > 6) {
              // Calculate additional tiers beyond 6
              const additionalTiers = callQuatity.quantity - 6;

              if (e.product_prices[6]) {
                cost =
                  Number(e.product_prices[5].unit_amount) +
                  Number(e.product_prices[6].unit_amount) *
                    Number(additionalTiers);
              } else {
                console.error("No price found for quantity above 6.");
                return;
              }
            } else {
              // Get the matching price based on the exact quantity
              const matchingPrice = e.product_prices.find(
                (price) => price.tier_start_unit == callQuatity.quantity
              );
              console.log("matchingPrice", matchingPrice);
              if (matchingPrice) {
                cost = matchingPrice.unit_amount;
              } else {
                console.error(
                  "No matching price found for the given quantity."
                );
                return;
              }
            }

            // Calculate total based on the type
            if (e.type === "plan" || e.type === "addon") {
              total = cost * state?.billingParam?.unit;
            } else {
              total = cost;
            }

            // Update the array if the index is found
            if (index !== -1) {
              updatedArray[index] = {
                ...updatedArray[index],
                cost: cost,
                total: total,
                quantity: callQuatity.quantity,
              };
              console.log("updatedArray", updatedArray, cost, total, index);
            }
          }
        });

        // After all updates, dispatch the final updated array
        dispatchReducer({
          type: "SET_DATA",
          data: {
            ...state,
            product_array: updatedArray,
            is_billing_call: true,
            set: 4,
          },
        });
        setCallAfterCollection(true);
        setCallQuantity({ call: false, item: {}, quantity: 1 });

        // console.log("arrayProduct", arrayProduct);
        // eslint-disable-next-line no-unused-expressions
        // handleQty(callQuatity?.qtyUpdateFil, callQuatity.quantity)

        // callQuatity?.qtyUpdateFil.forEach((e) => handleQty(e[0], callQuatity.quantity));
      } else {
        handleQty(callQuatity.item, callQuatity.quantity);
      }
    }
  }, [callQuatity.call]);

  function updateCongestionCharge(data) {
    // Create a deep copy of the data to avoid mutating the original state
    const updatedData = data.map((item) => ({ ...item }));

    // Find the plan and the congestion charges from the copied data
    const plan = updatedData.find((item) => item.type === "plan");
    const congestionCharge1 = updatedData.find(
      (item) => item.price_id === process.env.REACT_APP_PRODUCT_COL_CON_CHARGE
    );
    const congestionCharge2 = updatedData.find(
      (item) => item.price_id === process.env.REACT_APP_PRODUCT_EXTRAMILES
    );

    // Check if plan exists
    if (!plan) {
      return updatedData; // Return the copied data as is if the plan is not found
    }

    // Calculate the multiplier based on the plan's quantity
    let multiplier = 1;
    if (plan.quantity > 18) {
      multiplier = 7; // Max out the multiplier at 7 for quantities greater than 18
    } else if (plan.quantity > 15) {
      multiplier = 6;
    } else if (plan.quantity > 12) {
      multiplier = 5;
    } else if (plan.quantity > 9) {
      multiplier = 4;
    } else if (plan.quantity > 6) {
      multiplier = 3;
    } else if (plan.quantity > 3) {
      multiplier = 2;
    }

    // Update the total for congestionCharge1 if it exists
    if (congestionCharge1) {
      congestionCharge1.total = parseFloat(congestionCharge1.cost) * multiplier;
      congestionCharge1.quantity = multiplier;
    }

    // Update the total for congestionCharge2 if it exists
    if (congestionCharge2) {
      congestionCharge2.total =
        parseFloat(congestionCharge2.cost) *
        multiplier *
        parseFloat(congestionCharge2.quantity);
    }
    return updatedData;
  }

  // Example usage:
  useEffect(() => {
    if (callAfterCollection) {
      const arr = state.product_array;
      const updatedData = updateCongestionCharge(arr);
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          product_array: updatedData,
          // is_billing_call: true,
          set: 100,
        },
      });
      setCallAfterCollection(false);
    }
  }, [callAfterCollection]);

  const variantProductTotal = useMemo(() => {
    if (state?.product_array?.length < 1) return;
    let data = {
      subTotal: 0,
      total: 0,
      defaultDiscount: 0,
      couponDiscount: 0,
      discountedValue: 0,
      podDisValue: 0,
    };
    // eslint-disable-next-line no-unused-expressions
    state?.product_array.forEach((item) => {
      if (item.type === "plan") {
        data.podDisValue = item.total;
      }
    });
    const subTotal = state?.product_array?.reduce((acc, item) => {
      return acc + parseFloat(item.total);
    }, 0);
    if (state?.default_coupon) {
      const defaultDis = calculateDefaultDiscount(
        state?.product_array,
        state?.default_coupon,
        data
      );
      console.log("default_coupon", defaultDis);
      data.defaultDiscount = defaultDis;
      data.podDisValue = data.podDisValue - defaultDis;
    }
    data.discountedValue = subTotal;
    if (state.promoCoupon.length > 0) {
      const billingPodDiscount = state.promoCoupon.filter(
        (e) => e.check === "billing"
      );
      const notBillingPodDiscount = state.promoCoupon.filter(
        (e) => e.check != "billing"
      );
      console.log(
        "billingPodDiscount",
        data,
        notBillingPodDiscount,
        data["discountedValue"],
        billingPodDiscount,
        state.promoCoupon,
        data["podDisValue"]
      );

      if (billingPodDiscount.length > 0) {
        billingPodDiscount.map((offer) => {
          if (offer?.type === "percent") {
            data["couponDiscount"] =
              data["couponDiscount"] +
              (data["podDisValue"] * offer.value) / 100;
            data["podDisValue"] =
              data["podDisValue"] - (data["podDisValue"] * offer.value) / 100;
          } else if (
            offer?.type === "fixed" ||
            offer?.type === "fixed_amount"
          ) {
            data["podDisValue"] =
              Number(data["podDisValue"]) - Number(offer.value);
            data["couponDiscount"] =
              data["couponDiscount"] + Number(offer.value);
          }
          return offer;
        });
      }
      if (notBillingPodDiscount.length > 0) {
        notBillingPodDiscount.map((offer) => {
          if (offer?.type === "percent") {
            console.log(
              "offer",
              data["couponDiscount"],
              data["discountedValue"]
            );
            data["couponDiscount"] =
              data["couponDiscount"] +
              ((data["discountedValue"] - data["defaultDiscount"]) *
                offer.value) /
                100;
            data["discountedValue"] =
              data["discountedValue"] -
              (data["discountedValue"] * offer.value) / 100;
          } else if (
            offer?.type === "fixed" ||
            offer?.type === "fixed_amount"
          ) {
            data["discountedValue"] =
              Number(data["discountedValue"]) - Number(offer.value);
            data["couponDiscount"] =
              data["couponDiscount"] + Number(offer.value);
          }
          return offer;
        });
      }
    }

    data.subTotal = subTotal;
    data.total =
      subTotal - Number(data.defaultDiscount) - Number(data.couponDiscount);
    console.log("data-90", data, subTotal);
    dispatchReducer({
      type: "SET_DATA",
      data: { ...state, variantProductTotal: data },
    });
    return data;
  }, [
    state?.product_array,
    state?.product_array?.length,
    state?.default_coupon?.length,
    state?.promoCoupon?.length,
  ]);

  const handleAddonSelectCover = (coverValue, pId) => {
    const value = coverValue / 1000;
    const arr = [...state?.product_array];
    console.log("arr-90", arr, pId);

    const addon = arr.findIndex((e) => e.price_id === pId);
    const addonData = arr.find((e) => e.price_id === pId);
    arr[addon].cover = coverValue;
    arr[addon].total = addonData?.cost * value * state?.billingParam?.unit;
    dispatchReducer({
      type: "SET_DATA",
      data: { ...state, product_array: arr, set: 8 },
    });
  };

  const items = Array.from({ length: 51 }, (_, index) => index * 1000);

  const get25Plan = useMemo(() => {
    const plan = state.product_array?.find((e) => e.type === "plan");

    const addon = state.product_array?.find((e) => e.type === "addon");
    const addonPrice = addon?.total ? Number(addon?.total) : 0;
    const price = plan?.total ? Number(plan?.total) : 0;
    console.log("discount-1234", state?.variantProductTotal);

    const discountPrice25 = price * 0.25;
    const recurring =
      price + addonPrice - state?.variantProductTotal?.defaultDiscount;
    const invoice = price - discountPrice25 + addonPrice;

    const digit2 = +invoice.toFixed(2);
    return { digit2, recurring };
  }, [
    state.product_array,
    state.product_array?.length,
    state?.variantProductTotal?.defaultDiscount,
    state?.billingParam?.unit,
    state?.variantProductTotal?.subTotal,
  ]);
  console.log("discount-123", state);

  const get25PlanContainer = useMemo(() => {
    // Find the plan item in the product array
    const plan = state.product_array?.find((e) => e.type === "plan");

    // Filter all addon items from the product array
    const addons = state.product_array?.filter((e) => e.type === "addon") || [];

    // Calculate the total price for addons (sum all their total values)
    const addonPrice = addons.reduce(
      (sum, addon) => sum + (addon.total ? Number(addon.total) : 0),
      0
    );

    // Get the price of the plan if it exists, otherwise default to 0
    const price = plan?.total ? Number(plan?.total) : 0;

    // Calculate 25% discount on the plan price
    const discountPrice25 = price * 0.25;

    // Calculate recurring total (plan price + total addons)
    const recurring = price + addonPrice;

    // Calculate invoice total (plan price with 25% discount + total addons)
    const invoice = price - discountPrice25 + addonPrice;

    // Round the invoice to two decimal places
    const digit2 = +invoice.toFixed(2);

    return { digit2, recurring };
  }, [state.product_array, state.product_array?.length]);
  return (
    <>
      <Table responsive className="summerytable">
        <thead>
          <th className="productsta" colSpan={2}>
            Product
          </th>
          <th className="cost">Cost</th>
          <th>Qty</th>
          <th className={`w-25`}>Total</th>
        </thead>
        <tbody>
          {state.product_array && state.product_array.length ? (
            state.product_array.map((data, index) => {
              return (
                <>
                  <tr key={index}>
                    <td colSpan={2}>
                      {data?.name}
                      {data?.price_id ===
                        process.env.REACT_APP_PRODUCT_ADHOC_CHARGE && (
                        <>
                          <br />
                          <span>{state?.adhocChargeName}</span>
                        </>
                      )}
                    </td>
                    <td
                      className=" d-flex justify-content-between"
                      style={{ width: "80px" }}
                    >
                      <>
                        <span style={{ float: "right" }}>
                          &pound;
                          {`
                       ${Number(data?.cost)
                         .toFixed(2)
                         .toLocaleString("en-US", {
                           maximumFractionDigits: 2,
                         })}  `}
                        </span>
                      </>
                    </td>
                    <td style={{ verticalAlign: "baseline" }}>
                      {data.type === "addon" &&
                      (data.price_id ===
                        process.env.REACT_APP_PRODUCT_POD_PROTECT ||
                        data.price_id ===
                          process.env.REACT_APP_PRODUCT_STORE_PROTECT ||
                        data.price_id === "Storeprotect") ? (
                        <Select
                          name="address"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          className={`form-control marginCustom11  width116 `}
                          IconComponent={(props) => (
                            <i {...props}>
                              <KeyboardArrowDownIcon />
                            </i>
                          )}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          value={data.cover}
                          onChange={(e) => {
                            handleAddonSelectCover(
                              e.target.value,
                              data.price_id
                            );
                          }}
                        >
                          {items.map((item) => {
                            if (item === 0) return;
                            return (
                              <MenuItem key={item} value={item}>
                                &pound;
                                {item.toLocaleString("en-IN")}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      ) : (
                        <InputGroup className="qty-group">
                          <FormControl
                            type="number"
                            placeholder="Qty"
                            aria-label="Qty"
                            aria-describedby="basic-addon1"
                            // max={"10"}
                            maxLength="12"
                            step="1"
                            value={data?.quantity || ""}
                            onChange={(e) => {
                              console.log("werwer", e.target.value);
                              handleQty(data, e.target.value);
                            }}
                            disabled={
                              data.price_id ===
                                process.env
                                  .REACT_APP_PRODUCT_COLLECTION_SERVICE ||
                              data.price_id ===
                                process.env.REACT_APP_PRODUCT_COL_PUB_HOLIDAY ||
                              data.price_id ===
                                process.env.REACT_APP_PRODUCT_WEEKEND_CHARGE ||
                              data.price_id ===
                                process.env.REACT_APP_PRODUCT_COL_CON_CHARGE ||
                              data.price_id ===
                                process.env.REACT_APP_PRODUCT_RET_SER ||
                              data.price_id ===
                                process.env.REACT_APP_PRODUCT_RET_PUB_HOLIDAY ||
                              data.price_id ===
                                process.env.REACT_APP_PRODUCT_RET_WEEKEND_CHARGE
                            }
                            style={{ paddingRight: "10px" }}
                          />
                        </InputGroup>
                      )}
                    </td>
                    <td className="d-flex justify-content-between">
                      &pound;
                      <span style={{ float: "right" }}>
                        {Number(data?.total).toFixed(2)}
                      </span>
                    </td>
                    <td className="cart-icon">
                      {/* {typeCheck === "sendQuote" ||
                                            // data.pricing_model === "stairstep" ||
                                            // data?.pricing_model === "tiered" ||
                                            data?.type === "plan" ||
                                            data?.id === "Toll-Charges-with-VAT" || data?.id == "ShippingFee" ? (
                                            ""
                                        ) : ( */}
                      {/* {data?.type !== "plan" && ( */}
                      <span className="svg-icon svg-icon-sm svg-icon-primary ">
                        <SVG
                          title="Delete"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                          onClick={() => {
                            const filter = state.product_array.filter(
                              (e) => e.name != data?.name
                            );
                            dispatchReducer({
                              type: "SET_DATA",
                              data: {
                                ...state,
                                product_array: filter,
                                set: 6,
                              },
                            });
                          }}
                        />
                      </span>
                      {/* // )} */}
                      {/* )} */}
                    </td>
                  </tr>
                </>
              );
            })
          ) : (
            <>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <div>
                    {/* {loading ? (
                      <span className="ml-2 mr-2 spinner spinner"></span>
                    ) : (
                      <p>No&nbsp;Record&nbsp;Found</p>
                    )} */}
                  </div>
                </td>
              </tr>
            </>
          )}
          {state?.product_array && state?.product_array?.length > 0 ? (
            <>
              <tr>
                <td colSpan="2">
                  {" "}
                  <div>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="enabled_for_checkout"
                        checked={state?.is_packaging_check}
                        onChange={(e) => {
                          const arr = [...state?.product_array];
                          const shipProduct = orderSummaryListStripe.allProducts.find(
                            (e) =>
                              e.stripe_product_id ===
                              process.env.REACT_APP_PRODUCT_SHIPPING_FEE
                          );
                          const dataShipProduct = transformDataToAddProduct(
                            shipProduct
                          );
                          const addUniqueProducts = isItemExists(
                            state.product_array,
                            dataShipProduct
                          );
                          if (!e.target.checked) {
                            const index = arr.findIndex(
                              (e) =>
                                e.price_id ===
                                process.env.REACT_APP_PRODUCT_SHIPPING_FEE
                            );
                            if (index != -1) {
                              arr.splice(index, 1);
                              dispatchReducer({
                                type: "SET_DATA",
                                data: {
                                  ...state,
                                  product_array: arr,
                                  is_packaging_check: e.target.checked,
                                },
                              });
                              return;
                            } else {
                              dispatchReducer({
                                type: "SET_DATA",
                                data: {
                                  ...state,
                                  is_packaging_check: e.target.checked,
                                },
                              });
                              return;
                            }
                          }
                          if (!addUniqueProducts) {
                            arr.push(dataShipProduct);
                            dispatchReducer({
                              type: "SET_DATA",
                              data: {
                                ...state,
                                product_array: arr,
                                is_packaging_check: e.target.checked,
                              },
                            });
                          } else {
                            dispatchReducer({
                              type: "SET_DATA",
                              data: {
                                ...state,
                                is_packaging_check: e.target.checked,
                              },
                            });
                          }
                        }}
                      />
                      <span className="mr-2"></span>
                      Ship&nbsp;packaging&nbsp;materials
                    </label>
                  </div>
                </td>
                {state?.adhocChargeName && state?.adhocGbp ? (
                  <td
                    className="bord-top"
                    colSpan={state?.adhocChargeName ? 2 : 0}
                  >
                    {/* Adhoc Charge - {state?.adhocChargeName} */}
                  </td>
                ) : (
                  <td className="bord-top"></td>
                )}
                {/* {state?.adhocChargeName && state?.adhocGbp ? (
                  ""
                ) : (
                  <td className="bord-top"></td>
                )} */}
                {/* <td className="bord-top"></td> */}
                <td className="bord-top text-right">
                  {" "}
                  <strong>Subtotal</strong>
                </td>
                <td className="bord-top d-flex justify-content-between">
                  <>
                    &pound;
                    <span style={{ float: "right" }}>
                      {" "}
                      {variantProductTotal?.subTotal
                        ? Number(variantProductTotal?.subTotal).toFixed(2)
                        : "0.00"}
                    </span>
                  </>
                </td>
              </tr>
              <tr>
                <td className="bord-top"></td>
                <td className="bord-top"></td>
                {/* <td className="bord-top"></td>  */}
                <td className="bord-top text-right" colSpan={2}>
                  {" "}
                  <strong>Discount on Storage</strong>
                </td>
                <td className="bord-top d-flex justify-content-between">
                  &pound;
                  <span style={{ float: "right" }}>
                    {" "}
                    {variantProductTotal?.defaultDiscount
                      ? Number(variantProductTotal?.defaultDiscount).toFixed(2)
                      : "0.00"}
                  </span>
                </td>
              </tr>
              {variantProductTotal?.couponDiscount ? (
                <>
                  <tr>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    {/* <td className="bord-top"></td>  */}
                    <td className="bord-top text-right" colSpan={2}>
                      {" "}
                      <strong>Additional Discount</strong>
                    </td>
                    <td className="bord-top d-flex justify-content-between">
                      &pound;
                      <span style={{ float: "right" }}>
                        {" "}
                        {variantProductTotal?.couponDiscount
                          ? Number(variantProductTotal?.couponDiscount).toFixed(
                              2
                            )
                          : "0.00"}
                      </span>
                    </td>
                    <td className="cart-icon">
                      <span className="svg-icon svg-icon-sm svg-icon-primary ">
                        <SVG
                          title="Delete"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                          onClick={() => {
                            dispatchReducer({
                              type: "SET_DATA",
                              data: {
                                ...state,
                                promoCoupon: [],
                              },
                            });
                          }}
                        />
                      </span>
                      {/* )} */}
                    </td>
                  </tr>
                  <tr>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    {/* <td className="bord-top"></td>  */}
                    <td className="bord-top text-right" colSpan={2}>
                      {" "}
                      <strong>Total Discount</strong>
                    </td>
                    <td className="bord-top d-flex justify-content-between">
                      &pound;
                      <span style={{ float: "right" }}>
                        {" "}
                        {variantProductTotal?.couponDiscount
                          ? Number(
                              Number(variantProductTotal?.couponDiscount) +
                                Number(
                                  variantProductTotal?.defaultDiscount || 0
                                )
                            ).toFixed(2)
                          : "0.00"}
                      </span>
                    </td>
                    <td className="cart-icon">
                      {/* <span className="svg-icon svg-icon-sm svg-icon-primary ">
                      <SVG
                        title="Delete"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                        onClick={() => {
                          dispatchReducer({
                            type: "SET_DATA",
                            data: {
                              ...state,
                              promoCoupon: [],
                            },
                          });
                        }}
                      />
                    </span> */}
                      {/* )} */}
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
              <tr>
                {state?.adhocChargeName && state?.adhocGbp ? (
                  <td
                    className="bord-top"
                    colSpan={state?.adhocChargeName ? 2 : 0}
                  >
                    {/* Adhoc Charge - {state?.adhocChargeName} */}
                  </td>
                ) : (
                  <td className="bord-top"></td>
                )}
                {state?.adhocChargeName && state?.adhocGbp ? (
                  ""
                ) : (
                  <td className="bord-top"></td>
                )}
                <td className="bord-top"></td>
                <td className="bord-top text-right">
                  {" "}
                  <strong> Total</strong>
                </td>
                <td className="bord-top d-flex justify-content-between">
                  <>
                    &pound;
                    <span style={{ float: "right" }}>
                      {" "}
                      {variantProductTotal?.total
                        ? Number(variantProductTotal?.total).toFixed(2)
                        : "0.00"}
                    </span>
                  </>
                </td>
              </tr>
              <tr>
                {/* <td className="bord-top"></td> */}
                <td className="bord-top text-left" colSpan={2}>
                  {" "}
                  <strong>Recurring Amount </strong> &pound;
                  <span>
                    {" "}
                    {state.containerTypeSelect === "container"
                      ? get25PlanContainer?.recurring
                        ? Number(get25PlanContainer?.recurring).toFixed(2)
                        : "0.00"
                      : get25Plan?.recurring
                      ? Number(get25Plan?.recurring).toFixed(2)
                      : "0.00"}
                  </span>
                </td>
                <td className="bord-top d-flex justify-content-between">
                  <></>
                </td>
                {state?.adhocChargeName && state?.adhocGbp ? (
                  ""
                ) : (
                  <td className="bord-top"></td>
                )}
                {state?.adhocChargeName && state?.adhocGbp ? (
                  <td
                    className="bord-top"
                    colSpan={state?.adhocChargeName ? 2 : 0}
                  >
                    {/* Adhoc Charge - {state?.adhocChargeName} */}
                  </td>
                ) : (
                  <td className="bord-top"></td>
                )}
              </tr>
              {!state.collectionRetrun?.return_start_time_slot &&
              state.collectionRetrun?.collection_date &&
              (state?.containerTypeSelect != "pod" ||
                (state?.containerTypeSelect == "pod" &&
                  state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID &&
                  state?.product_array?.some(
                    (e) =>
                      e.type === "plan" &&
                      e?.price_id !== process.env?.REACT_APP_PRODUCT_ECONOMY
                  ))) &&
              // Convert string date to Date object
              new Date(state.collectionRetrun?.collection_date) >
                new Date(Date.now() + 48 * 60 * 60 * 1000) ? (
                <>
                  <tr>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    <td className="bord-top text-right">
                      {" "}
                      <strong>Due today</strong>
                    </td>
                    <td
                      className="bord-top d-flex justify-content-between"
                      colSpan="2"
                    >
                      &pound;
                      <span style={{ float: "right" }}>
                        {state.collectionRetrun?.collection_date &&
                        variantProductTotal?.total
                          ? Number(+variantProductTotal?.total * 0.1).toFixed(2)
                          : "0.00"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    {/* <td className="bord-top"></td> */}
                    <td className="bord-top text-right" colSpan="2">
                      {" "}
                      <strong>
                        Due on{" "}
                        {moment(
                          new Date(
                            new Date(
                              state.collectionRetrun?.collection_date
                            ).getTime() -
                              48 * 60 * 60 * 1000
                          )
                        ).format("DD-MM-yyyy")}
                      </strong>
                    </td>
                    <td
                      className="bord-top d-flex justify-content-between"
                      colSpan="2"
                    >
                      &pound;
                      <span style={{ float: "right" }}>
                        {state.collectionRetrun?.collection_date &&
                        variantProductTotal?.total
                          ? Number(+variantProductTotal?.total * 0.9).toFixed(2)
                          : "0.00"}{" "}
                      </span>
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
              {cardDetail &&
                cardDetail?.length > 0 &&
                cardDetail[0]?.contact?.franchise?.discount_after_renewal ==
                  1 &&
                state.containerTypeSelect !== "container" && (
                  <tr>
                    <td colSpan="2" className="bord-top">
                      <div className="d-flex">
                        {cardDetail &&
                        cardDetail?.length > 0 &&
                        cardDetail[0]?.contact?.franchise
                          ?.discount_after_renewal == 1
                          ? `25% discount after ${
                              +state.params?.unit > 3 ? state.params?.unit : "3"
                            } months `
                          : "25% discount not applicable"}
                      </div>
                      {}
                      <div>
                        {console.log("get25Plan", get25Plan)}
                        Price after 3 months: &pound; {get25Plan?.digit2}
                      </div>
                    </td>
                  </tr>
                )}
            </>
          ) : (
            ""
          )}
        </tbody>
      </Table>
    </>
  );
}
