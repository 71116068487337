import { createSlice } from "@reduxjs/toolkit";

const initialCommonState = {
  notification: [],
  dashboardFeeds: [],
  loading: false,
  loadingPod: false,
  loadingContainer: false,
};

export const callTypes = {
  list: "list",
  action: "action",
  loading: "loading",
  loadingPod: "loadingPod",
  loadingContainer: "loadingContainer",
};

export const commonSlice = createSlice({
  name: "common",
  initialState: initialCommonState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        state.loading = false;
      } else if (action.payload.callType === callTypes.loadingPod) {
        state.loadingPod = false;
      } else if (action.payload.callType === callTypes.loadingContainer) {
        state.loadingContainer = false;
      } else {
        state.actionsLoading = false;
        state.loading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        state.loading = true;
      } else if (action.payload.callType === callTypes.loadingPod) {
        state.loadingPod = true;
      } else if (action.payload.callType === callTypes.loadingContainer) {
        state.loadingContainer = true;
      } else {
        state.actionsLoading = true;
        state.loading = true;
      }
    },

    notificationFetched: (state, action) => {
      const { data } = action.payload;
      state.notification = data;
    },
    dashboardFeeds: (state, action) => {
      const { data } = action.payload;
      state.dashboardFeeds = data;
    },
    getDashboardDataSlice: (state, action) => {
      const { data } = action.payload;
      state.dashboardDataState = data;
      state.loading = false;
    },
    getDashboardPodDataSlice: (state, action) => {
      const { data } = action.payload;
      state.dashboardPodDataState = data;
      state.loadingPod = false;
    },
    getDashboardContainerDataSlice: (state, action) => {
      const { data } = action.payload;
      state.dashboardContainerDataState = data;
      state.loadingContainer = false;
    },
    getFillRateDataSlice: (state, action) => {
      const { data } = action.payload;
      state.dashboardFillRateState = data;
      state.loadingFill = false;
    },
  },
});
