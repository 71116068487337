import React, { useState } from "react";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import AddProductModal from "../components/EditOrderComponents/Modal/AddProoductModal";
// import { findOfferCode } from "../../_redux/order/orderCrud";
import AddCouponModal from "../components/EditOrderComponents/Modal/AddCouponModal";
import AddManualDiscountModal from "../components/EditOrderComponents/Modal/AddManualDiscountModal";
import AddCouponReleaseModal from "../components/EditOrderComponents/Modal/AddCouponReleaseModal";

export const EditOrderTableReleaseButtons = ({
  orderUIContext,
  state,
  dispatchReducer,
  editOrderDetail,
}) => {
  const [dialog, setDialog] = useState({ check: false, data: {} });
  console.log("editOrderDetail", editOrderDetail);
  return (
    <>
      <tr style={{ backgroundColor: "#eff5f5" }}>
        <td colSpan={5}>
          <div className="mb-2  d-flex">
            {/* <div
              className="mr-4"
              onClick={() =>
                setDialog({
                  check: true,
                  title: "Add Recurring Addon",
                  type: "addon",
                })
              }
            >
              <ControlPointIcon />
              <strong className="ml-2">Add Recurring Addon</strong>
            </div>
            | */}
            {/* <div
              className="mr-4"
              onClick={() =>
                setDialog({ check: true, title: "Add Charge", type: "charge" })
              }
            >
              <ControlPointIcon />
              <strong className="ml-2">Add Product</strong>
            </div> */}

            <div
              className="ml-4"
              onClick={() =>
                setDialog({
                  check: false,
                  coupon: true,
                  franchise: editOrderDetail?.franchise_id,
                })
              }
            >
              <ControlPointIcon />
              <strong className="ml-2">Add Coupon Code</strong>
            </div>
          </div>
        </td>
      </tr>

      {/* {dialog?.manual && (
        <AddManualDiscountModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )} */}
      {dialog?.coupon && (
        <AddCouponReleaseModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
          state={state}
          dispatchReducer={dispatchReducer}
        />
      )}
      {/* {dialog?.check && (
        <AddProductModal
          dialog={dialog}
          setDialog={setDialog}
          state={state}
          dispatchReducer={dispatchReducer}
        />
      )} */}
    </>
  );
};
