/* eslint-disable no-restricted-imports */
import moment from "moment";
import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { CreateContactOffCanvas } from "../../../../app/modules/ContactDeals/canvas/CreateContactOffCanvas";
import { CustomerDetailCard } from "../../../../app/modules/Order/NewOrder/components/CustomerDetailCard";
import { useIntl } from "react-intl";
import { initIds } from "../../controls/RightPanel";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { DamageWaiverCanva } from "../../../../app/modules/Order/order-canva/DamageWaiverCanva";
import { fulfillmentStatus } from "../../../../app/utils/commonFunction";
import { canvaCancel } from "../../../../app/utils/canvaCloseFunction";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { fetchVersion } from "../../../../app/modules/Order/_redux/order/orderAction";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import {
  getNotification,
  patchNoti,
} from "../../../../app/modules/Order/_redux/order/orderCrud";
import NotificationModal from "../../../../app/modules/Order/order-modal/NotificationModal";
import SnackBarTool from "../snack-bar/SnackBar";

function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
      sx={{
        padding: "4px 12px", // Adjust padding to reduce height
        fontSize: "0.875rem", // Optional: Adjust font size for compactness
        lineHeight: "1.3", // Reduce line height
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
export function OrderInfo({ orderDetail, location, setOpenInvoice }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [notiData, setNotiData] = useState([]);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({ open: false });

  const init = ["kt_quick_panelone"];

  useEffect(() => {
    if (orderDetail?.id || dialog?.call) {
      getNotification(orderDetail?.id).then((res) => {
        console.log("resd", res.data.data);
        setNotiData(res.data.data);
      });
    }
  }, [orderDetail?.id, dialog?.call]);
  console.log("dialog", dialog);

  const handleActionNotification = (id, description) => {
    setSnack({ call: false, type: "", message: "" });

    const data = {
      id: id,
      description: description,
    };
    console.log("data-0", data);
    setLoading(true);

    patchNoti(data)
      .then((res) => {
        setLoading(false);

        setSnack({ call: true, type: "success", message: res.data.message });
        setDialog({ open: false, call: true });
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    initIds(init);
  }, [init]);
  const { role } = useSelector(
    (state) => ({
      role: state.auth.user.roles,
    }),
    shallowEqual
  );
  const intl = useIntl();
  const [openCanva, setOpenCanva] = useState(false);
  const [openDamageCanva, setOpenDamageCanva] = useState(false);
  let cus_address = orderDetail?.customer_details?.address || "";
  if (cus_address !== "") {
    cus_address = cus_address?.replaceAll(", ", ",");
  }
  const editValues = {
    first_name: orderDetail?.customer_details?.first_name || "",
    last_name: orderDetail?.customer_details?.last_name || "",
    email: orderDetail?.customer_details?.contact?.email || [],
    phone: orderDetail?.customer_details?.contact?.phone || [],
    postcode: orderDetail?.customer_details?.postcode || "",
    city: orderDetail?.customer_details?.city || "",
    address: cus_address,
    user_id: orderDetail?.customer_details?.contact?.user_id || "",
    is_address_manual:
      orderDetail?.customer_details?.contact?.is_address_manual || 0,
    franchise_id: orderDetail?.franchise_id || "",
    contact_owner_id:
      orderDetail?.customer_details?.contact?.contact_owner_id || "",
    contact_order_id: orderDetail?.id || "",
  };
  const handleSelect = (e) => {
    if (e.target.value === "T&Cs") {
      const newTab = window.open(
        `/terms-and-condition?franchise_id=${orderDetail?.franchise_id ||
          ""}&order_id=${orderDetail?.id}&version=${orderDetail?.version}`,
        "_blank"
      );
      newTab.focus();
      // history.push({
      //   pathname: `/terms-and-condition`,
      //   state: {
      //     franchise_id: orderDetail?.franchise_id || "",
      //     order_id: orderDetail?.id || "",
      //   },
      // });
    } else {
      setOpenDamageCanva(true);
    }
  };
  useEffect(() => {
    if (
      location?.search === "?search" &&
      orderDetail?.customer_details?.first_name
    ) {
      canvaCancel("kt_StorageDetail_panel_toggle");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, orderDetail?.customer_details?.first_name]);

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-body ">
          {/* {notiData &&
            notiData.length > 0 &&
            notiData?.map((item, index) => {
              if (item?.is_marked == 1) return;
              return (
                <div className="mb-4">
                  <Alert
                    severity="warning"
                    icon={<NotificationsActiveIcon className="mt-2" />}
                  >
                    <div className="d-flex">
                      <div className="mt-2">{item?.message} </div>
                      &nbsp;&nbsp;&nbsp;
                      {item?.is_marked != 1 && (
                        <div>
                          <button
                            className="btn btn-primary font-weight-bolder font-size-sm btn-sm"
                            onClick={() =>
                              setDialog({ open: true, id: item.id })
                            }
                          >
                            Action taken?
                          </button>
                        </div>
                      )}
                      &nbsp;&nbsp;&nbsp;
                      {item?.is_refund == 1 && (
                        <div>
                          <button
                            className="btn btn-primary font-weight-bolder font-size-sm btn-sm"
                            onClick={() =>
                              setOpenInvoice({ open: true, check: "invoice" })
                            }
                          >
                            Refund
                          </button>
                        </div>
                      )}
                    </div>
                  </Alert>
                </div>
              );
            })} */}
          <div className="d-flex justify-content-between">
            <div>
              <h5 className="card-title align-items-start flex-column">
                <span className="font-weight-bolder text-dark">
                  {intl.formatMessage({ id: "Order.Number" })} :{" "}
                  {orderDetail?.booking_reference || ""}{" "}
                  <span
                    className="font-weight-bolder text-dark"
                    style={{ paddingTop: "6px" }}
                  >
                    <sup
                      // style={{
                      //   color:
                      //     orderDetail?.is_collection_completed === 1
                      //       ? "green"
                      //       : "red",
                      // }}
                      className="top-4"
                    >
                      <span
                        class={`badge badge-pill ${
                          orderDetail?.order_details?.pod_plan_type ===
                          "First Class"
                            ? "badge-gold"
                            : orderDetail?.order_details?.pod_plan_type ===
                              "Economy"
                            ? "badge-bronze"
                            : orderDetail?.order_details?.pod_plan_type ===
                              "Premium"
                            ? "badge-silver"
                            : ""
                        }`}
                        style={{ fontSize: "13px" }}
                      >
                        <strong>
                          {orderDetail?.order_details?.pod_plan_type !==
                          "Unknown Product"
                            ? orderDetail?.order_details?.pod_plan_type
                            : ""}
                        </strong>
                      </span>
                    </sup>
                  </span>
                </span>
                <span className="d-block text-muted pt-2 font-size-sm font-weight-normal">
                  {intl.formatMessage({ id: "Order.Date" })} :{" "}
                  {moment(orderDetail?.created_at || "").format("DD-MM-yyyy") ||
                    ""}
                </span>
                {role &&
                role?.length &&
                role[0] === "super-admin" &&
                orderDetail?.subscription_scheduled_id ? (
                  <span className="d-block text-muted pt-2 font-size-sm font-weight-normal">
                    Stripe Subscription Id :{" "}
                    {orderDetail?.subscription_id?.includes("sub")
                      ? orderDetail?.subscription_id
                      : orderDetail?.subscription_scheduled_id || ""}
                  </span>
                ) : (
                  ""
                )}
              </h5>
            </div>

            <div className="storage-process">
              <div className="mb-2 d-flex justify-content-between">
                <div className="orderActionSelect">
                  <FormControl fullWidth>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className="form-control"
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      onChange={(e) => handleSelect(e)}
                      value={""}
                      // onBlur={handleBlur}
                      // value={values.search_view}
                    >
                      <MenuItem value="">Action</MenuItem>
                      <MenuItem value="T&Cs">T&Cs</MenuItem>
                      <MenuItem value="Damage/Waiver">Damage/Waiver</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <button
                  className="btn btn-primary font-weight-bolder font-size-sm btn-sm"
                  id="kt_StorageDetail_panel_toggle"
                  onClick={() => {
                    if (orderDetail.id) {
                      dispatch(
                        fetchVersion(orderDetail.franchise_id, orderDetail.id)
                      );
                    }
                  }}
                >
                  {intl.formatMessage({ id: "Storage.Details" })}
                </button>
              </div>
              <div className="row align-items-center">
                <div className="col-4">
                  {orderDetail?.is_cancel === 1
                    ? "Cancelled"
                    : intl.formatMessage({ id: "Fulfillment" })}
                </div>
                <div className="col-8">
                  {orderDetail?.is_container == "1" ? (
                    <ProgressBar
                      variant={
                        orderDetail?.is_cancel === 1 ? "danger" : "success"
                      }
                      now={
                        orderDetail?.container_status == "1"
                          ? 20
                          : orderDetail?.container_status == "2"
                          ? 40
                          : orderDetail?.container_status == "3"
                          ? 60
                          : orderDetail?.container_status == "4"
                          ? 80
                          : orderDetail?.container_status == "5"
                          ? 100
                          : 0
                      }
                      label={
                        orderDetail?.container_status == "1"
                          ? "Res"
                          : orderDetail?.container_status == "2"
                          ? "Confirmed"
                          : orderDetail?.container_status == "3"
                          ? "In Storage"
                          : orderDetail?.container_status == "4"
                          ? "Completed"
                          : orderDetail?.container_status == "5"
                          ? "Cancelled"
                          : "-"
                      }
                    />
                  ) : (
                    <ProgressBar
                      variant={
                        orderDetail?.is_cancel === 1 ? "danger" : "success"
                      }
                      now={
                        fulfillmentStatus(orderDetail?.fulfillment_stage)
                          ?.now || 0
                      }
                      label={
                        fulfillmentStatus(orderDetail?.fulfillment_stage)
                          ?.title || ""
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="separator separator-solid my-5"></div>
          <CustomerDetailCard
            detailData={orderDetail?.customer_details || {}}
            franchise={orderDetail?.franchise_details?.name || ""}
            agent={orderDetail?.agent_details?.first_name || ""}
            agentLast={orderDetail?.agent_details?.last_name || ""}
            orderDetail={orderDetail}
            setOpenCanva={setOpenCanva}
            type={"order"}
          />
        </div>
      </div>
      {openCanva ? (
        <>
          <CreateContactOffCanvas
            title={"Customer Details"}
            type={orderDetail?.customer_details ? "edit" : ""}
            check={"customer"}
            checked={"customers"}
            editCustomer={editValues}
            editCustomerId={orderDetail?.customer_details?.contact?.id || ""}
            setOpenCanva={setOpenCanva}
            orderId={orderDetail?.id || ""}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenCanva(false)}
          ></div>
        </>
      ) : (
        ""
      )}
      {openDamageCanva && (
        <>
          <DamageWaiverCanva
            setOpenDamageCanva={setOpenDamageCanva}
            orderDetail={orderDetail}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenDamageCanva(false)}
          ></div>
        </>
      )}
      {dialog.open && (
        <NotificationModal
          dialog={dialog}
          setDialog={setDialog}
          header={"Reminder Notification"}
          // mainText={"mainText"}
          loading={loading}
          handleChange={handleActionNotification}
        />
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
