/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { Button, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogApi from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogApiCall";
import { EditVan } from "../franchise-canva";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  createFranchiseVanStatus,
  deleteContainer,
} from "../../../_redux/franchises/franchisesCrud";
import { fetchFranchisesVan } from "../../../_redux/franchises/franchisesActions";
import { initIds } from "../../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import InactiveDialog from "../franchise-active-dialog/InactiveDialog";
import { CircularProgress, Switch } from "@material-ui/core";
import SiteModal from "../franchise-modal/SiteModal";
import { ContainerCanva } from "../franchise-canva/ContainerCanva";
import { fetchContainerFranchise } from "../../../_redux/container/containerAction";
import { EditContainerModal } from "../franchise-modal/EditContainerModal";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialogApi from "../franchise-active-dialog/DeleteDialog";
import Pagination from "@material-ui/lab/Pagination";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export function Container({ permission, franchData, fraId }) {
  const intl = useIntl();
  // Use the dispatch hook to dispatch actions
  const dispatch = useDispatch();
  const init = ["kt_container_panel"];
  // Use state hook to manage snackbar state
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // Use state hook to manage dialog state
  const [dialog, setDialog] = useState(false);
  const [dialogEdit, setDialogEdit] = useState({ check: false });
  const [deleteSites, setDeleteSite] = useState({ modal: false });
  const [toggleCheck, setToggleCheck] = useState(false);
  const [page, setPage] = useState(1);

  // Use the useSelector hook to access the state
  const { conLoading, vanLoading, containerList } = useSelector(
    (state) => ({
      conLoading: state.container.conLoading,
      vanLoading: state.franchise.vanLoading,
      containerList: state.container.containerFranchise,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (dialog) initIds(init);
  }, [init, dialog]);

  // Event handler for changing the van status
  const handleChange = () => {
    setSnack({ call: false, type: "", message: "" });

    const data = {
      id: dialog.data.id,
      status: dialog.data.e ? 1 : 0,
    };
    // Call the createFranchiseVanStatus function to update the van status
    createFranchiseVanStatus(data)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data?.statusChangeAllowed !== 1) {
            return;
          }
          // Dispatch an action to fetch updated van data
          dispatch(fetchFranchisesVan(dialog.data.franchise));
          setSnack({
            call: true,
            type: "success",
            message: res.data.message,
          });
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });

    setDialog({ check: false });
  };
  // Render the JSX for the component
  useEffect(() => {
    if (toggleCheck) {
      const data = {
        pageNumber: page,
        pageSize: 20,
        franchise_id: fraId,
      };
      dispatch(fetchContainerFranchise(data));
    }
  }, [toggleCheck]);

  const handleDelete = (id) => {
    setSnack({ call: false, type: "", message: "" });
    deleteContainer({ id: id })
      .then((res) => {
        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });
        setDeleteSite({ modal: false });
        const data = {
          pageNumber: page,
          pageSize: 20,
          franchise_id: fraId,
        };
        dispatch(fetchContainerFranchise(data));
      })
      .catch((err) => {
        setSnack({ call: true, type: "error", message: err });
        setDeleteSite({ modal: false });
      });
  };
  return (
    <>
      <div className="card card-custom  gutter-b mb-10">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center w-100 ">
            <h3 className="card-title p-0 m-0 ">
              <span className="font-weight-bolder text-dark">Container</span>
              <span>
                {toggleCheck ? (
                  <ExpandLessIcon
                    style={{
                      fontSize: "3rem",
                      margin: " 0 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleCheck(!toggleCheck)}
                  />
                ) : (
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: "3rem",
                      margin: " 0 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleCheck(!toggleCheck)}
                  />
                )}
              </span>
              {/* <Switch
                checked={toggleCheck}
                onChange={() => setToggleCheck(!toggleCheck)}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              /> */}
            </h3>
            <div>
              {/* {permission?.setting_franchise?.create ||
              permission?.setting_franchise?.edit ? ( */}
              <Button
                variant="primary"
                size="sm"
                onClick={() => setDialog(true)}
              >
                Add Container
              </Button>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>
          {toggleCheck && (
            <>
              {containerList?.list && containerList?.list?.length ? (
                <>
                  <Table responsive className="mt-5">
                    <thead>
                      <tr>
                        <th>Unique ID</th>
                        <th>Container Name</th>
                        <th>Type</th>
                        <th>Floor</th>
                        <th>Site Name</th>
                        <th>Class Name</th>
                        <th>currently on site</th>
                        <th>Store vehicle</th>
                        <th>Vehicle access</th>
                        <th>Available from</th>
                        <th style={{ width: "20px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {containerList?.list
                        ? containerList?.list.map((data, index) => (
                            <tr key={index}>
                              <td>{data.reference_unique_id}</td>
                              <td>{data.container_name + " sqft"}</td>
                              <td>{data.type}</td>
                              <td>{data.floor}</td>
                              <td>{data.site_name}</td>
                              <td>{data.class_name}</td>
                              <td>{data.currently_on_site}</td>
                              <td>{data.store_vehicle}</td>
                              <td>{data.vehicle_access}</td>
                              <td>
                                {data.available_from
                                  ? moment(data.available_from).format(
                                      "DD-MM-yyyy"
                                    )
                                  : ""}
                              </td>

                              <td className="d-flex">
                                <span
                                  className="symbol-label ml-2 pointer"
                                  onClick={() =>
                                    setDialogEdit({ check: true, data: data })
                                  }
                                >
                                  <span className="svg-icon svg-icon-sm svg-icon-primary">
                                    <SVG
                                      className="h-25 align-self-end"
                                      title="Edit"
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Design/Edit.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </span>
                                {console.log("dataf", data)}
                                <span
                                  className="symbol-label  pointer"
                                  onClick={() =>
                                    setDeleteSite({
                                      modal: true,
                                      data: data?.id,
                                    })
                                  }
                                >
                                  <DeleteIcon />
                                </span>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                  <Pagination
                    count={Math.ceil(+containerList?.totalCount / 20)}
                    page={page}
                    onChange={(e, p) => {
                      const data = {
                        pageNumber: p,
                        pageSize: 20,
                        franchise_id: fraId,
                      };
                      dispatch(fetchContainerFranchise(data));
                      setPage(p);
                    }}
                    shape="rounded"
                  />
                </>
              ) : (
                <>
                  {conLoading ? (
                    <div className="text-center mt-8">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="mt-4" style={{ textAlign: "center" }}>
                      <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {deleteSites.modal && (
        <DeleteDialogApi
          title={"Container"}
          handleChange={handleDelete}
          dialog={deleteSites}
          setDialog={setDeleteSite}
          deleteSite={true}
        />
      )}
      {dialogEdit.check && (
        <EditContainerModal
          dialogCanva={dialogEdit}
          setDialogCanva={setDialogEdit}
          fraId={fraId}
        />
      )}
      {dialog && (
        <ContainerCanva
          dialogCanva={dialog}
          setDialogCanva={setDialog}
          fraId={fraId}
        />
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
