import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { EditOrderNotes } from "../order-canva/EditOrderNotes";

export function OrderDetailOfUpcomingInvoice({ upcomingInvoice, title }) {
  if (
    !upcomingInvoice ||
    !upcomingInvoice.line_items ||
    upcomingInvoice.line_items.length === 0
  ) {
    return (
      <div className="card card-custom gutter-b customer-duration ">
        <div className="card-header align-items-center border-0 mt-4 mb-0">
          <div className="d-flex justify-content-between w-100">
            <h3 className="card-title align-items-start flex-column">
              <span className="font-weight-bolder text-dark">{title}</span>
            </h3>
          </div>
        </div>
        <div className="card-body table-responsive">
          <p>No upcoming invoice details available.</p>
        </div>
      </div>
    );
  }

  const { next_invoice_date, line_items, total, duration } = upcomingInvoice;

  // Format the date
  const formattedDate = new Date(next_invoice_date * 1000).toLocaleDateString(
    "en-GB",
    {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }
  );

  // Calculate subtotal (excluding tax)
  const subtotal = line_items.reduce((acc, item) => acc + item.amount, 0);

  // Example VAT percentage (20%)
  const vatPercentage = 20;
  const vatAmount = (subtotal / (100 + vatPercentage)) * vatPercentage; // VAT inclusive breakdown
  const totalExcludingTax = subtotal - vatAmount;

  return (
    <div className="card card-custom gutter-b customer-duration">
      <div className="card-header align-items-center border-0 mt-4 mb-0">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">{title}</span>
        </h3>
        <p>
          This is a preview of the invoice that will be billed on{" "}
          <strong>{formattedDate}</strong>. It may change if the subscription is
          updated.
        </p>
      </div>
      <div className="card-body table-responsive">
        {/* Invoice Table */}
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>Description</th>
              <th style={{ textAlign: "center" }}>Qty</th>
              <th style={{ textAlign: "center" }}>Unit Price</th>
              <th style={{ textAlign: "right" }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {/* Duration Row */}
            <tr>
              <td style={{ textAlign: "left" }}>{duration}</td>
              <td style={{ textAlign: "center" }}>-</td>
              <td style={{ textAlign: "center" }}>-</td>
              <td style={{ textAlign: "right" }}>-</td>
            </tr>

            {/* Line Items */}
            {line_items.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlign: "left" }}>{item.description}</td>
                <td style={{ textAlign: "center" }}>{item.qty}</td>
                <td style={{ textAlign: "center" }}>
                  £{item.unit_amount.toFixed(2)}
                </td>
                <td style={{ textAlign: "right" }}>
                  £{item.amount.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Summary Section */}
        <div className="invoice-summary" style={{ marginTop: "16px" }}>
          <div style={{ textAlign: "right", marginBottom: "8px" }}>
            <strong>Subtotal:</strong> £{subtotal.toFixed(2)}
          </div>
          <div style={{ textAlign: "right", marginBottom: "8px" }}>
            <strong>Total excluding tax:</strong> £
            {totalExcludingTax.toFixed(2)}
          </div>
          <div style={{ textAlign: "right", marginBottom: "8px" }}>
            <strong>
              VAT – United Kingdom ({vatPercentage}% inclusive on £
              {totalExcludingTax.toFixed(2)}):
            </strong>{" "}
            £{vatAmount.toFixed(2)}
          </div>
          <div style={{ textAlign: "right", marginBottom: "8px" }}>
            <strong>Total:</strong> £{total.toFixed(2)}
          </div>
          <div style={{ textAlign: "right", marginBottom: "8px" }}>
            <strong>Amount due:</strong> £{total.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
}
