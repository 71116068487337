/* eslint-disable no-restricted-imports */
import {
  PricingArray,
  editAddon,
  editCharge,
  editPlan,
} from "../../ProductHelper";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NewProductCanvas } from "../../product-canvas/NewProductCanvas";
import SVG from "react-inlinesvg";
import currencyApi from "../../../../../../utils/currencyApi.json";
import { fetchActiveCountry } from "../../../../_redux/country/countryAction";
import { fetchFranchise } from "../../../../_redux/products/productsActions";
import { initIds } from "../../../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { convertGBPtoPoundSymbol } from "../../../../../../utils/commonFunction";
import { useParams } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import {
  fetchFranchisesSectorPostCode,
  fetchSiteList,
} from "../../../../_redux/franchises/franchisesActions";
import { Feeds } from "../../../../../../../_metronic/_partials/widgets";
import { franchiseSlice } from "../../../../_redux/franchises/franchisesSlice";

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
});

const ProductDetailCard = ({ setCanva, detailData, timeline }) => {
  const { actions } = franchiseSlice;
  const { role } = useSelector(
    (state) => ({
      role: state.auth.user.roles,
    }),
    shallowEqual
  );
  const intl = useIntl();
  const dispatch = useDispatch();
  const init = ["kt_plan_panel"];
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    initIds(init);
  }, [init]);

  const [canvaDetail, setCanvaDetail] = useState({ type: false, data: {} });
  const [currency, setCurrency] = useState("");
  const [sector, setSector] = useState("");
  const [sectorPrice, setSectorPrice] = useState([
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
  ]);

  const { siteList, siteLoading, sectorList } = useSelector(
    (state) => ({
      siteList: state.franchise.siteList,
      siteLoading: state.franchise.siteLoading,
      sectorList: state.franchise.sectorList,
    }),
    shallowEqual
  );
  console.log("siteList", siteList);
  // const handlePrice = (data) => {
  //   const arr = [...pricingArray];

  //   for (let item of data) {
  //     switch (true) {
  //       case item?.interval === "week" &&
  //         item?.interval_count === 1 &&
  //         !arr[0].check:
  //         if (item?.currency === currency) {
  //           arr[0] = {
  //             ...item,
  //             title: "Weekly",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[0] = { ...item, title: "Weekly", disable: false };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 1 &&
  //         !arr[1].check:
  //         if (item.currency === currency) {
  //           arr[1] = {
  //             ...item,
  //             title: "Every 1 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[1] = {
  //             ...item,
  //             title: "Every 1 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 2 &&
  //         !arr[2].check:
  //         if (item.currency === currency) {
  //           arr[2] = {
  //             ...item,
  //             title: "Every 2 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[2] = {
  //             ...item,
  //             title: "Every 2 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 3 &&
  //         !arr[3].check:
  //         if (item.currency === currency) {
  //           arr[3] = {
  //             ...item,
  //             title: "Every 3 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[3] = {
  //             ...item,
  //             title: "Every 3 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 4 &&
  //         !arr[4].check:
  //         if (item.currency === currency) {
  //           arr[4] = {
  //             ...item,
  //             title: "Every 4 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[4] = {
  //             ...item,
  //             title: "Every 4 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 5 &&
  //         !arr[5].check:
  //         if (item.currency === currency) {
  //           arr[5] = {
  //             ...item,
  //             title: "Every 5 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[5] = {
  //             ...item,
  //             title: "Every 5 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 6 &&
  //         !arr[6].check:
  //         if (item.currency === currency) {
  //           arr[6] = {
  //             ...item,
  //             title: "Every 6 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[6] = {
  //             ...item,
  //             title: "Every 6 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 7 &&
  //         !arr[7].check:
  //         if (item.currency === currency) {
  //           arr[7] = {
  //             ...item,
  //             title: "Every 7 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[7] = {
  //             ...item,
  //             title: "Every 7 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 8 &&
  //         !arr[8].check:
  //         if (item.currency === currency) {
  //           arr[8] = {
  //             ...item,
  //             title: "Every 8 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[8] = {
  //             ...item,
  //             title: "Every 8 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 9 &&
  //         !arr[9].check:
  //         if (item.currency === currency) {
  //           arr[9] = {
  //             ...item,
  //             title: "Every 9 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[9] = {
  //             ...item,
  //             title: "Every 9 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 10 &&
  //         !arr[10].check:
  //         if (item.currency === currency) {
  //           arr[10] = {
  //             ...item,
  //             title: "Every 10 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[10] = {
  //             ...item,
  //             title: "Every 10 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "month" &&
  //         item.interval_count === 11 &&
  //         !arr[11].check:
  //         if (item.currency === currency) {
  //           arr[11] = {
  //             ...item,
  //             title: "Every 11 month",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[11] = {
  //             ...item,
  //             title: "Every 11 month",
  //             disable: false,
  //           };
  //         }
  //         break;
  //       case item.interval === "year" &&
  //         item.interval_count === 1 &&
  //         !arr[12].check:
  //         if (item.currency === currency) {
  //           arr[12] = {
  //             ...item,
  //             title: "Yearly",
  //             check: true,
  //             disable: false,
  //           };
  //         } else {
  //           arr[12] = { ...item, title: "Yearly", disable: false };
  //         }
  //         break;

  //       default:
  //         break;
  //     }
  //     setPricingArray(arr);
  //   }
  // };

  useEffect(() => {
    if (
      detailData &&
      detailData.product_plan === "container" &&
      detailData.for_franchise === 1 &&
      detailData.type === "plan"
    ) {
      dispatch(fetchSiteList(params.id, detailData.id));
    }
  }, [detailData.product_plan]);

  useEffect(() => {
    if (
      detailData &&
      detailData.product_plan === "pod" &&
      detailData.for_franchise === 1 &&
      detailData.type === "plan"
    ) {
      dispatch(fetchFranchisesSectorPostCode(params.id));
    }
    return () => {
      dispatch(actions.franchiseSectorPostCodeFetched({ data: [] }));
    };
  }, [detailData, dispatch]);

  console.log("sectorList", sectorList);

  useEffect(() => {
    if (detailData?.metadata?.country) {
      dispatch(fetchFranchise(detailData?.metadata?.country));
      handleCurrency(detailData?.metadata?.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData.metadata, dispatch]);

  // useEffect(() => {
  //   if (detailData?.product_prices && detailData?.product_prices?.length > 0) {
  //     handlePrice(detailData.product_prices);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [detailData.product_prices, currency]);

  useEffect(() => {
    dispatch(fetchActiveCountry({ type: "data_two" }));
  }, [dispatch]);

  const { countryData } = useSelector(
    (state) => ({
      countryData: state.country.activeCountry,
    }),
    shallowEqual
  );

  const handleCurrency = (dd) => {
    const contryCurrency = countryData?.filter(
      (element) => element.id === Number(dd)
    );

    if (contryCurrency?.length) {
      const currencyCode = currencyApi?.filter(
        (element) => element.name === contryCurrency[0].currency
      );

      if (currencyCode?.length) {
        setCurrency(currencyCode[0].cc);
      } else {
        setCurrency("");
      }
    }
  };

  const handletablePricing = (sector) => {
    console.log("sector", sector);
    const isSectorPrices =
      detailData &&
      detailData.product_prices &&
      detailData.product_prices.length > 2 &&
      detailData.product_prices.filter((e) => e.sector_id === sector.id);
    if (isSectorPrices && isSectorPrices.length) {
      setSectorPrice(isSectorPrices);
    } else {
      setSectorPrice([0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]);
    }
  };

  console.log("DETAIL DATAAA", detailData);
  const nonIsSectorPrices =
    detailData &&
    detailData.product_prices &&
    detailData.product_prices.length &&
    detailData.product_prices.filter((e) => e.is_sector === 0);

  // console.log("isSectorPrices", isSectorPrices);
  console.log("nonIsSectorPrices", nonIsSectorPrices);

  return (
    <div className="d-flex">
      <div className="col-md-8">
        <div className="d-flex ">
          <h3 className="card-title align-items-start flex-column d-flex">
            <span
              className="font-weight-bolder text-dark"
              style={{ whiteSpace: "break-spaces", width: "100%" }}
            >
              {detailData?.name}
            </span>
            <span
              className={`text-${
                detailData?.status === 1 ? "success" : "danger"
              } font-weight-bold font-size-sm mt-3`}
            >
              {detailData?.status === 1 ? "Active" : "Archived"}
            </span>
          </h3>
        </div>

        <div>
          <div className="col-md-6 mb-5 d-flex">
            <h3 className="card-title align-items-start flex-column d-flex">
              <span className="font-weight-bolder text-dark">
                {intl.formatMessage({ id: "Product.Details" })}
              </span>
            </h3>
            {params.id && params.p_id ? null : (
              <span
                className="svg-icon svg-icon-sm svg-icon-primary ml-3"
                onClick={() =>
                  setCanvaDetail({
                    type: true,
                    data: {
                      title: `Edit ${detailData?.name}`,
                      type: "editProduct",
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <SVG
                  style={{ color: "orange" }}
                  title="edit"
                  className="h-22 align-self-end"
                  src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                ></SVG>
              </span>
            )}
          </div>
          {/* <div className="col-md-4 mb-5">
          <div>
            <strong>{intl.formatMessage({ id: "Product.ID" })}</strong>{" "}
          </div>
          <div className="text-muted">{detailData?.stripe_product_id}</div>
        </div> */}
          {/* <div className="col-md-4 mb-5">
          <div>
            <strong>{intl.formatMessage({ id: "External.Name" })}</strong>{" "}
          </div>
          <div className="text-muted">{detailData?.external_name}</div>
        </div> */}
          <div className="col-md-4 mb-5">
            <div>
              <strong>{intl.formatMessage({ id: "Name" })}</strong>{" "}
            </div>
            <div className="text-muted">{detailData?.name}</div>
          </div>
          {/* <div className="col-md-4 mb-5">
          <div>
            <strong>{intl.formatMessage({ id: "Description" })}</strong>{" "}
          </div>
          <div className="text-muted">{detailData?.description}</div>
        </div> */}
          <div className="col-md-4 mb-5">
            <div>
              <strong>{intl.formatMessage({ id: "Product.Type" })}</strong>{" "}
            </div>
            <div className="text-muted">
              {detailData?.type === "charge"
                ? "Charge"
                : detailData?.type === "plan"
                ? "Plan"
                : "Addon"}
            </div>
          </div>
          <div className="d-flex">
            <div className="col-md-6">
              <div>
                <span>
                  <strong>{intl.formatMessage({ id: "Unit.Price" })}</strong>
                </span>
                {params.id && params.p_id ? (
                  detailData ? (
                    detailData.product_plan === "container" &&
                    detailData.type === "plan" ? null : (
                      <>
                        {nonIsSectorPrices &&
                        nonIsSectorPrices.length > 0 &&
                        nonIsSectorPrices[0].pricing_model ===
                          "tiered" ? null : (
                          <span
                            className="svg-icon svg-icon-sm svg-icon-primary ml-3"
                            onClick={() =>
                              setCanvaDetail({
                                type: true,
                                data: {
                                  title: `Edit ${detailData?.name}`,
                                  type: "editPrice",
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <SVG
                              style={{ color: "orange" }}
                              title="edit"
                              className="h-22 align-self-end"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Design/Edit.svg"
                              )}
                            />
                          </span>
                        )}
                      </>
                    )
                  ) : null
                ) : null}
              </div>
              <div className="mt-2">
                {console.log("detailData-548", detailData)}
                {detailData &&
                detailData.product_plan === "container" &&
                detailData.for_franchise === 1 &&
                (detailData.type === "plan" ||
                  detailData?.stripe_product_id ==
                    process.env?.REACT_APP_PRODUCT_LOCKRENTAL) ? (
                  siteList && siteList.length > 0 ? (
                    <>
                      <div>
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Sites Name</TableCell>
                                <TableCell align="center">
                                  Price per Unit (&pound;/mo)
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {siteList.map((e) => {
                                return (
                                  <>
                                    <TableRow key={e.id}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                      >
                                        {e?.name}
                                      </TableCell>
                                      <TableCell align="center">
                                        <span className="mx-2">
                                          {e.product_prices &&
                                          e.product_prices.length > 0 &&
                                          e.product_prices[0].unit_amount !== ""
                                            ? e.product_prices[0].unit_amount
                                            : "0.0"}
                                        </span>
                                        <span
                                          className="symbol-label  pointer"
                                          onClick={() =>
                                            setCanvaDetail({
                                              type: true,
                                              data: {
                                                title: `Edit ${e?.name}`,
                                                type: "editSitePrice",
                                                siteID: e?.id,
                                                data: e,
                                                pID: detailData?.id,
                                              },
                                            })
                                          }
                                        >
                                          <span className="svg-icon svg-icon-sm svg-icon-primary mr-1">
                                            <SVG
                                              className="h-25 align-self-end"
                                              title="Edit"
                                              src={toAbsoluteUrl(
                                                "/media/svg/icons/Design/Edit.svg"
                                              )}
                                            ></SVG>
                                          </span>
                                        </span>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </>
                  ) : (
                    <div>No Sites Available</div>
                  )
                ) : (
                  <>
                    {!nonIsSectorPrices ? null : nonIsSectorPrices.length <
                      2 ? (
                      <>
                        <div className="mb-5">
                          <div className="text-muted">
                            {nonIsSectorPrices &&
                            nonIsSectorPrices.length > 0 &&
                            nonIsSectorPrices[0]
                              ? convertGBPtoPoundSymbol(
                                  nonIsSectorPrices[0].currency
                                ) + nonIsSectorPrices[0].unit_amount
                              : ""}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <h3 className="card-title align-items-start flex-column mt-2">
                            <span className="font-weight-bolder text-dark">
                              Stairstep pricing breakdown{" "}
                            </span>
                            {params.id && params.p_id ? (
                              <span
                                className="svg-icon svg-icon-sm svg-icon-primary ml-3"
                                onClick={() =>
                                  setCanvaDetail({
                                    type: true,
                                    data: {
                                      title: `Edit ${detailData?.name}`,
                                      type: "editPrice",
                                    },
                                  })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <SVG
                                  style={{ color: "orange" }}
                                  title="edit"
                                  className="h-22 align-self-end"
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Design/Edit.svg"
                                  )}
                                ></SVG>
                              </span>
                            ) : null}
                          </h3>
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">
                                    Pod (Units)
                                  </TableCell>
                                  <TableCell align="center">
                                    Price per Unit (&pound;/mo)
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {nonIsSectorPrices &&
                                  nonIsSectorPrices.length > 2 &&
                                  nonIsSectorPrices.map((e, index) => {
                                    if (index > 6) {
                                      return;
                                    }
                                    return (
                                      <>
                                        <TableRow>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                          >
                                            {e?.tier_start_unit === 7
                                              ? "7+"
                                              : e?.tier_start_unit}
                                          </TableCell>
                                          <TableCell align="center">
                                            &pound;{e?.unit_amount}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div>
                {sectorList && sectorList.length ? (
                  <FormControl fullWidth>
                    <strong>
                      <label>Choose Sector</label>
                    </strong>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      name="sector"
                      className={`form-control w-100 `}
                      value={sector}
                      onChange={(e) => {
                        setSector(e.target.value);
                        handletablePricing(e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        {intl.formatMessage({ id: "Select" })}
                      </MenuItem>
                      {sectorList &&
                        sectorList.length &&
                        sectorList.map((e) => {
                          return <MenuItem value={e}>{e.name}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                ) : null}
              </div>
              <div className="mt-2">
                {sector !== "" && (
                  <div>
                    <h3 className="card-title align-items-start flex-column mt-2">
                      <span className="font-weight-bolder text-dark">
                        {sector.name}
                      </span>
                      {sector && (
                        <span
                          className="symbol-label  pointer mx-1"
                          onClick={() =>
                            setCanvaDetail({
                              type: true,
                              data: {
                                title: `Edit ${sector.name} Price`,
                                type: "editPrice",
                                sectorId: sector.id,
                              },
                            })
                          }
                        >
                          <span className="svg-icon svg-icon-sm svg-icon-primary mr-1">
                            <SVG
                              className="h-25 align-self-end"
                              title="Edit"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Design/Edit.svg"
                              )}
                            ></SVG>
                          </span>
                        </span>
                      )}
                    </h3>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Pod (Units)</TableCell>
                            <TableCell align="center">
                              Price per Unit (&pound;/mo)
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sectorPrice.map((e, index) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    {e?.tier_start_unit === 7
                                      ? "7+"
                                      : e?.tier_start_unit || index + 1}
                                  </TableCell>
                                  <TableCell align="center">
                                    &pound;{e?.unit_amount || "0.0"}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {canvaDetail.type ? (
          <>
            <NewProductCanvas
              setCanva={setCanvaDetail}
              canva={canvaDetail}
              editValue={
                canvaDetail?.data?.type === "editSitePrice"
                  ? canvaDetail?.data?.data
                  : detailData
              }
              type={
                params.id && params.p_id
                  ? canvaDetail?.data?.type === "editSitePrice"
                    ? "editSitePrice"
                    : "priceEdit"
                  : "productEdit"
              }
            />
            <div
              className="offcanvas-overlay"
              onClick={() => setCanvaDetail({ type: false, data: {} })}
            ></div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-4" style={{ height: "100px" }}>
        <Feeds classNameSend="card-stretchs" data={timeline} type={"product"} />
      </div>
    </div>
  );
};

export default ProductDetailCard;
