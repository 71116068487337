import * as requestFromServer from "./commonCrud";
import { commonSlice, callTypes } from "./commonSlice";

const { actions } = commonSlice;

export const fetchNotification = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .convertContactDeal()
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.notificationFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchDashboardFeed = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .dashboardFeed()
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.dashboardFeeds({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getDashboardData = (filter, fraId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.loading }));
  return requestFromServer
    .getDashboardDataAxios(filter, fraId)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.getDashboardDataSlice({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.loading }));
    });
};
export const getPodStandingData = (filter, fraId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.loadingPod }));
  return requestFromServer
    .getDashboardPodStandingDataAxios(filter, fraId)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.getDashboardPodDataSlice({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.loadingPod }));
    });
};
export const getContainerStandingData = (filter, fraId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.loadingContainer }));
  return requestFromServer
    .getDashboardContainerStandingDataAxios(filter, fraId)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.getDashboardContainerDataSlice({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(
        actions.catchError({ error, callType: callTypes.loadingContainer })
      );
    });
};
export const getFillRateData = (filter, fraId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.loadingContainer }));
  return requestFromServer
    .getFillRateDataAxios(filter, fraId)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.getFillRateDataSlice({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(
        actions.catchError({ error, callType: callTypes.loadingContainer })
      );
    });
};
