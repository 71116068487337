import { WeekCounter } from "../../utils/commonFunction";
import { containerList } from "./_redux/order/orderCrud";
// import { fetchOrderSummary } from "./_redux/order/orderAction";

export const handleUpdateNewOrderQuote = (quote) => {
  const data = {
    totalData: { discountValue: 0 },
    productArray: [],
    manualDiscount: {},
    adHoc: {},
    adhocGbp: 0,
    adhocGbpDummy: 0,
    coupon_id: [],
    currencyCode: "",
    driver_note: "",
    subTotal: "",
    paymentIntent: {},
    isShip: "0",
    afterDiscount: "",
    // params: {},
    total: "",
    vat: "",
    vatTotal: "",
    params: { unit: quote.period, period: quote.periodUnit, products_id: [] },
    postcode: {
      postcode:
        (quote?.customer &&
          quote?.customer?.length &&
          quote?.customer[0]?.postcode) ||
        "",
    },
    quantity: [],
    deletedProduct: quote?.quoteDetails?.deleted_product || [],
    // coupons: [],
    cover: {},
    plan_type:
      quote?.customer?.length > 0
        ? quote?.customer[0]?.contact?.franchise?.plan_type
        : "",
    containerTypeSelect: quote?.is_container === 1 ? "container" : "pod",
    containerListSelect: quote?.container_size || "",
    siteId: quote?.site_id,
    is_plan_replaced: quote?.quoteDetails?.is_plan_replaced,
    // siteSelect: {
    //   id: 12,
    //   franchise_id: 81,
    //   name: "Jagatpura-1",
    //   available_from: "2024-02-28",
    //   address:
    //     "Acorn Solutions Ltd, Unit 2B, Brick Knoll Park, Ashley Road, St. Albans, Hertfordshire, St. Albans, al1 5ug",
    //   capacity: 1,
    //   images: [
    //     {
    //       id: 46,
    //       site_id: 12,
    //       image:
    //         "https://apistaging.easystorage.com/storage/site_images/3139.4064082386253.jpg",
    //       is_default: 0,
    //       deleted_at: null,
    //       created_at: "2024-03-12T00:00:00.000000Z",
    //       updated_at: null,
    //     },
    //     {
    //       id: 47,
    //       site_id: 12,
    //       image:
    //         "https://apistaging.easystorage.com/storage/site_images/5754.853807889577.jpg",
    //       is_default: 0,
    //       deleted_at: null,
    //       created_at: "2024-03-12T00:00:00.000000Z",
    //       updated_at: null,
    //     },
    //   ],
    //   franchise: {
    //     id: 81,
    //     name: "UxBridge",
    //     short_name: "ux",
    //     chargebee_key: "uxbridge-test",
    //     stripe_pk: "test_EkIY0DqMftpTIsGqKnctP84rcunxgGwwv",
    //   },
    //   distance: 45.891,
    // },
  };
  // const ff = quote?.qtyProducts
  const arrP = quote?.qtyProducts ? [...quote?.qtyProducts] : [];
  // console.log(arrP);
  const index = arrP && arrP.findIndex((item) => item.id === "Adhoc-Charges");

  // Move the element to the end of the array
  if (index !== -1) {
    const removedItem = arrP?.splice(index, 1); // Remove the element from its current position
    // eslint-disable-next-line no-unused-expressions
    arrP && arrP.push(removedItem[0]); // Push the removed element to the end of the array
  }
  // eslint-disable-next-line no-unused-expressions
  arrP?.forEach((e, index) => {
    data.params.products_id.push(e.id);
    if (arrP[index].id === "Adhoc-Charges") {
      data.adhocGbp =
        arrP[index].id === "Adhoc-Charges" ? arrP[index].price : "";
      data.adhocGbpDummy =
        arrP[index].id === "Adhoc-Charges" ? arrP[index].price : "";
    }
    const coverData = {
      cover:
        quote.orderSummaryData[index].type === "addon" ? arrP[index].cover : "",
      price:
        quote.orderSummaryData[index].type === "addon" ? arrP[index].price : 0,
    };
    data.cover = coverData;
    data.quantity.push({
      i: index,
      id: arrP[index].id,
      qty: arrP[index].quantity,
    });

    data.productArray.push({
      cost: +arrP[index]?.price,
      currency: quote?.currency,
      id: arrP[index]?.id,
      name: arrP[index]?.name,
      price_id: quote?.orderSummaryData[index]?.quote_data.entity_id,
      productPrice: arrP[index]?.price,
      qty: +arrP[index]?.quantity,
      total:
        quote.orderSummaryData[index].type === "addon"
          ? quote.orderSummaryData[index].amount
          : "",
      type: quote.orderSummaryData[index].type,
      vatPrice: quote.orderSummaryData[index].quote_data.is_taxed,
      cover_list: arrP[index]?.cover_list || {},
      cover: arrP[index].cover,
      pricing_model:
        quote.orderSummaryData[index].quote_data.pricing_model || "",
    });
  });
  if (quote && quote.discounts && quote.discounts.length) {
    const discount = {
      applyOn: quote?.discounts[0]?.apply_on || "",
      discountType: quote?.discounts[0]?.type,
      duration: quote?.discounts[0]?.duration_type,
      enterDiscount:
        quote?.discounts[0]?.percentage || quote?.discounts[0]?.amount,
      limitedPeriod: quote?.discounts[0]?.period,
      productDiscount: quote?.discounts[0]?.period_unit,
      time: "",
    };
    data.manualDiscount = discount;
    data.coupon_id.push({
      type: quote?.discounts[0]?.type,
      value: quote?.discounts[0]?.percentage || quote?.discounts[0]?.amount,
      name: "manual",
    });
  }
  data.currencyCode = quote?.currency;

  return data;
};

export const formValues = (cardDetail, admin) => {
  let addressData =
    cardDetail && cardDetail.length && cardDetail[0].address
      ? cardDetail[0].address
      : "";
  if (addressData !== "") {
    addressData = addressData?.replaceAll(", ", ",");
    addressData = addressData?.replaceAll(",, ", ",");
    addressData = addressData?.replaceAll(",,,", ",");
  }
  const editValues = {
    first_name:
      cardDetail && cardDetail.length && cardDetail[0].first_name
        ? cardDetail[0].first_name
        : "",
    last_name:
      cardDetail && cardDetail.length && cardDetail[0].last_name
        ? cardDetail[0].last_name
        : "",
    email:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact.email
        ? cardDetail[0].contact.email
        : [],
    phone:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact.phone
        ? cardDetail[0].contact.phone
        : [],
    postcode:
      cardDetail && cardDetail.length && cardDetail[0].postcode
        ? cardDetail[0].postcode
        : "",
    city:
      cardDetail && cardDetail.length && cardDetail[0].city
        ? cardDetail[0].city
        : "",
    address: addressData,
    user_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact.user_id
        ? cardDetail[0].contact.user_id
        : "",
    is_address_manual:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact.is_address_manual
        ? cardDetail[0].contact.is_address_manual
        : 0,
    franchise_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact.franchise_id
        ? cardDetail[0].contact.franchise_id
        : "",
    contact_owner_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact.contact_owner_id
        ? cardDetail[0].contact.contact_owner_id
        : admin && admin.id
        ? admin.id
        : "",
  };
  return editValues;
};

export const updateOrderDetail = () => {};

export const productArrayNewData = (
  items,
  qtys,
  currencyIndex,
  quantity,
  reduxState
) => {
  let data = {
    id: items.item.id,
    type: items.item.type,
    name: items.item.external_name,
    qty: qtys || items?.item?.metadata?.pods_count || 1,
    price_id:
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.item_price
        ? items.price[currencyIndex]?.item_price?.id
        : "",
    cover_list:
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.cover_list
        ? items.price[currencyIndex]?.cover_list?.list
        : {},
    currency:
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.item_price
        ? items.price[currencyIndex]?.item_price?.currency_code
        : "",
    cost:
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.item_price
        ? items.price[currencyIndex]?.item_price?.price
        : "N/A",
    productPrice:
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.item_price
        ? items.price[currencyIndex]?.item_price?.price
        : "N/A",
    vatPrice:
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.item_price
        ? items.price[currencyIndex]?.item_price?.is_taxable
        : false,

    total:
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.item_price
        ? ` ${Number(
            quantity?.find((e) => e.id === items?.item?.id) &&
              quantity?.find((e) => e.id === items?.item?.id)?.qty
              ? quantity?.find((e) => e.id === items?.item?.id)?.qty
              : 1
          ) * Number(items.price[currencyIndex].item_price.price)}`
        : "N/A",
    pricing_model:
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.item_price
        ? items.price[currencyIndex]?.item_price?.pricing_model
        : "N/A",
  };
  if (items.item.type === "plan") {
    data.competitor_manual_discount =
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.competitor_manual_discount
        ? items.price[currencyIndex]?.competitor_manual_discount
        : "";
    data.extra_pod_price =
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.extra_pod_price
        ? items.price[currencyIndex]?.extra_pod_price
        : "";
    data.manual_discount =
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.manual_discount
        ? items.price[currencyIndex]?.manual_discount
        : "";
    data.pod_25 =
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.pod_25
        ? items.price[currencyIndex]?.pod_25
        : "";
  }

  if (items.item.type === "addon" && items.item.id !== "Loose-Load") {
    data.total = reduxState?.cover?.price || data.cover_list["4000"];
    data.cover = reduxState?.cover?.cover || "4000";
  }
  if (items.item.id === "Howdens") {
    data.total =
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.item_price
        ? ` ${Number(
            quantity?.find((e) => e.id === items?.item?.id) &&
              quantity?.find((e) => e.id === items?.item?.id)?.qty &&
              quantity?.find((e) => e.id === items?.item?.id)?.qty === 1
              ? 2
              : 2
          ) * Number(items.price[currencyIndex].item_price.price)}`
        : "N/A";
    data.qty = Number(data.qty) > 2 ? 2 : data.qty;
  }
  return data;
};

export const productArrayUpdated = (
  arr,
  updateIndex,
  items,
  currencyIndex,
  state,
  podCount
) => {
  arr[updateIndex].price_id =
    items &&
    items.price &&
    items.price.length &&
    items.price[currencyIndex]?.item_price
      ? items.price[currencyIndex]?.item_price?.id
      : "";
  arr[updateIndex].productPrice =
    items &&
    items.price &&
    items.price.length &&
    items.price[currencyIndex]?.item_price
      ? items.price[currencyIndex]?.item_price?.price
      : "N/A";
  arr[updateIndex].cost =
    items &&
    items.price &&
    items.price.length &&
    items.price[currencyIndex]?.item_price
      ? items.price[currencyIndex]?.item_price?.price
      : "N/A";
  arr[updateIndex].vatPrice =
    items &&
    items.price &&
    items.price.length &&
    items.price[currencyIndex]?.item_price
      ? items.price[currencyIndex]?.item_price?.is_taxable
      : false;
  arr[updateIndex].total =
    items.item.type === "addon" &&
    (items.item.id === "Container-Protect" ||
      items.item.id === "Store-Protect" ||
      items.item.id === "Insurance-Cover")
      ? // ? state?.cover?.cover
        //   ? state?.cover?.price
        //   : arr[updateIndex].cover_list[arr[updateIndex].cover]
        arr[updateIndex].cover_list[arr[updateIndex].cover]
      : items &&
        items.price &&
        items.price.length &&
        items.price[currencyIndex]?.item_price
      ? state?.containerTypeSelect == "pod" && items.item.type === "plan"
        ? Number(items.price[currencyIndex]?.item_price?.price)
        : ` ${Number(
            state?.quantity?.find((e) => e.id === items?.item?.id) &&
              state?.quantity.find((e) => e.id === items?.item?.id)?.qty
              ? state?.quantity.find((e) => e.id === items?.item?.id)?.qty
              : arr[updateIndex].qty
              ? Number(arr[updateIndex].qty)
              : 1
          ) * Number(items.price[currencyIndex]?.item_price?.price)}`
      : "N/A";
  // arr[updateIndex].total =
  //   items.item.type === "addon" && state?.cover?.cover
  //     ? state?.cover?.price
  //     : items &&
  //       items.price &&
  //       items.price.length &&
  //       items.price[currencyIndex]?.item_price
  //     ? ` ${Number(arr[updateIndex].qty ? Number(arr[updateIndex].qty) : 1) *
  //         Number(items.price[currencyIndex].item_price.price)}`
  //     : "N/A";

  if (
    arr[updateIndex].pricing_model === "stairstep" ||
    arr[updateIndex]?.pricing_model === "tiered"
  ) {
    arr[updateIndex].qty = podCount || 1;
    arr[updateIndex].total = arr[updateIndex].cost;
  }
  if (items.item.id === "Howdens") {
    arr[updateIndex].total =
      items &&
      items.price &&
      items.price.length &&
      items.price[currencyIndex]?.item_price
        ? ` ${Number(
            state?.quantity?.find((e) => e.id === items?.item?.id) &&
              state?.quantity?.find((e) => e.id === items?.item?.id)?.qty &&
              state?.quantity?.find((e) => e.id === items?.item?.id)?.qty === 1
              ? 2
              : 2
          ) * Number(items.price[currencyIndex].item_price.price)}`
        : "N/A";
  }
  return arr;
};

export const saveDraftOrder = (cardDetail, location, state) => {
  const data = {
    product: [],
    deal_id:
      location && location?.state?.dealId
        ? location?.state?.dealId
        : cardDetail &&
          cardDetail.length &&
          cardDetail[0].contact &&
          cardDetail[0].contact.latest_deals
        ? cardDetail[0].contact.latest_deals?.id
        : "",
    first_name:
      cardDetail && cardDetail.length && cardDetail[0].first_name
        ? cardDetail[0].first_name
        : "",
    last_name:
      cardDetail && cardDetail.length && cardDetail[0].last_name
        ? cardDetail[0].last_name
        : "",
    email:
      cardDetail && cardDetail.length && cardDetail[0].email
        ? cardDetail[0].email
        : "",
    phone:
      cardDetail && cardDetail.length && cardDetail[0].phone
        ? cardDetail[0].phone
        : "",
    address:
      cardDetail && cardDetail.length && cardDetail[0].address
        ? cardDetail[0].address
        : "",
    city:
      cardDetail && cardDetail.length && cardDetail[0].city
        ? cardDetail[0].city
        : "",
    zip:
      cardDetail && cardDetail.length && cardDetail[0].postcode
        ? cardDetail[0].postcode
        : "",
    country: state?.currencyCode ? state?.currencyCode.substring(0, 2) : "",
    franchise_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact?.franchise_id
        ? cardDetail[0].contact?.franchise_id
        : "",
    agent_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact?.agent?.id
        ? cardDetail[0].contact?.agent?.id
        : "",
    contact_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact?.id
        ? cardDetail[0].contact?.id
        : "",
    apply_manual_discounts: [],
    is_manual_discount: 0,
    currency_code: state && state.currencyCode ? state.currencyCode : "",
    adhoc_charge_price: "",
    adhoc_charge_quantity: "",
    coupon_id: [],
    item_price_id: [],
    quantity: [],
    quote_type: "draft",
  };
  return data;
};

export const newOrderIntent = (state) => {
  const intent = {
    id: state?.paymentIntent?.data?.id || "",
    expires_at: Number(state?.paymentIntent?.data?.expires_at) || "",
    amount: Number(state?.paymentIntent?.data?.amount) || "",
    created_at: Number(state?.paymentIntent?.data?.created_at) || "",
    currency_code: state?.paymentIntent?.data?.currency_code || "",
    gateway: state?.paymentIntent?.data?.gateway || "",
    gateway_account_id: state?.paymentIntent?.data?.gateway_account_id || "",
    modified_at: Number(state?.paymentIntent?.data?.modified_at) || "",
    object: state?.paymentIntent?.data?.object || "",
    payment_method_type: state?.paymentIntent?.data?.payment_method_type || "",
    status: state?.paymentIntent?.data?.status || "",
    customerId: state?.paymentIntent?.data?.customer_id || "",
  };
  return intent;
};

export const newOrderPayment = (cardDetail, state, formik, token) => {
  const data = {
    id:
      cardDetail && cardDetail.length && cardDetail[0].id
        ? cardDetail[0].id
        : "",
    amount:
      // eslint-disable-next-line eqeqeq
      state?.total == 0
        ? 0
        : +state?.total.toFixed(2) || +state?.subTotal.toFixed(2) || 0,
    product: [],
    packaging_materials: [],
    first_name:
      cardDetail && cardDetail.length && cardDetail[0].first_name
        ? cardDetail[0].first_name
        : "",
    last_name:
      cardDetail && cardDetail.length && cardDetail[0].last_name
        ? cardDetail[0].last_name
        : "",
    email:
      cardDetail && cardDetail.length && cardDetail[0].email
        ? cardDetail[0].email
        : "",
    phone:
      cardDetail && cardDetail.length && cardDetail[0].phone
        ? cardDetail[0].phone
        : "",
    address:
      cardDetail && cardDetail.length && cardDetail[0].address
        ? cardDetail[0].address
        : "",
    city:
      cardDetail && cardDetail.length && cardDetail[0].city
        ? cardDetail[0].city
        : "",
    zip:
      cardDetail && cardDetail.length && cardDetail[0].postcode
        ? cardDetail[0].postcode
        : "",
    country: state?.currencyCode ? state?.currencyCode.substring(0, 2) : "",
    franchise_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact?.franchise_id
        ? cardDetail[0].contact?.franchise_id
        : "",
    agent_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact?.agent?.id
        ? cardDetail[0].contact?.agent?.id
        : "",
    contact_id:
      cardDetail &&
      cardDetail.length &&
      cardDetail[0].contact &&
      cardDetail[0].contact?.id
        ? cardDetail[0].contact?.id
        : "",
    apply_manual_discounts: [],
    is_manual_discount: 0,
    currency_code: state?.currencyCode ? state?.currencyCode : "",
    adhoc_charge_price: "",
    adhoc_charge_quantity: "",
    coupon_id: [],
    item_price_id: [],
    quantity: [],
    ship_packaging_materials: state?.isShip,

    customer_id: state?.paymentIntent?.data?.customer_id || "",
    gw_token: token || "",
    driver_note: state?.driver_note || "",

    is_billing_address: formik.values.billing === "same" ? 0 : 1,
    shipping_line_one: formik.values.street1,
    shipping_line_two: formik.values.street2,
    shipping_line_three: formik.values.street3,
    shipping_city: formik.values.city,
    shipping_zip: formik.values.postcode,
    collection_date: state.collectionRetrun?.collection_date || "",
    start_time_slot: state.collectionRetrun?.start_time_slot || "",
    end_time_slot: state.collectionRetrun?.end_time_slot || "",
    collection_address_line:
      state.collectionRetrun?.collection_address_line || "",
    collection_address_city:
      state.collectionRetrun?.collection_address_city || "",
    collection_postcode: state.collectionRetrun?.collection_postcode || "",
    return_postcode: state.collectionRetrun?.return_postcode || "",
    return_date:
      state.collectionRetrun?.return_date &&
      state.collectionRetrun?.return_start_time_slot
        ? state.collectionRetrun?.return_date
        : "",
    is_return:
      state.collectionRetrun?.return_date &&
      state.collectionRetrun?.return_start_time_slot
        ? 1
        : "0",
    return_start_time_slot:
      state.collectionRetrun?.return_start_time_slot || "",
    return_end_time_slot: state.collectionRetrun?.return_end_time_slot || "",
    return_address_line: state.collectionRetrun?.return_address_line || "",
    return_address_city: state.collectionRetrun?.return_address_city || "",
    depot_id: state.collectionRetrun?.depot_id || "",
    van_id: state.collectionRetrun?.van_id || "",
    collection_van_id: state.collectionRetrun?.collection_van_id || "",
    return_van_id: state.collectionRetrun?.return_van_id || "",
    is_student: state.collectionRetrun?.is_student || "",
    is_student_return: state.collectionRetrun?.is_student_return || "",
    state: "jaipur",
    storage_note: JSON.parse(localStorage.getItem("selectedUserItems")),
    is_manual: state.collectionRetrun?.is_manual || "0",
    is_address_manual: state.collectionRetrun?.is_address_manual || "",
    is_return_address_manual:
      state.collectionRetrun?.is_return_address_manual || "",
    is_collection_address: state.collectionRetrun?.is_collection_address || "",
    is_return_address: state.collectionRetrun?.is_return_address || "",
    period: state?.params?.unit || "",
    period_unit: state?.params?.period || "",
    container_size: state?.containerListSelect || "",
    site_id: state?.siteSelect?.id || "",
    pod_plan_type: "",
  };
  if (state.containerTypeSelect !== "container") {
    delete data.container_size;
    delete data.site_id;
  }
  if (
    state.collectionRetrun?.collection_date &&
    state.collectionRetrun?.return_date &&
    state.collectionRetrun?.return_start_time_slot &&
    !state.productArray.some((e) => e.id === "Howdens")
  ) {
    const week = WeekCounter(
      state.collectionRetrun?.collection_date,
      state.collectionRetrun?.return_date
    );
    data.billing_cycle = week;
  }
  if (state?.productArray.find((e) => e.type === "plan")?.extra_pod_price) {
    data.extra_pod_price =
      state?.productArray.find((e) => e.type === "plan")?.extra_pod_price || 0;
  }
  const planSelect = state?.productArray.find((e) => e.type === "plan");
  if (planSelect && state.containerTypeSelect !== "container") {
    data.pod_plan_type =
      planSelect?.id === "Economy-POD"
        ? "Economy"
        : planSelect?.id === "POD"
        ? "Premium"
        : planSelect?.id === "First-Class"
        ? "First Class"
        : "";
    data.bp_id = planSelect?.id;
  }
  return data;
};

export const newOrderAddressAuth3ds = (cardDetail, addre, state, formik) => {
  const formikValues = formik?.values?.billing;
  const additionalData = {
    billingAddress: {
      firstName:
        cardDetail && cardDetail.length > 0 && cardDetail[0].firstName
          ? cardDetail[0].firstName
          : "",
      lastName:
        cardDetail && cardDetail.length > 0 && cardDetail[0].lastName
          ? cardDetail[0].lastName
          : "",

      phone:
        cardDetail && cardDetail.length > 0 && cardDetail[0].phone
          ? cardDetail[0].phone
          : "",
      addressLine1:
        formikValues === "manual"
          ? formik?.values.street1
          : cardDetail && cardDetail.length > 0 && cardDetail[0].address
          ? cardDetail[0].address
          : "",

      city:
        formikValues === "manual"
          ? formik?.values.city
          : cardDetail && cardDetail.length > 0 && cardDetail[0].city
          ? cardDetail[0].city
          : "",
      countryCode: state?.currencyCode
        ? state?.currencyCode.substring(0, 2)
        : "",
      zip:
        formikValues === "manual"
          ? formik?.values.postCode
          : cardDetail && cardDetail.length > 0 && cardDetail[0].postcode
          ? cardDetail[0].postcode
          : "",
    },
  };
  return additionalData;
};

export const newOrderPaymentIntent = (value, state, stValue, finalPrice) => {
  // const ten = stValue?.total && +stValue?.total.toFixed(2) * 0.1;
  const tenPercent =
    !state.collectionRetrun?.return_start_time_slot &&
    state.collectionRetrun?.collection_date &&
    (state?.containerTypeSelect != "pod" ||
      (state?.containerTypeSelect == "pod" &&
        state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID &&
        state?.product_array?.some(
          (e) => e.type === "plan" && e?.id !== "Economy-POD"
        ))) &&
    new Date(state.collectionRetrun?.collection_date) >
      new Date(Date.now() + 48 * 60 * 60 * 1000);
  const data = {
    first_name: value?.first_name ? value.first_name : "",
    last_name: value?.last_name ? value.last_name : "",
    email: value?.email ? value.email : "",
    address: value?.address ? value.address : "",
    city: value?.city ? value.city : "",
    state: "",
    zip: value?.postcode ? value.postcode : "",
    country: "GB",
    amount: tenPercent
      ? Number(Number(state?.variantProductTotal?.total) * 0.1).toFixed(2)
      : Number(state?.variantProductTotal?.total).toFixed(2),
    // amount:
    // (state?.containerTypeSelect != "pod" ||(state?.containerTypeSelect == "pod" && state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID && state?.productArray?.some((e)=> e.type === "plan" &&e?.id !== "Economy-POD")))&&
    //   // state?.containerTypeSelect === "container" &&
    //   state.collectionRetrun?.collection_date &&
    //   // Convert string date to Date object
    //   new Date(state.collectionRetrun?.collection_date) >
    //     new Date(Date.now() + 48 * 60 * 60 * 1000)
    //     ? +ten || 1
    //     : +stValue?.total +
    //         Number(
    //           state?.productArray.find((e) => e.type === "plan")
    //             ?.extra_pod_price || 0
    //         ) ||
    //       Number(stValue?.subTotal) ||
    //       "",
    currency_code: "GBP",
    franchise_id: value?.contact?.franchise_id
      ? value.contact.franchise_id
      : "",
  };
  // if (
  //   state.collectionRetrun?.collection_date &&
  //   state.collectionRetrun?.return_date &&
  //   state.collectionRetrun?.return_start_time_slot
  // ) {
  //   if( state.collectionRetrun?.collection_date &&
  //     (state?.containerTypeSelect == "pod" && state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID && state?.productArray?.some((e)=> e.type === "plan" &&e?.id !== "Economy-POD"))&&
  //     // Convert string date to Date object
  //     new Date(state.collectionRetrun?.collection_date) >
  //       new Date(Date.now() + 48 * 60 * 60 * 1000)){
  //         data.amount = finalPrice ? Math.ceil(finalPrice*0.10) : "";

  //       }else{

  //         data.amount = finalPrice ? Math.ceil(finalPrice) : "";
  //       }

  //   // Number(stValue?.subTotal) * Number(week);
  // }

  return data;
};

export const orderCollectionValue = (moment, orderDetail, keys) => {
  const initialValues = {
    order_id: orderDetail?.id || "",
    collection_date:
      orderDetail?.collection_date || moment(new Date()).format("yyyy-MM-DD"),
    return_date: orderDetail?.return_date || "",
    return_start_time_slot: orderDetail?.return_start_time_slot || "",
    return_end_time_slot:
      moment(orderDetail?.return_date + "T" + orderDetail?.return_end_time_slot)
        .add(1, "hours")
        .format("HH:mm") || "",
    start_time_slot: orderDetail?.start_time_slot || "",
    end_time_slot:
      moment(orderDetail?.collection_date + "T" + orderDetail?.end_time_slot)
        .add(1, "hours")
        .format("HH:mm") || "",
    depot_id: orderDetail?.depot_id || "",
    van_id: "",
    is_collection_address: orderDetail?.is_collection_address || "0",
    is_return_address: orderDetail?.is_return_address || "0",
    collection_postcode: orderDetail?.collection_postcode || "",
    return_postcode: orderDetail?.return_postcode || "",
    is_collection_address_manual:
      orderDetail?.is_collection_address_manual || "0",
    is_return_address_manual: orderDetail?.is_return_address_manual || "0",
    is_address_manual: "0",
    collection_address_line: orderDetail?.collection_address_line || "",
    collection_address_city: orderDetail?.collection_address_city || "",
    return_address_line: orderDetail?.return_address_line || "",
    return_address_city: orderDetail?.return_address_city || "",
    is_student: orderDetail?.is_student || "0",
    is_student_return: orderDetail?.is_student_return || "0",
    is_return: orderDetail?.return_date ? 1 : "0",
    collection_van_id: orderDetail?.collection_van_id || "",
    return_van_id: orderDetail?.return_van_id || "",
    return_pod_number: [],
    return_reason: orderDetail?.return_reason || "",
    return_reason_description: orderDetail?.return_reason_description || "",
  };
  const initialAccessValues = {
    collection_date:
      orderDetail?.collection_date || moment(new Date()).format("yyyy-MM-DD"),
    order_id: orderDetail?.id || "",
    access_date: orderDetail?.access_date || "",
    access_start_time_slot:
      (orderDetail?.is_student_access === 1 &&
        orderDetail?.access_start_time_slot) ||
      "",
    access_end_time_slot:
      (orderDetail?.is_student_access === 1 &&
        orderDetail?.access_end_time_slot) ||
      "",
    subscription_id: orderDetail?.subscription_id || "",
    currency_code: orderDetail?.currency || "",
    franchise_id: orderDetail?.franchise_id || "",
    depot_id: orderDetail?.depot_id || "",
    access_pod_number: [],
  };
  return keys === "access" ? initialAccessValues : initialValues;
};

export const newOrderCollectionRetrunValue = (values, formik) => {
  const data = {
    order_id: values?.order_id || "",
    is_collection_address: values?.is_collection_address || "0",
    is_return_address: values?.is_return_address || "0",
    collection_address_city:
      values?.is_collection_address === 1
        ? values?.collection_address_city
        : formik.initialValues?.collection_address_city || "",
    collection_address_line:
      values?.is_collection_address === 1
        ? values?.collection_address_line
        : formik.initialValues?.collection_address_line || "",
    collection_date: values?.collection_date || "",
    collection_postcode: values?.collection_postcode || "",
    depot_id: values?.depot_id || "",
    end_time_slot: values?.end_time_slot || "",
    is_address_manual: values?.is_address_manual || "",
    is_manual: values?.is_manual || "",
    is_student: values?.is_student || "",
    is_student_return: values?.is_student_return || "",
    return_address_city:
      values?.is_return_address === 1
        ? values?.return_address_city
        : formik?.values?.return_address_city || "",
    return_address_line:
      values?.is_return_address === 1
        ? values?.return_address_line
        : formik?.values?.return_address_line || "",
    return_date: values?.return_date || "",
    return_end_time_slot: values?.return_end_time_slot || "",
    return_postcode: values?.return_postcode || "",
    return_start_time_slot: values?.return_start_time_slot || "",
    start_time_slot: values?.start_time_slot || "",
    collection_van_id: values?.collection_van_id || "",
    return_van_id: values?.return_van_id || "",
    van_id: values?.van_id || "",
    is_return: values?.is_return || "",
  };
  return data;
};

export const newOrderCollectionValue = (values, formik, type, state) => {
  const data = {
    order_id: values?.order_id || "",
    is_collection_address: values?.is_collection_address || "0",
    collection_address_city:
      values?.is_collection_address === 1
        ? values?.collection_address_city
        : formik.initialValues?.collection_address_city || "",
    collection_address_line:
      values?.is_collection_address === 1
        ? values?.collection_address_line
        : formik.initialValues?.collection_address_line || "",
    collection_date: values?.collection_date || "",
    collection_postcode: values?.collection_postcode || "",
    depot_id: values?.depot_id || "",
    end_time_slot: values?.end_time_slot || "",
    is_manual: values?.is_manual || "",
    is_student: values?.is_student || "",
    van_id: values?.van_id || values?.collection_van_id || "",
    start_time_slot: values?.start_time_slot || "",
    collection_van_id: values?.collection_van_id || "",
    is_return: "0",
    max_pod: values?.max_pod || "",
  };
  if (type === "order") {
    data.mail_to_customer = values?.mail_to_customer || "";
  }
  if (state?.containerTypeSelect === "container") {
    data.date_type = values?.date_type || "";
    data.site_class_id = values?.site_class_id || "";
    data.container_return_date = values?.container_return_date || "";
    data.container_collection_date = values?.container_collection_date || "";
    data.return_date = values?.return_date || "";
  }
  return data;
};
export const newOrderReturnValue = (values, formik) => {
  const data = {
    order_id: values?.order_id || "",
    is_return_address: values?.is_return_address || "0",
    van_id: values?.van_id || values?.return_van_id || "",
    is_manual: values?.is_manual || "",
    is_student_return: values?.is_student_return || "",
    return_address_city:
      values?.is_return_address === 1
        ? values?.return_address_city
        : formik?.values?.return_address_city || "",
    return_address_line:
      values?.is_return_address === 1
        ? values?.return_address_line
        : formik?.values?.return_address_line || "",
    return_date: values?.return_date || "",
    return_end_time_slot: values?.return_end_time_slot || "",
    return_postcode: values?.return_postcode || "",
    return_start_time_slot: values?.return_start_time_slot || "",
    return_van_id: values?.return_van_id || "",
    is_return: values?.is_return || "",
  };

  return data;
};

export const orderReturnValue = (values, formik, type) => {
  const data = {
    order_id: values?.order_id || "",
    is_return_address: values?.is_return_address || "0",
    van_id: values?.van_id || values?.return_van_id || "",
    is_manual: values?.is_manual || "",
    is_student_return: values?.is_student_return || "",
    return_address_city:
      values?.is_return_address === 1
        ? values?.return_address_city
        : formik.initialValues?.collection_address_city || "",
    return_address_line:
      values?.is_return_address === 1
        ? values?.return_address_line
        : formik.initialValues?.collection_address_line || "",
    return_date: values?.return_date || "",
    return_end_time_slot: values?.return_end_time_slot || "",
    return_postcode:
      values?.is_return_address === 1
        ? values?.return_postcode
        : formik.initialValues?.collection_postcode || "",
    return_start_time_slot: values?.return_start_time_slot || "",
    return_van_id: values?.return_van_id || "",
    is_return: values?.is_return || "",
    return_pod_number: values?.return_pod_number || [],
    return_reason: values?.return_reason || "",
    return_reason_description: values?.return_reason_description || "",
  };
  if (type === "order") {
    data.mail_to_customer = values?.mail_to_customer || "";
  }
  return data;
};

export const handleFinalPrice = (state) => {
  let planTotal = 0;
  let addonTotal = 0;
  let discountedPrice = 0;
  let podDiscount = 0;
  let proThree = 0;
  let week = WeekCounter(
    state.collectionRetrun?.collection_date,
    state.collectionRetrun?.return_date
  );
  let month = Math.floor(week / 4.34524);
  const product =
    state?.productArray && state?.productArray?.length > 0
      ? state?.productArray
      : [];
  const coupon =
    state?.coupon_id && state?.coupon_id?.length > 0 ? state?.coupon_id : [];
  // Step 1: Multiply week with product of type 'plan' and apply coupons with 'check' set to 'billing'
  if (week > 13) {
    for (const prod of product) {
      if (prod.type === "plan") {
        planTotal += +prod.total * 13;
      }
    }
    for (const prod of product) {
      if (prod.type === "plan") {
        proThree += +prod.total * (+week - 13);
      }
    }
    const filteredArr = coupon.filter(
      (item) => item.check === "billing" && item.type === "percent"
    );
    const lowestValue = Math.min(...filteredArr.map((item) => item.value));
    const lowestValueObject = filteredArr.find(
      (item) => item.value === lowestValue
    );
    const lowestValueArray = [lowestValueObject];
    for (const coup of coupon) {
      if (coup.check === "billing" && coup.type === "percent") {
        const couponValue = (coup?.value || 0 / 100) * planTotal;
        planTotal -= couponValue;
        discountedPrice += couponValue;
        podDiscount += couponValue;
      }
    }
    for (const coup of lowestValueArray) {
      const couponValue = (coup?.value || 0 / 100) * proThree;
      proThree -= couponValue;
      discountedPrice += couponValue;
      podDiscount += couponValue;
    }
  } else {
    for (const prod of product) {
      if (prod.type === "plan") {
        planTotal += +prod.total * week;
      }
    }
    for (const coup of coupon) {
      if (coup.check === "billing" && coup.type === "percent") {
        const couponValue = (coup.value / 100) * planTotal;
        planTotal -= couponValue;
        discountedPrice += couponValue;
        podDiscount += couponValue;
      }
    }
  }

  // Step 4: Add total of type 'addon' products multiplied by week
  for (const prod of product) {
    if (prod.type === "addon") {
      addonTotal += +prod.total * week;
    }
  }

  // Step 5: Add planTotal with addonTotal and any leftover product
  let total = +planTotal + +proThree + addonTotal;

  for (const prod of product) {
    if (prod.type !== "plan" && prod.type !== "addon") {
      total += +prod.total;
    }
  }

  const filteredCoupons = coupon.filter((coup) => coup.check !== "billing");

  // Sort remaining coupons with type "percent" and "fixed" based on their values
  const percentCoupons = filteredCoupons.filter(
    (coup) => coup.type === "percent"
  );
  const fixedCoupons = filteredCoupons.filter((coup) => coup.type === "fixed");

  percentCoupons.sort((a, b) => b.value - a.value);
  fixedCoupons.sort((a, b) => b.value - a.value);

  const arrangedCoupons = [...percentCoupons, ...fixedCoupons];
  for (const coup of arrangedCoupons) {
    if (coup.check !== "billing" && coup.type === "percent") {
      const couponValue = (coup.value / 100) * total;
      total -= couponValue;
      discountedPrice += couponValue;
    } else if (coup.check !== "billing" && coup.type === "fixed") {
      total -= +coup.value;
      discountedPrice += +coup.value;
    }
  }
  const totalPrice = +total.toFixed(2);
  const dicount = +discountedPrice.toFixed(2);
  const data = {
    total: totalPrice,
    discountedPrice: dicount,
    podDiscount: podDiscount,
  };
  return data;
};

export const handleFinalPriceCalculation = (state) => {
  let totalOfCharge = 0;
  let totalWithoutCharge = 0;
  let counter = WeekCounter(
    state.collectionRetrun?.collection_date,
    state.collectionRetrun?.return_date
  );

  const filterCharge = state?.productArray.filter((e) => e.type === "charge");
  const filterWithOutCharge = state?.productArray.filter(
    (e) => e.type !== "charge"
  );

  filterWithOutCharge.forEach(
    (e) => (totalWithoutCharge = Number(totalWithoutCharge) + Number(e.total))
  );
  filterCharge.forEach(
    (e) => (totalOfCharge = Number(totalOfCharge) + Number(e.total))
  );

  let finalOfNoCharge = Number(totalWithoutCharge) * Number(counter);
  let finalTotal = Number(finalOfNoCharge) + Number(totalOfCharge);
  return finalTotal;
};

export const handleUpdateQtyPackaging = (data, productArray) => {
  // const arr
  for (const item of productArray) {
    const index = data.findIndex((e) => e.id === item?.id);
    if (index > -1) {
      data[index].qty = item?.qty || 1;
    }
  }
  return data;
};

export function rearrangeArray(arr) {
  const fixedArr = [];
  const percentArr = [];

  for (let i = 0; i < arr.length; i++) {
    if (arr[i].type === "fixed") {
      fixedArr.push(arr[i]);
    } else if (arr[i].type === "percent") {
      percentArr.push(arr[i]);
    }
  }

  fixedArr.sort((a, b) => b.value - a.value);
  percentArr.sort((a, b) => b.value - a.value);
  const manualIndex = fixedArr.findIndex((ft) => ft.name === "manual");

  // If "manual" element is found, remove it from fixedArr and add it to the beginning
  if (manualIndex !== -1) {
    const manualElement = fixedArr.splice(manualIndex, 1)[0];
    fixedArr.unshift(manualElement);
  }
  return fixedArr.concat(percentArr);
}

//competitor price set api call function
// const handleCheck = (
//   e,
//   values,
//   dispatchReducer,
//   state,
//   dispatch,
//   paramData,
//   cardDetail,
//   handleBilling
// ) => {
//   const dataPrice = {
//     price: values || 0,
//     check: e === "check" ? true : false,
//   };
//   dispatchReducer({ type: "competitorPrice", value: dataPrice });

//   const data = {
//     collection_date: state?.collectionRetrun?.collection_date || "",
//     pod:
//       (state?.productArray.length > 0 &&
//         state?.productArray.find((e) => e.type === "plan")?.qty) ||
//       "",
//     return_date:
//       state.collectionRetrun?.return_date &&
//       state.collectionRetrun?.return_start_time_slot
//         ? state.collectionRetrun?.return_date
//         : "",
//     is_student: state.collectionRetrun?.is_student || 0,
//     is_student_return: state.collectionRetrun?.is_student_return || 0,
//     competitor_price: e === "check" ? values : 0,
//     isCompetitor: e === "check",
//   };
//   if (e === "check") {
//     dispatchReducer({ type: "coupon", value: [] });
//     dispatch(
//       fetchOrderSummary(
//         "12",
//         paramData.unit,
//         paramData.period,
//         paramData?.products_id,
//         cardDetail && cardDetail.length && cardDetail[0].contact?.franchise_id
//           ? cardDetail[0].contact?.franchise_id
//           : "",
//         cardDetail && cardDetail.length && cardDetail[0].postcode
//           ? cardDetail[0].postcode
//           : "",
//         "",
//         "",
//         "",
//         "",
//         data
//       )
//     );
//   } else {
//     handleBilling(
//       state?.params?.period === "week"
//         ? "1w"
//         : state?.params?.unit
//         ? state?.params?.unit
//         : 1,
//       data
//     );
//   }
// };

export const findDistance = async (origin, destination) => {
  const service = new window.google.maps.DistanceMatrixService();
  let distances = 0;
  await service.getDistanceMatrix(
    {
      origins: [origin],
      destinations: [destination],
      travelMode: "DRIVING",
      unitSystem: window.google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    },
    (response, status) => {
      if (status === "OK") {
        if (response.rows[0].elements[0]?.status === "NOT_FOUND") {
          distances = "Not Found";
        } else {
          const distance = response.rows[0].elements[0].distance?.value || 0; // Distance in meters
          distances = distance / 1000; // Convert meters to kilometers
        }
      } else {
        console.error("Error:", status);
        distances = "Not Found";
      }
    }
  );
  return distances;
};

export const apiThirdPartyEmail = async (email) => {
  const url =
    "https://apistaging.easystorage.com/api/self-booking/api-delighted";
  const apiKey = "LQjzchgcMAeddo7TL86dYdKFOKK2FUHj";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(`${apiKey}:`)}`,
    },
    body: JSON.stringify({
      email: email,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    console.log("Success:", data);
  } else {
    console.error("Error:", response.statusText);
  }
};

export const planChangeChargeReset = (product, quantity) => {
  if (
    !product ||
    !product.product_prices ||
    !Array.isArray(product.product_prices)
  ) {
    throw new Error("Invalid product object");
  }

  let cost = 0;
  for (let price of product.product_prices) {
    if (price.tier_start_unit <= quantity && price.tier_end_unit >= quantity) {
      cost = parseFloat(price.unit_amount);
      break;
    }
  }

  const updatedProduct = {
    ...product,
    quantity: quantity.toString(),
    cost: cost.toFixed(2),
    total: cost * quantity,
  };

  return updatedProduct;
};
