import React from "react";
import { Modal } from "react-bootstrap";

const DynamicFranchiseDialog = ({
  dialog,
  setDialog,

  handleChange,
  mainText,
  header,
}) => {
  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{mainText}</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog(false)}
            className="btn btn-light btn-elevate"
          >
            No
          </button>
          <> </>
          <button
            type="button"
            onClick={handleChange}
            className="btn btn-delete btn-elevate"
          >
            Yes
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DynamicFranchiseDialog;
