import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CreateOrderDropDown } from "./components/CreateOrderDropDown";
import { Dropdown } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { addDays } from "date-fns";
import {
  getContainerStandingData,
  getDashboardData,
  getFillRateData,
  getPodStandingData,
} from "../../../app/pages/redux/commonAction";
import { fetchPermission } from "../../../app/pages/redux/permission";
import { format } from "date-fns";
import { dealsFranchiseListing } from "../../../app/modules/ContactDeals/_redux/deals/dealsCrud";
import { InputGroup } from "react-bootstrap";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import moment from "moment";
import { LoadingCustome } from "../controls/LoadingCustome";
import RefreshIcon from "@material-ui/icons/Refresh";
import { fetchStats } from "../../../app/modules/Order/_redux/order/orderAction";
// eslint-disable-next-line no-restricted-imports
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#d35401",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  refreshIcon: {
    transition: "transform 0.3s linear",
    animation: "$rotate 1s linear infinite",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
export function DashboardComponent() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const {
    feedsData,
    permission,
    dashboardState,
    role,
    userData,
    admin,
    loading,
    dashboardPod,
    dashboardContainer,
    adminR,
    loadingPod,
    loadingContainer,
    dashboardFillRate,
  } = useSelector(
    (state) => ({
      feedsData: state.common.dashboardFeeds,
      dashboardState: state.common.dashboardDataState,
      dashboardPod: state.common.dashboardPodDataState?.podStandings,
      dashboardContainer:
        state.common.dashboardContainerDataState?.podStandings,
      permission: state.auth.permission,
      role: state.auth.user.roles,
      userData: state.auth.user,
      admin: state.auth,
      loading: state.common.loading,
      loadingPod: state.common.loadingPod,
      loadingContainer: state.common.loadingContainer,
      adminR: state.auth.user,
      dashboardFillRate: state.common.dashboardFillRateState?.fillRate,
    }),
    shallowEqual
  );

  const classes = useStyles();

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [dateText, setDateText] = useState("");
  const [filter, setFilter] = useState({
    franchise_id: [],
    start_date: "",
    end_date: "",
  });
  const [filterPod, setFilterPod] = useState({
    franchise_id: [],
    start_date: "",
    end_date: "",
  });
  const [filterContainer, setFilterContainer] = useState({
    franchise_id: [],
    start_date: "",
    end_date: "",
  });
  const [filterFillRate, setFilterFillRate] = useState({
    franchise_id: [],
    start_date: "",
    end_date: "",
  });
  const [franchises, setFranchise] = useState([]);
  const handleFranchiseList = () => {
    dealsFranchiseListing().then((res) => {
      setFranchise(res.data.data);
    });
  };

  const handleRefreshClick = () => {
    dispatch(fetchStats());
    // setRotating(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleFranchiseList();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleDate = useCallback(() => {
    if (new Date(date[0].startDate).getDate() === new Date().getDate()) {
      setDateText("Today");
    } else if (
      new Date(date[0].startDate).getDate() ===
      new Date().getDate() - 1
    ) {
      setDateText("Yesteday");
    } else if (
      new Date(date[0].startDate).getDate() <
      new Date(date[0].endDate).getDate()
    ) {
      let start = format(new Date(date[0].startDate), "MMMM dd");
      let end = format(new Date(date[0].endDate), "MMMM dd");
      setDateText(`${start} to ${end}`);
    } else {
      let start = format(new Date(date[0].startDate), "MMMM dd");
      let end = format(new Date(date[0].endDate), "MMMM dd");
      setDateText(`${start} to ${end}`);
    }
  }, [date]);

  useEffect(() => {
    handleDate();
  }, [handleDate]);

  const { statsData } = useSelector(
    (state) => ({
      statsData: state.order.statsData,
    }),
    shallowEqual
  );

  const formatKey = (key) => {
    if (key === undefined) return "";
    // return key
    const formattedKey = key.replace(/_/g, " ").toLowerCase();
    return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
  };
  useEffect(() => {
    if (role && role?.length && role[0]?.name === "franchise-owner") {
      const arr = [];
      arr.push(userData?.franchise_id);
      setFilter({
        ...filter,
        franchise_id: arr,
      });
      setFilterPod({
        ...filter,
        franchise_id: arr,
      });
      setFilterContainer({
        ...filter,
        franchise_id: arr,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  useEffect(() => {
    if (role && role?.length && role[0] === "super-admin") {
      dispatch(fetchStats());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    // dispatch(fetchDashboardFeed());
    if (
      (userData?.roles?.length > 0 &&
        userData.roles[0] !== "super-admin" &&
        filter?.franchise_id?.length > 0) ||
      (userData?.roles?.length > 0 && userData.roles[0] == "super-admin") ||
      (userData?.roles?.length > 0 && userData.roles[0] == "super-agent") ||
      (userData?.roles?.length > 0 && userData.roles[0] == "franchise-owner")
    ) {
      if (
        (filter.start_date && filter.end_date) ||
        (filter.start_date == "" && filter.end_date == "")
      ) {
        dispatch(
          getDashboardData(
            filter,
            userData.roles[0] == "franchise-owner" ? userData?.franchise_id : ""
          )
        );
      }
    }
    // dispatch(fetchPermission());
  }, [filter]);
  useEffect(() => {
    // dispatch(fetchDashboardFeed());
    console.log("userData", userData);

    if (
      (userData?.roles?.length > 0 &&
        userData.roles[0] !== "super-admin" &&
        filterPod?.franchise_id?.length > 0) ||
      (userData?.roles?.length > 0 && userData.roles[0] == "super-admin") ||
      (userData?.roles?.length > 0 && userData.roles[0] == "super-agent") ||
      (userData?.roles?.length > 0 && userData.roles[0] == "franchise-owner")
    ) {
      if (
        (filterPod.start_date && filterPod.end_date) ||
        (filterPod.start_date == "" && filterPod.end_date == "")
      ) {
        dispatch(
          getPodStandingData(
            filterPod,
            userData.roles[0] == "franchise-owner" ? userData?.franchise_id : ""
          )
        );
      }
    }
    // dispatch(fetchPermission());
  }, [filterPod]);
  console.log("filterContainer", filterContainer);

  useEffect(() => {
    // dispatch(fetchDashboardFeed());
    if (
      (userData?.roles?.length > 0 &&
        userData.roles[0] !== "super-admin" &&
        filterContainer?.franchise_id?.length > 0) ||
      (userData?.roles?.length > 0 && userData.roles[0] == "super-admin") ||
      (userData?.roles?.length > 0 && userData.roles[0] == "super-agent") ||
      (userData?.roles?.length > 0 && userData.roles[0] == "franchise-owner")
    ) {
      if (
        (filterContainer.start_date && filterContainer.end_date) ||
        (filterContainer.start_date == "" && filterContainer.end_date == "")
      ) {
        dispatch(
          getContainerStandingData(
            filterContainer,
            userData.roles[0] == "franchise-owner" ? userData?.franchise_id : ""
          )
        );
      }
    }
    // dispatch(fetchPermission());
  }, [filterContainer]);
  useEffect(() => {
    // dispatch(fetchDashboardFeed());
    if (
      (userData?.roles?.length > 0 &&
        userData.roles[0] !== "super-admin" &&
        filterFillRate?.franchise_id?.length > 0) ||
      (userData?.roles?.length > 0 && userData.roles[0] == "super-admin") ||
      (userData?.roles?.length > 0 && userData.roles[0] == "super-agent") ||
      (userData?.roles?.length > 0 && userData.roles[0] == "franchise-owner")
    ) {
      if (
        (filterFillRate.start_date && filterFillRate.end_date) ||
        (filterFillRate.start_date == "" && filterFillRate.end_date == "")
      ) {
        dispatch(
          getFillRateData(
            filterFillRate,
            userData.roles[0] == "franchise-owner" ? userData?.franchise_id : ""
          )
        );
      }
    }
    // dispatch(fetchPermission());
  }, [filterFillRate]);
  const DataSection = ({ title, data }) => {
    return (
      <>
        {Object.entries(data ? data : {}).map(([key, value]) => (
          <div className=" col-md-6 col-lg-2 col-xl-2 col-xxl-2  mb-4">
            <div
              className="card card-custom card-stretch gutter-b bg-light-gry backGround"
              style={{ marginBottom: "0px", height: "106px" }}
            >
              <div className="card-body">
                {/* <h3 className="text-center">{title}</h3> */}

                <div className="ratio text-dark-75">
                  <div className="item" key={key}>
                    {Number(value).toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="percent"></div>
              </div>
            </div>

            <div className="text-center" style={{ margin: "5px" }}>
              {formatKey(key)}
            </div>
          </div>
        ))}
      </>
    );
  };
  const handleCheckboxChange = (e, id) => {
    e.persist(); // Prevent React from reusing the event object
    setFilter((prevFilter) => {
      const { franchise_id } = prevFilter;
      if (e.target.checked) {
        // Add id to franchise_id if it's checked
        return { ...prevFilter, franchise_id: [...franchise_id, id] };
      } else {
        // Remove id from franchise_id if it's unchecked
        return {
          ...prevFilter,
          franchise_id: franchise_id.filter((item) => item !== id),
        };
      }
    });
    setFilterFillRate((prevFilter) => {
      const { franchise_id } = prevFilter;
      if (e.target.checked) {
        // Add id to franchise_id if it's checked
        return { ...prevFilter, franchise_id: [...franchise_id, id] };
      } else {
        // Remove id from franchise_id if it's unchecked
        return {
          ...prevFilter,
          franchise_id: franchise_id.filter((item) => item !== id),
        };
      }
    });
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const totals = monthNames.reduce((acc, month) => {
    acc[month] = dashboardFillRate?.reduce(
      (sum, item) => Number(sum) + (item[month] || 0),
      0
    );
    return acc;
  }, {});
  return (
    <>
      {(loading || loadingContainer || loadingPod) && <LoadingCustome />}
      {/* {permission?.dashboard?.view ? ( */}
      <>
        <div className="d-flex align-items-baseline justify-content-between ">
          <div>
            {/* <h5 className="text-dark font-weight-bold my-2 mr-5">
              <>
                {user?.first_name
                  ? user.first_name.charAt(0).toUpperCase() +
                    user.first_name.slice(1)
                  : "Andrew"}
              </>
            </h5> */}
          </div>
        </div>
        <div></div>
        <div className="mb-5 mt-5">
          {role && role?.length && role[0] === "super-admin" && (
            <div className="col-lg-12">
              <div
                className="row orderTableCard p-2"
                style={{ fontSize: "13px" }}
              >
                <div className="col-lg-2 col-md-6  d-flex  ">
                  Yesterday:&nbsp;
                  <strong>{statsData?.collectionsYesterday || "-"}</strong>
                </div>
                <div className="col-lg-1 col-md-6  d-flex timeLineOrder">
                  Today:&nbsp;
                  <strong>{statsData?.collectionsToday || "-"}</strong>
                </div>
                <div className="col-lg-1 col-md-6  d-flex  ">
                  Online&nbsp;Today:&nbsp;
                  <strong>{statsData?.onlineOrdersToday || "-"}</strong>
                </div>
                <div
                  className="col-lg-2 col-md-6  d-flex timeLineOrderLast  "
                  style={{ justifyContent: "center" }}
                >
                  Last&nbsp;Month:&nbsp;
                  <strong>{statsData?.collectionsPreviousMonth || "-"}</strong>
                </div>
                <div className="col-lg-2 col-md-6  d-flex  ">
                  This&nbsp;Month:&nbsp;
                  <strong>{statsData?.collectionsThisMonth || "-"}</strong>
                </div>
                <div className="col-lg-2 col-md-6  d-flex  ">
                  Online&nbsp;This&nbsp;Month:&nbsp;
                  <strong>
                    {statsData?.onlineCollectionsThisMonth || "-"}
                  </strong>
                </div>
                <div className="col-lg-2 col-md-12  d-flex    justify-content-between timeLineOrderTime">
                  <span>
                    Time&nbsp;Since&nbsp;Last&nbsp;Order:&nbsp;&nbsp;
                    <strong>{statsData?.elapsedTime || "-"}</strong>
                  </span>
                  &nbsp;&nbsp;
                  <div
                    onClick={handleRefreshClick}
                    style={{ cursor: "pointer", marginTop: "-2px" }}
                  >
                    <RefreshIcon
                      className={`${
                        statsData?.loading ? classes.refreshIcon : ""
                      }`}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          )}
        </div>
        {console.log(
          "kok-90",
          (userData?.roles?.length > 0 && userData.roles[0] == "super-admin") ||
            admin?.user?.franchise_plan_types?.some((e) => e === "container")
        )}
        {(userData?.roles?.length > 0 && userData.roles[0] == "super-admin") ||
        admin?.user?.franchise_plan_types?.some((e) => e === "pod") ? (
          <Paper>
            <div style={{ padding: "2%", marginBottom: "2%" }}>
              <div>
                <h1 style={{ margin: "2% 0 0 1%" }}>Pod Standings</h1>
                <div className="row">
                  <div className="col-lg-8 mb-5 mt-5">
                    <div className="col-lg-8  d-flex order-flt mb-4">
                      <span className="mt-3" style={{ width: "50%" }}>
                        {filterPod?.start_date ? "Date" : "Current Month"}
                        :&nbsp;
                      </span>{" "}
                      &nbsp;
                      <InputGroup className="calendr">
                        <DatePicker
                          fullWidth
                          fixedHeight
                          className="form-control"
                          wrapperClassName="datepicker"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="From Date"
                          selected={Date.parse(filterPod?.start_date)}
                          startDate={Date.parse(filterPod?.start_date)}
                          endDate={Date.parse(filterPod?.end_date)}
                          // selectsRange
                          // isClearable
                          onChange={(dates) => {
                            // const [start, end] = dates;
                            setFilterPod({
                              ...filterPod,
                              start_date: dates
                                ? moment(dates).format("yyyy-MM-DD")
                                : "",
                            });

                            // if (dates === null) {
                            //   handleSubmit();
                            // }
                            // setFieldValue(
                            //   "to_date",
                            //   end ? moment(end).format("yyyy-MM-DD") : ""
                            // );
                            // if (end || (start === null && end === null)) {
                            //   handleSubmit();
                            // }
                          }}
                        />

                        <CalendarTodayIcon />
                      </InputGroup>
                      &nbsp;
                      <InputGroup className="calendr">
                        <DatePicker
                          fullWidth
                          fixedHeight
                          className="form-control"
                          wrapperClassName="datepicker"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="To Date"
                          selected={Date.parse(filterPod?.end_date)}
                          startDate={Date.parse(filterPod?.start_date)}
                          endDate={Date.parse(filterPod?.end_date)}
                          minDate={Date.parse(filterPod?.start_date)}
                          // selectsRange
                          onChange={(dates) => {
                            setFilterPod({
                              ...filterPod,
                              end_date: dates
                                ? moment(dates).format("yyyy-MM-DD")
                                : "",
                            });
                          }}
                        />

                        <CalendarTodayIcon />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead style={{ backgroundColor: "#175c83" }}>
                    <TableRow style={{ backgroundColor: "#ffe6cc" }}>
                      <StyledTableCell
                        align="center"
                        style={{ padding: "12px 0 12px" }}
                      >
                        <h4 style={{ fontSize: "15px" }}>Position</h4>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ padding: "12px 0 12px" }}
                      >
                        <h4 style={{ fontSize: "15px" }}>Franchise</h4>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ padding: "12px 0 12px" }}
                      >
                        <h4 style={{ fontSize: "15px" }}>Economy</h4>
                        <p style={{ fontSize: "13px", marginBottom: "0" }}>
                          Pod per week(&pound;)
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ padding: "12px 0 12px" }}
                      >
                        <h4 style={{ fontSize: "15px" }}>Premium</h4>
                        <p style={{ fontSize: "13px", marginBottom: "0" }}>
                          Pod per week(&pound;)
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ padding: "12px 0 12px" }}
                      >
                        <h4 style={{ fontSize: "15px" }}>FC</h4>
                        <p style={{ fontSize: "13px", marginBottom: "0" }}>
                          Pod per week(&pound;)
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ padding: "12px 0 12px" }}
                      >
                        <h4 style={{ fontSize: "15px" }}>Total</h4>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dashboardPod?.length > 0 &&
                      dashboardPod.map((e, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {e?.Position}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {e?.Franchise}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {e?.Economy}
                            <br />
                            {e?.Economy_Price}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {e?.Premium}
                            <br />
                            {e?.Premium_Price}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {e?.FC}
                            <br />
                            {e?.FC_Price}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {e?.Total}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        ) : (
          ""
        )}
        {(userData?.roles?.length > 0 && userData.roles[0] == "super-admin") ||
        admin?.user?.franchise_plan_types?.some((e) => e === "container") ? (
          <Paper>
            <div style={{ padding: "2%", marginBottom: "2%" }}>
              <div>
                <h1 style={{ margin: "2% 0 0 1%" }}>Container Standings</h1>
                <div className="row">
                  <div className="col-lg-8 mb-5 mt-5">
                    <div className="col-lg-8  d-flex order-flt mb-4">
                      <span className="mt-3" style={{ width: "50%" }}>
                        {filterContainer?.start_date ? "Date" : "Current Month"}
                        :&nbsp;
                      </span>{" "}
                      &nbsp;
                      <InputGroup className="calendr">
                        <DatePicker
                          fullWidth
                          fixedHeight
                          className="form-control"
                          wrapperClassName="datepicker"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="From Date"
                          selected={Date.parse(filterContainer?.start_date)}
                          startDate={Date.parse(filterContainer?.start_date)}
                          endDate={Date.parse(filterContainer?.end_date)}
                          // selectsRange
                          // isClearable
                          onChange={(dates) => {
                            // const [start, end] = dates;
                            setFilterContainer({
                              ...filterContainer,
                              start_date: dates
                                ? moment(dates).format("yyyy-MM-DD")
                                : "",
                            });

                            // if (dates === null) {
                            //   handleSubmit();
                            // }
                            // setFieldValue(
                            //   "to_date",
                            //   end ? moment(end).format("yyyy-MM-DD") : ""
                            // );
                            // if (end || (start === null && end === null)) {
                            //   handleSubmit();
                            // }
                          }}
                        />

                        <CalendarTodayIcon />
                      </InputGroup>
                      &nbsp;
                      <InputGroup className="calendr">
                        <DatePicker
                          fullWidth
                          fixedHeight
                          className="form-control"
                          wrapperClassName="datepicker"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="To Date"
                          selected={Date.parse(filterContainer?.end_date)}
                          startDate={Date.parse(filterContainer?.start_date)}
                          endDate={Date.parse(filterContainer?.end_date)}
                          minDate={Date.parse(filterContainer?.start_date)}
                          // selectsRange
                          onChange={(dates) => {
                            setFilterContainer({
                              ...filterContainer,
                              end_date: dates
                                ? moment(dates).format("yyyy-MM-DD")
                                : "",
                            });
                          }}
                        />

                        <CalendarTodayIcon />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead style={{ backgroundColor: "#175c83" }}>
                    <TableRow style={{ backgroundColor: "#ffe6cc" }}>
                      <StyledTableCell
                        align="center"
                        style={{ padding: "12px 0 12px" }}
                      >
                        <h4 style={{ fontSize: "15px" }}>Position</h4>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ padding: "12px 0 12px" }}
                      >
                        <h4 style={{ fontSize: "15px" }}>Franchise</h4>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ padding: "12px 0 12px" }}
                      >
                        <h4 style={{ fontSize: "15px" }}>Site</h4>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ padding: "12px 0 12px" }}
                      >
                        <h4 style={{ fontSize: "15px" }}>Total</h4>
                        <p style={{ fontSize: "13px", marginBottom: "0" }}>
                          Container per month(&pound;)
                        </p>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dashboardContainer?.length > 0 &&
                      dashboardContainer.map((e, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {e?.Position}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {e?.Franchise}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {e?.SiteName}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {e?.Order_Count}
                            <br />
                            {e?.price}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        ) : (
          ""
        )}
        <Paper>
          <div style={{ padding: "2%", marginBottom: "2%" }}>
            <div>
              <h1 style={{ margin: "2% 0 0 1%" }}>
                Fill Rate{" "}
                <span style={{ fontSize: "14px" }}>
                  (based on Move In marked Completed on Vault. If Move In not
                  marked Completed on Vault, then the data would not show up in
                  the table below) BETA
                </span>
              </h1>
              <div className="row">
                <div className="col-lg-8 mb-5 mt-5">
                  {/* <div className="col-lg-8  d-flex order-flt mb-4">
                    <span className="mt-3" style={{ width: "50%" }}>

                      {filterContainer?.start_date ? "Date" : "Current Month"}:&nbsp;
                    </span>{" "}
                    &nbsp;
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="From Date"
                        selected={Date.parse(filterContainer?.start_date)}
                        startDate={Date.parse(filterContainer?.start_date)}
                        endDate={Date.parse(filterContainer?.end_date)}
                        // selectsRange
                        // isClearable
                        onChange={(dates) => {
                          // const [start, end] = dates;
                          setFilterContainer(
                            {
                              ...filterContainer, "start_date":
                                dates ? moment(dates).format("yyyy-MM-DD") : ""
                            }
                          );

                          // if (dates === null) {
                          //   handleSubmit();
                          // }
                          // setFieldValue(
                          //   "to_date",
                          //   end ? moment(end).format("yyyy-MM-DD") : ""
                          // );
                          // if (end || (start === null && end === null)) {
                          //   handleSubmit();
                          // }
                        }}
                      />

                      <CalendarTodayIcon />
                    </InputGroup>
                    &nbsp;
                    <InputGroup className="calendr">

                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="To Date"
                        selected={Date.parse(filterContainer?.end_date)}
                        startDate={Date.parse(filterContainer?.start_date)}
                        endDate={Date.parse(filterContainer?.end_date)}
                        minDate={Date.parse(filterContainer?.start_date)}
                        // selectsRange
                        onChange={(dates) => {
                          setFilterContainer(
                            {
                              ...filterContainer, "end_date":
                                dates ? moment(dates).format("yyyy-MM-DD") : ""
                            }
                          );
                        }}
                      />

                      <CalendarTodayIcon />
                    </InputGroup>
                  </div> */}
                </div>
              </div>
            </div>
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableHead style={{ backgroundColor: "#175c83" }}>
                  <TableRow style={{ backgroundColor: "#ffe6cc" }}>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Franchise</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Site</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Jan</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Feb</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Mar</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Apr</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>May</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Jun</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Jul</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Aug</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Sep</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Oct</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Nov</h4>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ padding: "12px 0 12px" }}
                    >
                      <h4 style={{ fontSize: "15px" }}>Dec</h4>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboardFillRate?.length > 0 &&
                    dashboardFillRate.map((e, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {e?.Franchise}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.SiteName}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.January}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.February}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.March}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.April}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.May}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.June}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.July}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.August}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.September}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.October}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.November}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {e?.December}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {dashboardFillRate?.length > 0 && (
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        <strong>Total</strong>
                      </StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                      {monthNames.map((month) => (
                        <StyledTableCell key={month} align="center">
                          <strong>{totals[month]}</strong>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Paper>
        <div className="row">
          <div className="col-lg-12 col-xxl-12 grown-card">
            <div className="card card-custom  card-stretch gutter-b">
              <div className="card-body ">
                <div className="row">
                  <div className="col-lg-8 mb-5 mt-5">
                    <div className="col-lg-8  d-flex order-flt mb-4">
                      <span className="mt-3">
                        Created&nbsp;By&nbsp;Date:&nbsp;
                      </span>{" "}
                      &nbsp;
                      <InputGroup className="calendr">
                        <DatePicker
                          fullWidth
                          fixedHeight
                          className="form-control"
                          wrapperClassName="datepicker"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="From Date"
                          selected={Date.parse(filter?.start_date)}
                          startDate={Date.parse(filter?.start_date)}
                          endDate={Date.parse(filter?.end_date)}
                          // selectsRange
                          // isClearable
                          onChange={(dates) => {
                            // const [start, end] = dates;
                            setFilter({
                              ...filter,
                              start_date: dates
                                ? moment(dates).format("yyyy-MM-DD")
                                : "",
                            });

                            // if (dates === null) {
                            //   handleSubmit();
                            // }
                            // setFieldValue(
                            //   "to_date",
                            //   end ? moment(end).format("yyyy-MM-DD") : ""
                            // );
                            // if (end || (start === null && end === null)) {
                            //   handleSubmit();
                            // }
                          }}
                        />

                        <CalendarTodayIcon />
                      </InputGroup>
                      &nbsp;
                      <InputGroup className="calendr">
                        <DatePicker
                          fullWidth
                          fixedHeight
                          className="form-control"
                          wrapperClassName="datepicker"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="To Date"
                          selected={Date.parse(filter?.end_date)}
                          startDate={Date.parse(filter?.start_date)}
                          endDate={Date.parse(filter?.end_date)}
                          minDate={Date.parse(filter?.start_date)}
                          // selectsRange
                          onChange={(dates) => {
                            setFilter({
                              ...filter,
                              end_date: dates
                                ? moment(dates).format("yyyy-MM-DD")
                                : "",
                            });
                          }}
                        />

                        <CalendarTodayIcon />
                      </InputGroup>
                    </div>
                  </div>

                  {userData?.roles?.length > 0 &&
                    userData.roles[0] === "super-admin" && (
                      <div
                        className="col-lg-4 mb-5 mt-5 order-flt"
                        style={{ justifyContent: "end" }}
                      >
                        <div
                          className="loader-dropdown"
                          style={{ width: "70%" }}
                        >
                          <Dropdown
                            className="dropdown-inline doggle-toggle-dashboard"
                            drop="down"
                            alignRight
                          >
                            <Dropdown.Toggle
                              id="dropdown-toggle-top2"
                              variant="transparent"
                              className="btn btn-light   dropdown-toggle text-dark-75 "
                              title={""}
                            >
                              Franchise
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                              <ul className="navi navi-hover">
                                {franchises?.map((item, index) => (
                                  <li className="navi-item">
                                    <>
                                      <span className="navi-text">
                                        <label className="checkbox">
                                          <input
                                            type="checkbox"
                                            // name={`collection_loader_id[${index}]`}
                                            checked={filter.franchise_id.includes(
                                              item?.id
                                            )}
                                            onChange={(e) =>
                                              handleCheckboxChange(e, item?.id)
                                            }
                                          />
                                          <span className="mr-2"></span>{" "}
                                          {item?.name || "-"}
                                        </label>
                                      </span>
                                    </>
                                  </li>
                                )) || ""}
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                </div>
                <div className="row mt-10">
                  {dashboardState && (
                    <>
                      {console.log("dashboardState", filter)}
                      {Object.entries(dashboardState)
                        .filter(([key, value]) => value !== null)
                        .map(([key, value]) => {
                          const userHasRole =
                            userData?.roles?.length > 0 &&
                            userData.roles[0] !== "super-admin";
                          const userHasFranchisePlan = userData?.franchise_plan_types?.some(
                            (e) => e === "pod"
                          );
                          const formattedKey = formatKey(key);
                          const userHasFranchiseContainer = userData?.franchise_plan_types?.some(
                            (e) => e === "container"
                          );
                          if (
                            (userHasRole &&
                              !userHasFranchisePlan &&
                              formattedKey === "POD") ||
                            (userHasRole &&
                              !userHasFranchisePlan &&
                              formattedKey === "Staticpod")
                          ) {
                            return null; // Skip rendering this entry
                          }
                          if (
                            (userHasRole &&
                              !userHasFranchiseContainer &&
                              formattedKey === "Con") ||
                            (userHasRole &&
                              !userHasFranchiseContainer &&
                              formattedKey === "Staticcon")
                          ) {
                            return null;
                          }
                          if (
                            userData?.roles?.length > 0 &&
                            userData.roles[0] !== "super-admin" &&
                            formatKey(key) === "Contact"
                          ) {
                            return null;
                          }
                          if (
                            userData?.roles?.length > 0 &&
                            userData.roles[0] !== "super-admin" &&
                            formatKey(key) === "Box" &&
                            userData?.franchise_id != 6
                          ) {
                            return null;
                          }
                          const removeWords = (str) => {
                            return str
                              .replace(/\b(Pod|Container)\b/g, "")
                              .trim();
                          };
                          const headingKey = formatKey(key);
                          const capitalizeFirstLetter = (str) => {
                            return str.charAt(0).toUpperCase() + str.slice(1);
                          };
                          const finalHeading = capitalizeFirstLetter(
                            removeWords(headingKey)
                          );
                          return (
                            <>
                              <div style={{ width: "100%" }}>
                                <h1
                                  style={{
                                    marginLeft: "2%",
                                    marginBottom: "1%",
                                    marginTop:
                                      formatKey(key) == "Container today"
                                        ? "2%"
                                        : "",
                                  }}
                                >
                                  {formatKey(key) == "Pod today"
                                    ? "Pod"
                                    : formatKey(key) == "Container today"
                                    ? "Container"
                                    : ""}
                                </h1>
                              </div>
                              <br />
                              {formatKey(key) != "Staticpod" &&
                                formatKey(key) != "Podonoff" &&
                                formatKey(key) != "Cononoff" &&
                                formatKey(key) != "Staticcon" &&
                                formatKey(key) != "Pod3rd" && (
                                  <h3 style={{ paddingLeft: "26px" }}>
                                    {finalHeading == "Today" &&
                                    (filter?.end_date !== "" ||
                                      filter?.franchise_id?.length > 0)
                                      ? ""
                                      : finalHeading}
                                  </h3>
                                )}

                              <div
                                className="row w-100"
                                style={{ paddingLeft: "26px" }}
                              >
                                <DataSection
                                  title={key}
                                  key={key}
                                  data={value}
                                />
                              </div>
                            </>
                          );
                        })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* ) : (
        <Card>
          <CardBody>
            <NotAuthorized />
          </CardBody>
        </Card>
      )} */}
    </>
  );
}
