/* eslint-disable jsx-a11y/anchor-is-valid */

import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import { divideByHundred } from "../../../utils/canvaCloseFunction";
import { useIntl } from "react-intl";
import {
  convertGBPtoPoundSymbol,
  // matchRemoveHash,
} from "../../../utils/commonFunction";
import packagingMaterialList from "../../../utils/packagingMaterial.json";
import { getMavisPackagingMaterial } from "../_redux/order/orderCrud";

export function OrderSubscriptionTable({ orderDetail, subDetail }) {
  const intl = useIntl();
  const [listPackage, setListPackage] = useState([]);
  const [listMavisPackage, setListMavisPackage] = useState([]);
  const value = orderDetail?.subscription_data;

  const handlePackagingMaterial = () => {
    const matchingItems = [...listPackage];

    packagingMaterialList.forEach((orderItem) => {
      const packItem = orderDetail?.order_summary?.find(
        (packItem) => packItem?.id === orderItem.chargebee_charge_id
      );

      if (packItem) {
        matchingItems.push({
          id: packItem.id,
          name: packItem.name,
          price: packItem.price,
          quantity: packItem.quantity,
          item_price_id: packItem.item_price_id,
        });
      }
    });
    setListPackage(matchingItems);
  };
  const handleMavisPackaging = () => {
    getMavisPackagingMaterial({ order_id: orderDetail?.id }).then((res) => {
      setListMavisPackage(res.data.data);
    });
  };
  useEffect(() => {
    if (orderDetail?.id) {
      handleMavisPackaging();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.id]);

  useEffect(() => {
    if (
      orderDetail?.order_summary &&
      orderDetail?.order_summary?.length > 0 &&
      !orderDetail?.packaging_materials
    ) {
      handlePackagingMaterial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.order_summary?.length]);

  return (
    <div className={"customCheck"}>
      <Table responsive className="summerytable customColor">
        <thead>
          <tr>
            <th colSpan={2}>{intl.formatMessage({ id: "Product" })}</th>

            <th style={{ textAlign: "right" }}>
              {/* {intl.formatMessage({ id: "Total" })} */}
            </th>
            <th style={{ textAlign: "right" }}>
              {intl.formatMessage({ id: "Qty" })}
            </th>
          </tr>
        </thead>
        <tbody>
          {orderDetail?.order_summary?.length &&
          orderDetail?.is_same_time_return == 1
            ? orderDetail?.order_summary?.map((item, index) => {
                const insurance = orderDetail?.order_summary.find(
                  (e) =>
                    e.stripe_product_id ===
                    process.env.REACT_APP_PRODUCT_POD_PROTECT
                );
                return (
                  <tr key={index}>
                    <td colSpan={2}>{item?.name || "-"} </td>

                    <td className="d-flex"></td>
                    <td style={{ textAlign: "right" }}>
                      {item.name === "Pod Protect"
                        ? "£" + Number(insurance?.cover).toFixed(0)
                        : item?.quantity || "-"}{" "}
                    </td>
                  </tr>
                );
              })
            : orderDetail?.packaging_materials &&
              orderDetail?.packaging_materials?.length > 0
            ? ""
            : ""
          // <tr>
          //   <td colSpan={"5"}>
          //     <>
          //       <NoRecord />
          //     </>

          //   </td>
          // </tr>
          }
          {console.log("orderSummaryListStripe", subDetail, orderDetail)}
          {orderDetail?.is_same_time_return != 1 &&
            orderDetail?.subscription_id &&
            orderDetail?.subscription_id.includes("sub_") && (
              <>
                {subDetail?.items?.data?.map((item, index) => {
                  const insurance =
                    orderDetail?.order_summary?.length &&
                    orderDetail?.order_summary?.find(
                      (e) =>
                        e.stripe_product_id ===
                        process.env.REACT_APP_PRODUCT_POD_PROTECT
                    );
                  return (
                    <tr key={index}>
                      <td colSpan={2}>{item?.plan?.product_name || "-"} </td>

                      <td className="d-flex"></td>
                      <td style={{ textAlign: "right" }}>
                        {item?.plan.product_name === "Pod Protect" ||
                        item?.plan?.product_name === "Store Protect"
                          ? "£" +
                            Number(
                              orderDetail?.insurance_cover || insurance?.cover
                            ).toLocaleString()
                          : item?.quantity || "-"}{" "}
                      </td>
                    </tr>
                  );
                })}
              </>
            )}

          {orderDetail?.is_same_time_return != 1 &&
          (!orderDetail?.subscription_id ||
            !orderDetail?.subscription_id.includes("sub_")) ? (
            orderDetail.subscription_scheduled_id ? (
              <>
                {value?.phases && value?.phases?.length ? (
                  value?.phases[0]?.items?.map((item, index) => {
                    const insurance =
                      orderDetail?.order_summary?.length &&
                      orderDetail?.order_summary?.find(
                        (e) =>
                          e.stripe_product_id ===
                          process.env.REACT_APP_PRODUCT_POD_PROTECT
                      );
                    return (
                      <tr key={index}>
                        <td colSpan={2}>{item?.product_name || "-"} </td>

                        <td className="d-flex"></td>
                        <td style={{ textAlign: "right" }}>
                          {item.product_name === "Pod Protect"
                            ? "£" +
                              Number(
                                orderDetail?.insurance_cover || insurance?.cover
                              ).toLocaleString()
                            : item?.quantity || "-"}{" "}
                        </td>
                      </tr>
                    );
                  })
                ) : orderDetail?.packaging_materials &&
                  orderDetail?.packaging_materials?.length > 0 ? (
                  ""
                ) : (
                  <tr>
                    <td colSpan={"5"}>
                      <>
                        <NoRecord />
                      </>
                      {/* )} */}
                    </td>
                  </tr>
                )}
              </>
            ) : (
              <>
                {value?.items?.data && value?.items?.data.length ? (
                  value?.items?.data.map((item, index) => {
                    const insurance =
                      orderDetail?.order_summary?.length &&
                      orderDetail?.order_summary?.find(
                        (e) =>
                          e.stripe_product_id ===
                          process.env.REACT_APP_PRODUCT_POD_PROTECT
                      );
                    return (
                      <tr key={index}>
                        <td colSpan={2}>{item?.plan?.product_name || "-"} </td>

                        <td className="d-flex"></td>
                        <td style={{ textAlign: "right" }}>
                          {item?.plan?.product_name === "Pod Protect"
                            ? "£" +
                              Number(
                                orderDetail?.insurance_cover || insurance?.cover
                              ).toLocaleString()
                            : item?.quantity || "-"}{" "}
                        </td>
                      </tr>
                    );
                  })
                ) : orderDetail?.packaging_materials &&
                  orderDetail?.packaging_materials?.length > 0 ? (
                  ""
                ) : (
                  <tr>
                    <td colSpan={"5"}>
                      <>
                        <NoRecord />
                      </>
                      {/* )} */}
                    </td>
                  </tr>
                )}
              </>
            )
          ) : (
            ""
          )}
        </tbody>
      </Table>
      {(orderDetail?.packaging_materials &&
        orderDetail?.packaging_materials?.length > 0) ||
      (listPackage && listPackage?.length > 0) ||
      (listMavisPackage && listMavisPackage?.length > 0) ? (
        <>
          <h5 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              Packaging Material{" "}
              <span style={{ fontSize: "14px" }}>
                {orderDetail?.is_ship_materials === 1
                  ? "(shipped)"
                  : "(not shipped)"}
              </span>
            </span>
          </h5>
          <Table responsive className="summerytable customColor">
            <thead>
              <tr>
                <th colSpan={2}>{intl.formatMessage({ id: "Product" })}</th>

                <th style={{ textAlign: "right" }}>
                  {/* {intl.formatMessage({ id: "Total" })} */}
                </th>
                <th style={{ textAlign: "right" }}>
                  {intl.formatMessage({ id: "Qty" })}
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {orderDetail?.packaging_materials?.length > 0 ? (
                orderDetail.packaging_materials.map((item, index) => (
                  <tr key={index}>
                    <td colSpan={2}>{item?.id || "-"}</td>
                    <td className="d-flex"></td>
                    <td style={{ textAlign: "right" }}>
                      {item?.quantity || ""}
                    </td>
                  </tr>
                ))
              ) : listPackage?.length > 0 ? (
                listPackage.map((item, index) => (
                  <tr key={index}>
                    <td colSpan={2}>{item?.id || "-"}</td>
                    <td className="d-flex"></td>
                    <td style={{ textAlign: "right" }}>
                      {item?.quantity || ""}
                    </td>
                  </tr>
                ))
              ) : listMavisPackage && listMavisPackage.length > 0 ? (
                ""
              ) : (
                <tr>
                  <td colSpan={5}>
                    <NoRecord />
                  </td>
                </tr>
              )} */}
              {listMavisPackage && listMavisPackage.length > 0
                ? listMavisPackage.map((item, index) => (
                    <tr key={index}>
                      <td colSpan={2}>{item?.name || "-"}</td>
                      <td className="d-flex"></td>
                      <td style={{ textAlign: "right" }}>{item?.qty || ""}</td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
